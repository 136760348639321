import { CustomLink, SuccessThickIcon, useI18n } from "@vaultinum/app-sdk";
import { ReactNode, useContext, useEffect } from "react";

import { OrderContext } from "../../../contexts";
import { Translation } from "../../../i18n";
import { CREDIT_PAGE } from "../../../services";
import CreditsPageTemplate from "./CreditsPageTemplate";

export default function CreditsBuySuccessPage(): ReactNode {
    const { clearShoppingCart } = useContext(OrderContext);

    const { translation } = useI18n<Translation>();

    useEffect(clearShoppingCart, []);

    return (
        <CreditsPageTemplate>
            <div className="flex flex-col items-center gap-4">
                <SuccessThickIcon className="w-20 h-20 fill-green-600" />
                <h2 className="text-base text-center font-bold text-green-600">{translation.thanksForYourOrder}</h2>
                <p>{translation.yourCreditsWillBeAdded}</p>
                <CustomLink label={translation.backToCreditsManagement} className="text-sm" path={CREDIT_PAGE} />
            </div>
        </CreditsPageTemplate>
    );
}
