import { negate } from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { Button, Cell, Dot, IncrementInput, SimpleTable, useToast } from "../../../design-system";
import { isAdministrator, standardFormatDate } from "../../helpers";
import { useApi } from "../../hooks";
import { Translation, useI18n } from "../../i18n";
import { Member, MemberCredit, UserInfo, UserWallet } from "../../model";
import { allocateCredits, updateMemberCredit } from "../../services";
import MemberCreditStatus from "../MemberCreditStatus/MemberCreditStatus";
interface CreditUpdate {
    value: number;
    userInfo: UserInfo;
}
interface MemberCreditType {
    creditToPurchased: number;
    date: string;
    increment: number;
}

interface UserWalletWithIncrement extends UserWallet {
    increment: number;
}

const isNotAdministrator = (user: UserInfo) => negate(() => isAdministrator(user));

const compareUsers = (userWallet1: UserWallet, userWallet2: UserWallet): number => {
    if (isAdministrator(userWallet1.user) && isNotAdministrator(userWallet2.user)) {
        return -1;
    }

    if (isNotAdministrator(userWallet1.user) && isAdministrator(userWallet2.user)) {
        return 1;
    }

    return userWallet1.user.id.localeCompare(userWallet2.user.id);
};

const getIncrementsForNonAdministrators = (userWallets: UserWalletWithIncrement[]): number => {
    return userWallets.filter(({ user }) => isNotAdministrator(user)).reduce((sum, { increment }) => sum + increment, 0);
};

const getAvailableCreditsForAdmin = (memberCredit: MemberCredit, userWallets: UserWalletWithIncrement[]) => {
    return memberCredit.availableAndNotAllocated - getIncrementsForNonAdministrators(userWallets);
};

export default function MemberCredits({ memberCredits, refresh }: { memberCredits: MemberCredit; refresh?: () => void }): JSX.Element {
    const { fetchApi } = useApi();
    const toast = useToast();
    const { translation } = useI18n<Translation>();
    const [updatedUserCredits, setUpdatedUserCredits] = useState<CreditUpdate[]>([]);
    const [userWallets, setUserWallets] = useState<UserWalletWithIncrement[]>();
    const [userValues, setUserValues] = useState<Cell[][]>();
    const [memberExpiredDate, setMemberExpiredDate] = useState<string>(memberCredits.expiresAt ?? "");
    const [updateMemberCredits, setUpdateMemberCredits] = useState<MemberCreditType>();
    const [memberValues, setMemberValues] = useState<Cell[][]>();

    const memberCreditChanges = (value: number) => {
        if (updateMemberCredits && memberCredits) {
            const difference = value - updateMemberCredits.creditToPurchased;
            updateMemberCredits.increment += difference;
            setUpdateMemberCredits({ ...updateMemberCredits, creditToPurchased: value, increment: updateMemberCredits.increment });
        }
    };

    const handleMemberDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (updateMemberCredits) {
            setMemberExpiredDate(e.target.value);
            setUpdateMemberCredits({ ...updateMemberCredits, date: e.target.value });
        }
    };

    const handleRenderAvailbleCredits = (credit: number, increment: number): string => {
        if (increment > 0) {
            return `${credit} (+${increment})`;
        }

        if (increment < 0) {
            return `${credit} (${increment})`;
        }
        return `${credit}`;
    };

    useEffect(() => {
        setUserWallets([...memberCredits.userWallets].sort(compareUsers).map(item => ({ ...item, increment: 0 })));
        setUpdateMemberCredits({
            creditToPurchased: memberCredits.purchased,
            date: memberCredits.expiresAt ?? "",
            increment: 0
        });
    }, [memberCredits]);

    useEffect(() => {
        if (updateMemberCredits && userWallets) {
            setMemberValues([
                [
                    {
                        value:
                            memberCredits.available === undefined
                                ? translation.undefined
                                : handleRenderAvailbleCredits(memberCredits.available, updateMemberCredits.increment),
                        style: "font-bold text-right"
                    },
                    {
                        value: memberCredits.lost ? (
                            <div className="flex items-center justify-end gap-1.5">
                                {memberCredits.lost}
                                <Dot color="bg-orange" size="small" />
                            </div>
                        ) : undefined
                    },
                    {
                        value: updateMemberCredit(memberCredits) ? (
                            <IncrementInput
                                value={updateMemberCredits.creditToPurchased}
                                onChange={memberCreditChanges}
                                min={memberCredits.purchased - memberCredits.userWallets[0].credits.available}
                                disabled={memberCredits.status !== Member.CreditStatus.ACTIVE}
                            />
                        ) : (
                            memberCredits.purchased
                        ),
                        style: memberCredits._links.update.available ? "flex justify-center" : "text-right"
                    },

                    { value: memberCredits.consumed ?? translation.undefined, style: "text-right" },
                    {
                        value: updateMemberCredit(memberCredits) ? (
                            <input type="date" value={memberExpiredDate} onChange={handleMemberDateChange} />
                        ) : (
                            standardFormatDate(memberCredits.expiresAt)
                        ),
                        style: "text-right"
                    },
                    {
                        value: memberCredits.status ? <MemberCreditStatus status={memberCredits.status} /> : translation.undefined,
                        style: "text-right"
                    }
                ].filter(value => value.value !== undefined)
            ]);
        }
    }, [updateMemberCredits, memberCredits, userWallets]);

    const handleCalculateCreditChange = (value: number, user: UserInfo) => {
        if (userWallets) {
            const userListIndex = userWallets.findIndex(data => data.user.id === user.id);
            if (userListIndex !== -1) {
                const updatedListOfUsers = [...userWallets];
                const userUpdate = updatedListOfUsers[userListIndex];
                if (userUpdate.credits.allocated !== undefined) {
                    const difference = value - userUpdate.credits.allocated;
                    userUpdate.increment += difference;
                    userUpdate.credits.allocated = value;

                    for (const adminWallet of updatedListOfUsers.filter(wallet => isAdministrator(wallet.user))) {
                        adminWallet.increment -= difference;
                    }
                }
                setUserWallets(updatedListOfUsers);

                const isCreditIndex = updatedUserCredits.findIndex(data => data.userInfo.id === user.id);

                if (isCreditIndex !== -1) {
                    const updatedUserCreditsList = [...updatedUserCredits];

                    if (updatedListOfUsers[userListIndex].increment === 0) {
                        updatedUserCreditsList.splice(isCreditIndex, 1);
                    } else {
                        updatedUserCreditsList[isCreditIndex].value = value;
                    }

                    setUpdatedUserCredits(updatedUserCreditsList);
                } else {
                    setUpdatedUserCredits(prevCredits => [...prevCredits, { value, userInfo: user }]);
                }
            }
        }
    };

    const onCreditChange = (inputValue: number, user: UserInfo) => {
        handleCalculateCreditChange(inputValue, user);
    };

    useEffect(() => {
        if (memberCredits && userWallets) {
            const maxCredit = getAvailableCreditsForAdmin(memberCredits, userWallets);
            const usersCellList = userWallets.map(({ user, credits, increment }) =>
                [
                    {
                        value: translation.userWalletRole(user.role),
                        style: isAdministrator(user) ? "font-bold" : "font-normal"
                    },
                    {
                        value: translation.userWalletIdentity(user),
                        style: isAdministrator(user) ? "font-bold" : "font-normal"
                    },
                    {
                        value: handleRenderAvailbleCredits(credits.available, increment),
                        style: "font-bold text-right",
                        merge: isAdministrator(user)
                    },
                    {
                        value:
                            credits?.lost !== undefined ? (
                                <div className="flex items-center justify-end gap-1.5">
                                    {credits.lost}
                                    <Dot color="bg-orange" size="small" />
                                </div>
                            ) : undefined
                    },
                    {
                        value: credits.consumed,
                        style: "text-right"
                    },
                    {
                        value:
                            credits.allocated !== undefined ? (
                                <IncrementInput
                                    value={credits.allocated}
                                    onChange={(inputValue: number) => onCreditChange(inputValue, user)}
                                    disabled={allocateCredits(user) === undefined}
                                    min={credits.consumed}
                                    max={maxCredit + credits.allocated}
                                />
                            ) : (
                                translation.undefined
                            ),
                        style: isAdministrator(user) ? "text-center" : "flex justify-center",
                        merge: isAdministrator(user)
                    }
                ].filter(value => value.value !== undefined)
            );

            setUserValues(usersCellList);
        }
    }, [userWallets, memberCredits]);

    const memberTitles: Cell[] = [
        { value: translation.availablePlural, style: "text-right" },
        {
            value: memberCredits?.lost ? (
                <div className="flex items-center justify-end gap-1.5">
                    {translation.expiredPlural}
                    <Dot color="bg-orange" size="small" />
                </div>
            ) : undefined,
            style: "text-right font-normal"
        },
        { value: translation.purchasedPlural, style: "font-normal text-right" },
        { value: translation.consummed, style: "font-normal text-right" },
        { value: translation.expireAt, style: "font-normal text-right" },
        { value: translation.statut, style: "font-normal text-right" }
    ].filter(value => value.value !== undefined);

    const handleDisableSaveButton = (): boolean => {
        if (updateMemberCredit(memberCredits)) {
            if (updateMemberCredits) {
                if (updateMemberCredits.creditToPurchased !== memberCredits.purchased) {
                    return false;
                }

                if (updateMemberCredits.date !== memberCredits.expiresAt) {
                    return false;
                }
            }
        }

        return true;
    };

    const userTitles: Cell[] = [
        { value: translation.role, style: "text-left" },
        { value: translation.tableCreditName, style: "font-normal text-left" },
        { value: translation.availablePlural, style: "text-right" },
        {
            value: memberCredits.lost ? (
                <div className="flex items-center justify-end gap-1.5">
                    {translation.expiredPlural}
                    <Dot color="bg-orange" size="small" />
                </div>
            ) : undefined,
            style: "text-right font-normal"
        },
        { value: translation.consummed, style: "font-normal text-right" },
        { value: translation.allocated, style: "font-normal text-center" }
    ].filter(value => value.value !== undefined);

    const adminCount = (userWallets || []).filter(userWallet => isAdministrator(userWallet.user)).length;

    const filter = userValues?.map((row, rowIndex) => {
        if (rowIndex > 0 && adminCount > rowIndex) {
            return row.filter(cell => !cell.merge);
        }
        return row;
    });

    const cancelUserModification = () => {
        setUpdatedUserCredits([]);
        void refresh?.();
    };

    const cancelMemberModification = () => {
        setMemberExpiredDate(memberCredits.expiresAt ?? "");
        setUpdateMemberCredits(undefined);
        void refresh?.();
    };

    const submitUserModification = async () => {
        if (updatedUserCredits.length) {
            const promiseFn = updatedUserCredits.map(({ value, userInfo }) => {
                const allocateCreditsFn = allocateCredits(userInfo);
                if (allocateCreditsFn) {
                    return fetchApi(() => allocateCreditsFn(value));
                }
                return Promise.resolve();
            });

            await Promise.all(promiseFn).then(() => {
                toast.success(translation.successCreditModification);
                setUpdatedUserCredits([]);
                void refresh?.();
            });
        }
    };

    const submitMemberModification = async () => {
        if (updateMemberCredits) {
            const responseFn = updateMemberCredit(memberCredits);
            if (responseFn) {
                await fetchApi(
                    () => responseFn(updateMemberCredits.creditToPurchased, updateMemberCredits.date),
                    () => {
                        toast.success(translation.successCreditModification);
                        void refresh?.();
                    }
                );
            }
        }
    };

    return (
        <>
            <div className="space-y-2.5">
                <h2 className="font-semibold text-sm text-label">{translation.member.label}</h2>
                <SimpleTable titles={memberTitles} values={memberValues} />
            </div>
            {updateMemberCredit(memberCredits) && (
                <div className="flex flex-col justify-center items-center pt-5 pb-8 space-y-5">
                    <Button label={translation.saveModification} disabled={handleDisableSaveButton()} onClick={submitMemberModification} />
                    <Button
                        label={translation.cancelModification}
                        variant="link"
                        onClick={cancelMemberModification}
                        underline
                        disabled={handleDisableSaveButton()}
                        size="sm"
                    />
                </div>
            )}
            <div className="space-y-2.5 pt-10">
                <h2 className="font-semibold text-sm text-label">{translation.users}</h2>
                <SimpleTable titles={userTitles} values={filter} layout="auto" mergeLength={adminCount} />
            </div>
            <div className="flex flex-col justify-center items-center pt-7 space-y-5">
                <Button label={translation.saveModification} disabled={updatedUserCredits.length === 0} onClick={submitUserModification} />
                <Button
                    label={translation.cancelModification}
                    onClick={cancelUserModification}
                    variant="link"
                    disabled={updatedUserCredits.length === 0}
                    underline
                    size="sm"
                />
            </div>
        </>
    );
}
