import { Dot, LayoutSide, LayoutSideElement, Loader } from "../../../design-system";
import { standardFormatDate } from "../../helpers";
import { Translation, useI18n } from "../../i18n";
import { MemberCredit } from "../../model";

export default function MemberSideCredits({ memberCredits, actions = [] }: { memberCredits?: MemberCredit; actions?: LayoutSideElement[] }): JSX.Element {
    const { translation } = useI18n<Translation>();

    const SIDE_INFORMATIONS: LayoutSideElement[] = [
        {
            elementType: "component",
            component: (
                <>
                    {translation.label(translation.availableCredits)} <strong>{memberCredits?.available}</strong>
                </>
            )
        },
        {
            elementType: "component",
            component: (
                <ul className="list-disc pl-6">
                    <li>
                        {translation.label(translation.nonAllocatedCredit)} <strong>{memberCredits?.availableAndNotAllocated}</strong>
                    </li>
                    <li>
                        {translation.label(translation.allocatedCredit)} <strong>{memberCredits?.allocatedAndAvailable}</strong>
                    </li>
                </ul>
            )
        },
        {
            elementType: "component",
            component: (
                <>
                    {translation.label(translation.expireAt)} <strong>{standardFormatDate(memberCredits?.expiresAt)}</strong>
                </>
            )
        }
    ];

    const SIDE_EXPIRED: LayoutSideElement[] = [
        {
            elementType: "component",
            component: (
                <div className="flex items-center gap-1.5">
                    <Dot color="bg-orange" size="small" />
                    <p className="font-light text-sm text-deep-blue">: {translation.onlyVisibleByAPP}</p>
                </div>
            )
        }
    ];

    if (!memberCredits) {
        return <Loader />;
    }

    return (
        <>
            {actions.length > 0 && <LayoutSide title={translation.actions} sideElements={actions} />}
            <LayoutSide title={translation.information} sideElements={SIDE_INFORMATIONS} />
            {!!memberCredits?.lost && <LayoutSide sideElements={SIDE_EXPIRED} />}
        </>
    );
}
