import { Chip, Size } from "../../../design-system";
import { Translation, useI18n } from "../../i18n";
import { Representative } from "../../model";

const COLORS: { [key in Representative.Status]: "success" | "neutral" | "warning" | "primary" } = {
    [Representative.Status.SENT]: "primary",
    [Representative.Status.ACCEPTED]: "success",
    [Representative.Status.REVOKED]: "warning",
    [Representative.Status.REFUSED]: "warning",
    [Representative.Status.CANCELED]: "warning",
};

export default function RepresentativeStatus({ status, size = "md" }: { status: Representative.Status; size?: Size }): JSX.Element {
    const { translation } = useI18n<Translation>();
    return <Chip label={translation.representativeStatus[status]} type={COLORS[status]} size={size} tooltipContent={status} />;
}
