import {
    Archive,
    Country,
    Credits,
    Deposit,
    DepositApi,
    DepositSealResponse,
    DepositStats,
    DepositStore,
    DepositUpdate,
    DepositUpdateResponse,
    Link,
    Logibox,
    MemberCredit
} from "../model";
import { doDelete, doGet, doPost, doPut } from "./api.service";

const checkUrl = (url: string) => {
    if (!url.startsWith(`${process.env.REACT_APP_DEPOSIT_API_URL}`)) {
        throw new Error("Invalid url: not an Interdeposit API");
    }
};

const absolute = (url: string): string => {
    return `${process.env.REACT_APP_DEPOSIT_API_URL}${url}`;
};

export function getInformation(): Promise<DepositApi> {
    return doGet<DepositApi>(absolute("/v1/api"));
}

export function findAllUnsealedPhysicalDeposits(): Promise<Deposit[]> {
    const url: string = absolute("/v1/deposits");

    return doGet(url, {
        depositType: "PHYSICAL",
        sealStatus: "UNSEALED"
    });
}

export function findArchivedPhysicalDeposits(queryParams: string): Promise<Deposit[]> {
    const url: string = absolute("/v1/deposits");

    return doGet(url, {
        depositType: "PHYSICAL",
        sealStatus: "ARCHIVED",
        query: queryParams
    });
}

export function getDepositById(url: string): Promise<Deposit> {
    checkUrl(url);

    return doGet<Deposit>(url);
}

export function updateDeposit(deposit: Deposit | CreateDepositResponse, depositInfo: DepositUpdate): Promise<DepositUpdateResponse> {
    if (deposit._links.update) {
        return doPut(deposit._links.update.href, depositInfo);
    }

    throw new Error("Deposit update not allowed");
}

export function getMemberDepositsStats(url: string): Promise<DepositStats> {
    checkUrl(url);

    return doGet<DepositStats>(url);
}

export function getDepositStatsByUserId(userId: string): Promise<DepositStats> {
    const url: string = absolute(`/v1/users/${userId}/deposits-stats`);

    return doGet(url);
}

export function sealDeposit(
    apiResponse: DepositUpdateResponse | UploadFileResponse | FinalizeUploadResponse
): (() => Promise<DepositSealResponse>) | undefined {
    const link = apiResponse._links.seal;

    if (!link) {
        return undefined;
    }

    return () => doPost(link.href);
}

export function getInArchiveStatus(): Archive {
    const listOfInProgressLogibox: Archive = {
        logiboxClosed: [
            {
                logiboxId: "753",
                logiboxHashId: "#753"
            },
            {
                logiboxId: "754",
                logiboxHashId: "#754"
            }
        ],
        logiboxInProgress: {
            logiboxId: "755",
            logiboxHashId: "#755"
        },
        logiboxToBeClosed: [
            {
                logiboxId: "756",
                logiboxHashId: "#756"
            },
            {
                logiboxId: "757",
                logiboxHashId: "#757"
            }
        ]
    };

    return listOfInProgressLogibox;
}

export function getLogiboxesByReferences(references: string[]): Promise<Logibox[]> {
    return doGet(absolute(`/v1/logiboxes?references=${references.join(",")}`));
}

export type CreateDepositRequest = {
    name: string;
    subtitle?: string;
    type: Deposit.Type;
    subscriptionTypeCode: Deposit.SubscriptionTypeCode;
    workNature?: Deposit.WorkNature;
    productClass: Deposit.ProductClass;
    workType: Deposit.WorkType;
    countryCode: Country;
    workVersion?: string;
    workVersionDate?: string;
};

export type CreateDepositResponse = {
    id: string;
    depositStoreId: string;
    name: string;
    subtitle?: string;
    _links: {
        self: Link;
        depositStore: Link;
        upload?: Link;
        update?: Link;
        initializeUpload: Link;
    };
};

export function saveDepositMetadata(
    depositCreationInfo: DepositApi | DepositStore
): ((userId: string, memberId: string, depositCreationRequest: CreateDepositRequest) => Promise<CreateDepositResponse>) | undefined {
    const link = (depositCreationInfo as DepositApi)._links.create || (depositCreationInfo as DepositStore)._links.addDeposit;

    if (!link) {
        return undefined;
    }

    return (userId: string, memberId: string, depositCreationRequest: CreateDepositRequest) =>
        doPost(link.href, {
            ...depositCreationRequest,
            ownerId: memberId,
            creatorId: userId
        });
}

export type UploadFileResponse = {
    id: string;
    depositStoreId: string;
    _links: {
        self: Link;
        depositStore: Link;
        upload?: Link;
        seal?: Link;
    };
};

export function uploadFile(deposit: Deposit | CreateDepositResponse): ((file: File) => Promise<UploadFileResponse>) | undefined {
    const link = deposit._links.upload;

    if (!link) {
        return undefined;
    }

    return (file: File) => {
        const formData = new FormData();
        formData.append("path", file.name);
        formData.append("file", file);

        return doPost(link.href, formData);
    };
}

export function getOwnCredits(depositApi: DepositApi): (() => Promise<Credits>) | undefined {
    const link = depositApi._links.ownCredits;

    if (!link) {
        return undefined;
    }

    return () => doGet(link.href);
}

export function getDeleteDepositMetadataFn(deposit: Deposit): (() => Promise<void>) | undefined {
    const link = deposit._links.delete;
    if (!link) {
        return undefined;
    }
    return () => doDelete(link.href);
}

export function canResumeDeposit(deposit: Deposit): boolean {
    return !!deposit._links.resume;
}

export async function resumeDeposit(url: string): Promise<Deposit> {
    checkUrl(url);

    const deposit = await doGet<Deposit>(url);

    if (canResumeDeposit(deposit)) {
        return Promise.resolve(deposit);
    }

    throw new Error("Deposit cannot be resumed");
}

export function getMemberCredit(memberId: string): Promise<MemberCredit> {
    const url = absolute(`/v1/members/${memberId}/member-credit`);

    return doGet<MemberCredit>(url);
}

export interface InitializeUploadResponse {
    _links: {
        uploadChunk: Link;
    };
}

export function initializeUpload(deposit: Deposit | CreateDepositResponse): ((file: File) => Promise<InitializeUploadResponse>) | undefined {
    const link = deposit._links.initializeUpload;

    if (!link.available) {
        return undefined;
    }

    return (file: File) => {
        return doPost<InitializeUploadResponse>(link.href, {
            path: file.name,
            sizeInBytes: file.size
        });
    };
}

export interface UploadChunkResponse {
    _links: {
        uploadChunk: Link;
        finalizeUpload: Link;
    };
}

export function uploadChunk(
    response: InitializeUploadResponse
): ((chunk: Blob, currentChunk: number, chunksCount: number) => Promise<UploadChunkResponse>) | undefined {
    const link = response._links.uploadChunk;

    if (!link.available) {
        return undefined;
    }

    return (chunk: Blob, currentChunk: number, chunksCount: number) => {
        const formData = new FormData();
        formData.append("currentChunk", currentChunk.toString());
        formData.append("chunksCount", chunksCount.toString());
        formData.append("content", chunk);
        formData.append("sizeInBytes", chunk.size.toString());

        return doPost<UploadChunkResponse>(link.href, formData);
    };
}

export interface FinalizeUploadResponse {
    _links: {
        seal: Link;
    };
}

export function finalizeUpload(response: UploadChunkResponse): (() => Promise<FinalizeUploadResponse>) | undefined {
    const link = response._links.finalizeUpload;

    if (!link.available) {
        return undefined;
    }

    return () => doPost<FinalizeUploadResponse>(link.href);
}

export function deleteAllFiles(deposit: Deposit): (() => Promise<void>) | undefined {
    const link = deposit._links.deleteAllFiles;

    if (!link.available) {
        return undefined;
    }

    return () => doDelete<void>(link.href);
}

export function addHolder(deposit: Deposit): ((holderId: string) => Promise<void>) | undefined {
    const link = deposit._links.addHolder;

    if (!link?.available) {
        return undefined;
    }

    return (holderId: string) => doPost<void>(link.href, { memberId: holderId });
}
export function setSingleHolder(deposit: Deposit): (() => Promise<void>) | undefined {
    const link = deposit._links.setSingleHolder;

    if (!link?.available) {
        return undefined;
    }

    return () => doPost<void>(link.href);
}

export function setSharedHolders(deposit: Deposit): (() => Promise<Deposit.HoldersManagement>) | undefined {
    const link = deposit._links.setSharedHolders;

    if (!link?.available) {
        return undefined;
    }

    return () => doPost<Deposit.HoldersManagement>(link.href);
}

export function setRepresentative(deposit: Deposit): (() => Promise<Deposit.HoldersManagement>) | undefined {
    const link = deposit._links.setRepresentative;

    if (!link?.available) {
        return undefined;
    }

    return () => doPost<Deposit.HoldersManagement>(link.href);
}

export function addHolders(response: Deposit.HoldersManagement): ((memberIds: string[]) => Promise<void>) | undefined {
    const link = response._links.addHolders;

    if (!link?.available) {
        return undefined;
    }

    return memberIds => doPost<void>(link.href, { memberIds: memberIds });
}
