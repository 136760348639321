import { LayoutSide, LayoutSideElement } from "../../../design-system";
import { Translation, useI18n } from "../../i18n";
import { UserInfo } from "../../model";

export default function DepositContributorsSide({ contributors }: { contributors?: UserInfo[] }): JSX.Element {
    const { translation, translate } = useI18n<Translation>();

    const contributorStatus = (): LayoutSideElement[] => {
        const status: LayoutSideElement[] = [];

        if (contributors) {
            const activeStatus = contributors.filter(contributor => contributor.active);
            const inactiveStatus = contributors.filter(contributor => !contributor.active);

            if (activeStatus.length) {
                status.push({ label: translate(translation.contributorsActiveCount, activeStatus.length) });
            }

            if (inactiveStatus.length) {
                status.push({ label: translate(translation.contributorsRevokedCount, inactiveStatus.length) });
            }
        }

        return status;
    };

    return <LayoutSide title={translation.contributors} sideElements={contributorStatus()} />;
}
