import classNames from "classnames";
import { ReactNode } from "react";

import { ChevronMedium } from "../Icons";
import { Link } from "react-router-dom";

export type MenuItem = {
    title: string;
    icon: ReactNode;
    path: string;
};

export default function Menu({
    items,
    onToggle,
    openMenu,
    className,
    selectedMenuItem
}: {
    items: MenuItem[];
    onToggle: () => void;
    openMenu?: boolean;
    className?: string;
    selectedMenuItem?: MenuItem;
}) {
    return (
        <nav
            className={classNames("ease-in-out duration-200 bg-primary", className, {
                "w-52": openMenu,
                "w-16": !openMenu
            })}
        >
            <ul>
                {items.map(({ title, icon, path }) => (
                    <li key={title}>
                        <Link
                            to={path}
                            className={classNames("flex items-center space-x-5 px-4 py-2.5 m-2 h-12 rounded cursor-pointer ease-in-out duration-200", {
                                "bg-white text-primary font-bold": selectedMenuItem?.title === title,
                                "bg-primary text-light-grey font-light hover:bg-primary-mdlight": selectedMenuItem?.title !== title
                            })}
                        >
                            <span
                                className={classNames("w-3.5", {
                                    "fill-danger-high": selectedMenuItem?.title === title,
                                    "fill-light-grey": selectedMenuItem?.title !== title
                                })}
                            >
                                {icon}
                            </span>
                            <span
                                className={classNames("ease-in-out duration-200 whitespace-nowrap", {
                                    "opacity-0": !openMenu,
                                    "opacity-100": openMenu
                                })}
                            >
                                {title}
                            </span>
                        </Link>
                    </li>
                ))}
            </ul>
            <button
                className={classNames(
                    "flex items-center justify-center absolute right-0 left-0 mx-auto bottom-20 bg-primary-mdlight border-4 border-primary rounded-md h-8 w-8 ease-in-out duration-200 hover:scale-105 hover:shadow-sm",
                    {
                        "rotate-0 mr-4": openMenu,
                        "rotate-180": !openMenu
                    }
                )}
                onClick={onToggle}
            >
                <ChevronMedium className="w-3.5 h-3.5 fill-white" />
            </button>
        </nav>
    );
}
