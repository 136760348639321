import { isNotBlank } from ".";
import { Deposit, DepositApi } from "../model";

export function productClassCode(depositApi: DepositApi, productClass: Deposit.ProductClass): string | undefined {
    return depositApi.productClasses
        .flatMap(pClass => [{ code: pClass.code, value: pClass.value }, ...(pClass.values || [])])
        .find(pClass => pClass.value === productClass)?.code;
}

export function depositCreatorDisplayName({ member, user }: { member: { name: string }; user?: { firstName?: string; lastName?: string } }): string {
    if (user) {
        return [user.firstName, user.lastName].filter(isNotBlank).join(" ");
    }
    return member.name;
}
