import { Layout, LetterOpen, useI18n } from "@vaultinum/app-sdk";
import { CreateDeposit } from "../../../components";
import { Translation } from "../../../i18n";
import { DepositStep } from "../../../model";

export default function UpdateDepositPage(): JSX.Element {
    const { translation } = useI18n<Translation>();

    return (
        <Layout title={translation.depositAction} icon={<LetterOpen className="w-6 h-8 fill-primary" />}>
            <div className="max-w-3xl mx-auto w-full">
                <CreateDeposit step={DepositStep.RIGHTS} />
            </div>
        </Layout>
    );
}
