import { Link } from "./Hateoas";
import { Pagination } from "./Pagination";

export namespace Invoice {
    export enum Status {
        UNPAID = "UNPAID",
        PAID = "PAID",
        CANCELLED = "CANCELLED"
    }

    export interface Item {
        name: string;
        quantity: number;
    }
}

export interface Invoice {
    reference: string;
    totalVatExcluded: number;
    totalVatIncluded: number;
    status: Invoice.Status;
    createdAt: string;
    items: Invoice.Item[];
    _links: {
        downloadPdf?: Link;
        payment?: Link;
    };
}

export interface PaginatedInvoices {
    invoices: Invoice[];
    pagination: Pagination;
}
