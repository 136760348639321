import {
    AddDepositLinkDescription,
    AgreementSide,
    Button,
    DownloadIcon,
    Info,
    InterdepositContext,
    LayoutSide,
    LayoutSideElement,
    Loader,
    ReloadIcon,
    ShareOutlinedIcon,
    standardFormatDate,
    useI18n
} from "@vaultinum/app-sdk";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Translation } from "../../../i18n";
import { toDepositSorePageCreate } from "../../../services";
import HolderCard from "../../Deposits/HolderCard";

export default function DepositStoreTabSide(): JSX.Element {
    const { translation, translate } = useI18n<Translation>();
    const navigate = useNavigate();
    const { depositStore } = useContext(InterdepositContext);

    if (!depositStore) {
        return <Loader />;
    }

    const SIDE_ACTIONS: LayoutSideElement[] = [
        {
            elementType: "component",
            component: (
                <div className="space-y-2.5">
                    <Button
                        onClick={depositStore && (() => navigate(toDepositSorePageCreate(depositStore)))}
                        label={translation.update}
                        disabled={!depositStore._links.addDeposit.available}
                    />
                    {depositStore._links.addDeposit.available && depositStore?._links.addDeposit.description === AddDepositLinkDescription.NO_CREDIT && (
                        <Info type="warning" noBackground noPadding>
                            {translation.noCreditBuyCredit}
                        </Info>
                    )}
                </div>
            )
        }
    ];

    const SIDE_INFORMATIONS: LayoutSideElement[] = [
        {
            elementType: "textWithIcon",
            label: `${translation.deposit.firstDepositWithDate} ${standardFormatDate(depositStore.createdAt)}`,
            icon: <DownloadIcon className="fill-deep-blue" />
        },
        {
            icon: <ReloadIcon className="fill-deep-blue" />,
            label: `${translation.deposit.lastUpdateWithDate} ${standardFormatDate(depositStore.updatedAt)}`,
            elementType: "textWithIcon"
        },
        {
            elementType: "textWithIcon",
            label: translate(translation.deposit.numberOfDeposit, depositStore.depositsCount || 0),
            icon: <ShareOutlinedIcon className="fill-deep-blue" />
        }
    ];

    return (
        <>
            <LayoutSide title={translation.actions} sideElements={SIDE_ACTIONS} />
            <LayoutSide title={translation.information} sideElements={SIDE_INFORMATIONS} />
            {depositStore.agreements && depositStore.agreements.length > 0 && <AgreementSide agreements={depositStore.agreements} />}
            {depositStore.holders && (
                <LayoutSide
                    title={translation.depositHoldersTitle(depositStore.holders.length)}
                    sideElements={depositStore.holders
                        ?.map((holder, index) => <HolderCard holder={holder} withBorderTop={index > 0} key={holder.memberId} />)
                        .map(component => {
                            return { elementType: "component", component: component };
                        })}
                />
            )}
        </>
    );
}
