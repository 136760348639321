import { Layout, MoneyIcon, Stepper, useI18n } from "@vaultinum/app-sdk";
import { ReactNode } from "react";
import { Translation } from "../../../i18n";

type Step = {
    title: string;
};

export default function CreditsPageTemplate({
    children,
    description,
    currentStep
}: {
    children: ReactNode;
    description?: string;
    currentStep?: number;
}): ReactNode {
    const { translation } = useI18n<Translation>();

    const steps: Step[] = [{ title: translation.articlesSelection }, { title: translation.paymentMethod }, { title: translation.summary }];

    return (
        <Layout title="Commande de crédits" icon={<MoneyIcon className="fill-primary" />}>
            {currentStep && <Stepper currentStep={currentStep} stepItems={steps} className="justify-center w-full" />}
            <div className="my-5">
                {currentStep && <h3 className="text-center text-primary font-bold">{steps[currentStep - 1].title}</h3>}
                {description && <p className="text-center">{description}</p>}
            </div>
            {children}
        </Layout>
    );
}
