import classNames from "classnames";
import { ReactNode } from "react";
import { Tooltip } from "..";
import { ChevronSmall } from "../Icons";

type BreadCrumbItem = {
    label: string;
    path?: string;
    icon?: ReactNode;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    hiddenContent?: string;
};

export default function Breadcrumb({ breadCrumbList }: { breadCrumbList: BreadCrumbItem[] }): JSX.Element {
    const lastItemIndex = breadCrumbList.length - 1;

    return (
        <nav className="flex">
            <ol className="inline-flex items-center">
                {breadCrumbList.map(({ path, icon, label, onClick, hiddenContent }, index) => (
                    <li className="flex items-center" key={label}>
                        {index < lastItemIndex && (
                            <>
                                <Tooltip message={hiddenContent}>
                                    <a
                                        href={path}
                                        className="flex items-center text-sm font-medium cursor-pointer text-deep-blue hover:underline max-w-xs"
                                        onClick={onClick}
                                    >
                                        {icon}
                                        <span
                                            title={label}
                                            className={classNames({
                                                truncate: index > 0
                                            })}
                                        >
                                            {label}
                                        </span>
                                    </a>
                                </Tooltip>
                                <ChevronSmall className="w-2.5 h-2.5 rotate-90 fill-deep-blue mx-2" />
                            </>
                        )}
                        {index === lastItemIndex && (
                            <div className="flex items-center">
                                <Tooltip message={hiddenContent}>
                                    <span className="text-sm font-bold text-deep-blue">{label}</span>
                                </Tooltip>
                            </div>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
}
