import { CreditCardIcon, Layout, Loader, MemberInvoicesList, Tabs, useI18n, UserContext, useToast } from "@vaultinum/app-sdk";
import { useContext, useEffect } from "react";

import { OrderContext } from "../../contexts";
import { Translation } from "../../i18n";

export enum InvoicesAndPaymentTabs {
    PAYMENT = "payment",
    INVOICES = "invoices"
}

const InvoicesList = ({ memberId }: { memberId: string }): JSX.Element => {
    const { isOrderValidated, setIsOrderValidated } = useContext(OrderContext);

    const { translation } = useI18n<Translation>();
    const toast = useToast();

    useEffect(() => {
        if (isOrderValidated) {
            toast.success(translation.orderAccepted);
        }

        return () => setIsOrderValidated(false);
    }, [isOrderValidated]);

    return <MemberInvoicesList memberId={memberId} />;
};

export default function InvoicesAndPaymentsPage(): JSX.Element {
    const { user } = useContext(UserContext);
    const { translation } = useI18n<Translation>();

    const tabs = [
        {
            key: InvoicesAndPaymentTabs.INVOICES,
            title: translation.invoices,
            component: (user?.memberId && <InvoicesList memberId={user.memberId} />) || <Loader />
        }
    ];

    return (
        <Layout title={translation.paymentAndResumeInvoices} icon={<CreditCardIcon className="fill-primary" />}>
            <Tabs tabs={tabs} />
        </Layout>
    );
}
