import {
    getMemberApi,
    getMemberCredit,
    getMemberDetails,
    Layout,
    Loader,
    Member,
    MemberApi,
    MemberCredit,
    MemberCredits,
    MemberSideCredits,
    MoneyIcon,
    useApi,
    useI18n
} from "@vaultinum/app-sdk";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { OrderContext } from "../../contexts";
import { Translation } from "../../i18n";
import { CREDIT_BUY_PAGE } from "../../services";

export default function CreditPage() {
    const [memberCredits, setMemberCredits] = useState<MemberCredit | undefined>();
    const { fetchApi, isLoading } = useApi();
    const { translation } = useI18n<Translation>();
    const navigate = useNavigate();
    const { createOrder } = useContext(OrderContext);

    const loadInfos = async () => {
        await fetchApi<MemberApi>(getMemberApi, async (memberApi: MemberApi) => {
            if (memberApi) {
                const memberFn = getMemberDetails(memberApi);
                if (memberFn) {
                    await fetchApi(memberFn, async (response: Member) => {
                        await fetchApi(
                            () => getMemberCredit(response.id),
                            creditResponse => setMemberCredits(creditResponse)
                        );
                    });
                }
            }
        });
    };

    useEffect(() => {
        void loadInfos();
    }, []);

    if (!memberCredits) {
        return <Loader />;
    }

    return (
        <Layout
            title={translation.credits}
            icon={<MoneyIcon className="w-6 h-8 fill-primary" />}
            rightContent={
                <MemberSideCredits
                    memberCredits={memberCredits}
                    actions={
                        createOrder
                            ? [
                                  {
                                      label: translation.buyCredits,
                                      elementType: "button",
                                      action: () => navigate(CREDIT_BUY_PAGE)
                                  }
                              ]
                            : []
                    }
                />
            }
            isLoading={isLoading}
        >
            <MemberCredits memberCredits={memberCredits} refresh={loadInfos} />
        </Layout>
    );
}
