export enum Method {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE"
}

export enum AddDepositLinkDescription {
    NO_CREDIT = "NO_CREDIT",
    NO_RIGHT = "NO_RIGHT",
    ALLOWED = "ALLOWED",
    SEAL_STATUS_DOES_NOT_ALLOW_TO_ADD_DEPOSIT = "SEAL_STATUS_DOES_NOT_ALLOW_TO_ADD_DEPOSIT"
}

export interface Link {
    readonly href: string;
    readonly available?: boolean;
    readonly method?: Method;
    readonly description?: AddDepositLinkDescription;
    readonly reason?: string;
}
