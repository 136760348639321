import { ReactNode } from "react";

import { CopyableElement } from "../../../design-system";
import { getNodeText } from "../../../design-system/helpers";
import { isNotBlank } from "../../helpers";

export type DepositSectionData = { title: string; value: ReactNode | undefined; copyable?: boolean };

const DepositDataVaue = ({ value, copyable }: { value: ReactNode; copyable: boolean }): ReactNode => {
    if (copyable) {
        return <CopyableElement value={getNodeText(value)}>{value}</CopyableElement>;
    }

    return value;
};

const DepositSectionProps = ({ title, value, copyable = true }: { title: string; value: ReactNode; copyable?: boolean }): ReactNode => (
    <div className="flex gap-2">
        <dt className="font-bold whitespace-nowrap">{title}</dt>
        <dd>{value && (typeof value !== "string" || isNotBlank(value)) ? <DepositDataVaue value={value} copyable={copyable} /> : "-"}</dd>
    </div>
);

export default function DepositSection({ title, data }: { title: string; data: DepositSectionData[] }): JSX.Element {
    return (
        <div className="flex flex-col text-color-disabled h-full">
            <span className=" text-primary font-bold text-sm uppercase bg-primary-xlight p-2">{title}</span>
            <dl className="flex flex-col gap-y-1.5 border border-gray-200 h-full p-2">
                {data.map(({ title: dataTitle, value, copyable }) => (
                    <DepositSectionProps title={dataTitle} value={value} key={dataTitle} copyable={copyable} />
                ))}
            </dl>
        </div>
    );
}
