import { Chip, Size } from "../../../design-system";
import { SealStatus } from "../../model";

export const SEAL_STATUS_COLORS: { [key in SealStatus]: "success" | "neutral" | "warning" | "primary" } = {
    [SealStatus.OPEN]: "warning",
    [SealStatus.IN_PROGRESS]: "primary",
    [SealStatus.SEALED]: "success",
    [SealStatus.ARCHIVED]: "success",
    [SealStatus.ERROR]: "primary",
    [SealStatus.STANDBY]: "warning"
};

export function DepositStatus({
    label,
    status,
    size = "md",
    showToolTip = true
}: {
    label: string;
    status: SealStatus;
    size?: Size;
    showToolTip?: boolean;
}): JSX.Element {
    return <Chip label={label} type={SEAL_STATUS_COLORS[status]} size={size} tooltipContent={status} showToolTip={showToolTip} />;
}
