import {
    ApisContextProvider,
    BreadCrumbContextProvider,
    getEnvironment,
    I18n,
    InterdepositContextProvider,
    Loader,
    ScrollToTop,
    Toast,
    useI18n,
    UserContext,
    UserContextProvider
} from "@vaultinum/app-sdk";
import { useContext, useEffect } from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { PrivatePageLayout } from "./components";
import { MemberContextProvider, OrderContextProvider } from "./contexts";
import { fr, Translation } from "./i18n";
import {
    CREATE_DEPOSIT_PAGE,
    CREDIT_BUY_PAGE,
    CREDIT_BUY_PAYMENT_PAGE,
    CREDIT_BUY_SUCCESS_PAGE,
    CREDIT_BUY_SUMMARY_PAGE,
    CREDIT_PAGE,
    DEPOSIT_CREATORS_PAGE,
    DEPOSIT_PAGE,
    DEPOSIT_STORE_CREATE_DEPOSIT_PAGE,
    DEPOSIT_STORE_PAGE,
    DEPOSIT_STORE_RESUME_DEPOSIT_PAGE,
    DEPOSIT_STORE_UPDATE_DEPOSIT_PAGE,
    HOME_PAGE,
    LOGIN_PAGE,
    PAYMENT_AND_INVOICES_PAGE,
    REQUEST_INVITATION_PAGE,
    RESET_PASSWORD_PAGE,
    TrackingEvent,
    trackingService,
    UPDATE_DEPOSIT_PAGE,
    USER_INFO_PAGE,
    WALLET_PAGE
} from "./services";
import {
    CreateDepositPage,
    CreditPage,
    DepositCreatorsPage,
    DepositPage,
    DepositStoreCreateDepositPage,
    DepositStorePage,
    DepositStoreResumeDepositPage,
    DepositStoreUpdateDepositPage,
    HomePage,
    InvoicesAndPaymentsPage,
    RequestInvitationPage,
    UpdateDepositPage,
    UserInfoPage,
    WalletPage
} from "./views/private";
import { CreditsBuyPaymentPage, CreditsBuySelectionPage, CreditsBuySuccessPage, CreditsBuySummaryPage } from "./views/private/CreditsPage";
import { LoginPage, PasswordPage } from "./views/public";

function PrivateRoutes(): JSX.Element {
    const { translation } = useI18n<Translation>();
    const location = useLocation();

    useEffect(() => {
        trackingService.track(TrackingEvent.NAVIGATION, { path: `${location.pathname}${location.search}` });
    }, [location]);

    document.title = translation.applicationTitles[getEnvironment()];

    return (
        <Routes>
            <Route
                element={
                    <ApisContextProvider>
                        <MemberContextProvider>
                            <BreadCrumbContextProvider>
                                <ScrollToTop />
                                <InterdepositContextProvider>
                                    <PrivatePageLayout>
                                        <Outlet />
                                    </PrivatePageLayout>
                                </InterdepositContextProvider>
                            </BreadCrumbContextProvider>
                        </MemberContextProvider>
                    </ApisContextProvider>
                }
            >
                <Route path={HOME_PAGE} element={<HomePage />} />
                <Route
                    element={
                        <OrderContextProvider>
                            <Outlet />
                        </OrderContextProvider>
                    }
                >
                    <Route path={CREDIT_PAGE} element={<CreditPage />} />
                    <Route path={CREDIT_BUY_PAGE} element={<CreditsBuySelectionPage />} />
                    <Route path={CREDIT_BUY_PAYMENT_PAGE} element={<CreditsBuyPaymentPage />} />
                    <Route path={CREDIT_BUY_SUMMARY_PAGE} element={<CreditsBuySummaryPage />} />
                    <Route path={CREDIT_BUY_SUCCESS_PAGE} element={<CreditsBuySuccessPage />} />
                    <Route path={PAYMENT_AND_INVOICES_PAGE} element={<InvoicesAndPaymentsPage />} />
                </Route>
                <Route path={USER_INFO_PAGE} element={<UserInfoPage />} />
                <Route path={WALLET_PAGE} element={<WalletPage />} />
                <Route path={DEPOSIT_PAGE} element={<DepositPage />} />
                <Route path={DEPOSIT_STORE_PAGE} element={<DepositStorePage />} />
                <Route path={CREATE_DEPOSIT_PAGE} element={<CreateDepositPage />} />
                <Route path={UPDATE_DEPOSIT_PAGE} element={<UpdateDepositPage />} />
                <Route path={DEPOSIT_STORE_CREATE_DEPOSIT_PAGE} element={<DepositStoreCreateDepositPage />} />
                <Route path={DEPOSIT_STORE_UPDATE_DEPOSIT_PAGE} element={<DepositStoreUpdateDepositPage />} />
                <Route path={DEPOSIT_STORE_RESUME_DEPOSIT_PAGE} element={<DepositStoreResumeDepositPage />} />
                <Route path={DEPOSIT_CREATORS_PAGE} element={<DepositCreatorsPage />} />
                <Route path="*" element={<Navigate to={HOME_PAGE} />} />
            </Route>
            <Route path={REQUEST_INVITATION_PAGE} element={<RequestInvitationPage />} />
        </Routes>
    );
}

function PublicRoutes(): JSX.Element {
    const { translation } = useI18n<Translation>();
    document.title = translation.applicationTitles[getEnvironment()];

    return (
        <Routes>
            <Route path={LOGIN_PAGE} element={<LoginPage />} />
            <Route path={RESET_PASSWORD_PAGE} element={<PasswordPage />} />
            <Route path="*" element={<Navigate to={LOGIN_PAGE} />} />
        </Routes>
    );
}

function HandleRoutes(): JSX.Element {
    const { user, isLoading } = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate();
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirectUrl = urlParams.get("redirectUrl");

    useEffect(() => {
        if (!user && location.pathname !== LOGIN_PAGE && !isLoading) {
            window.location.replace(`${LOGIN_PAGE}?redirectUrl=${location.pathname + location.search}`);
        }
    }, [user, location, isLoading]);

    if (isLoading) {
        return <Loader />;
    }

    if (user) {
        if (redirectUrl) {
            navigate(redirectUrl);
        }
        return <PrivateRoutes />;
    }

    return <PublicRoutes />;
}

function AppRouter(): JSX.Element {
    return (
        <BrowserRouter>
            <UserContextProvider>
                <I18n locale="fr" locales={{ fr }}>
                    <Toast />
                    <HandleRoutes />
                </I18n>
            </UserContextProvider>
        </BrowserRouter>
    );
}
export default AppRouter;
