import React from "react";

import { Chip, Cloud, Mail, QuestionMark, Size } from "../../../design-system";
import { Deposit } from "../../model";

const ICON: { [key in Deposit.Type]: React.ReactNode } = {
    [Deposit.Type.DIGITAL]: <Cloud />,
    [Deposit.Type.PHYSICAL]: <Mail />,
    [Deposit.Type.UNKNOWN]: <QuestionMark />
};

export default function DepositStatusIcon({ depositType, tooltipContent, size = "sm" }: { depositType: Deposit.Type; tooltipContent: string; size?: Size }) {
    return <Chip label={ICON[depositType]} tooltipContent={tooltipContent} type="primary" size={size} invertColors />;
}
