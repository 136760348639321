import classNames from "classnames";
import { ReactNode } from "react";

import { Chip, CopyableText, Loader, Title } from "..";

export default function Layout({
    icon,
    title,
    children,
    subTitle,
    header,
    status,
    className,
    specificity,
    rightContent,
    isLoading
}: {
    icon: ReactNode;
    title: string;
    children: ReactNode;
    subTitle?: Record<string, string | undefined>;
    header?: Record<string, string | undefined>;
    status?: ReactNode;
    className?: string;
    specificity?: ReactNode;
    rightContent?: ReactNode;
    isLoading?: boolean;
}) {
    return (
        <div className={classNames("flex flex-col min-h-screen rounded bg-white", className)}>
            <div className="px-6 border-b-2 border-light-grey">
                <div className="flex items-start py-6">
                    <span className="flex items-center justify-center bg-blue-100 rounded mr-2.5 h-12 w-12 p-3">{icon}</span>
                    <div className="flex flex-col w-full m-auto">
                        <Title children={title} />
                        {subTitle && (
                            <div className="flex flex-wrap space-x-3 text-sm text-label">
                                {Object.keys(subTitle).map(key => (
                                    <span key={key}>
                                        {key} <CopyableText value={subTitle[key] || "-"} />
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="flex space-x-2.5">
                        {specificity && <Chip label={specificity} type="primary" invertColors />}
                        {status}
                    </div>
                </div>
                {header && (
                    <div className="flex flex-wrap gap-5 pb-2.5 text-deep-blue text-sm">
                        {Object.keys(header).map(key => (
                            <span key={key}>
                                <span className="font-bold">{key}</span> <CopyableText value={header[key] ?? "-"} />
                            </span>
                        ))}
                    </div>
                )}
            </div>
            <div className="flex flex-col h-full basis-0 grow sm:flex-row">
                <div
                    className={classNames("relative flex flex-col py-4 px-3 border-b-2 border-light-grey sm:border-none", {
                        "w-4/5": rightContent,
                        "w-full": !rightContent
                    })}
                >
                    {isLoading ? <Loader /> : children}
                </div>
                {rightContent && <div className="w-full h-full border-l-2 border-r-2 sm:w-1/5 sm:border-r-0 border-light-grey">{rightContent}</div>}
            </div>
        </div>
    );
}
