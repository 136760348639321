import {
    ContributorsList,
    DepositStatus,
    InterdepositContext,
    Layout,
    Loader,
    Member,
    ShareIcon,
    Tabs,
    UserContext,
    getMemberApi,
    getMemberDetails,
    useApi,
    useI18n
} from "@vaultinum/app-sdk";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import WalletBreadcrumb from "../../../../components/BreadCrumb/Wallet/WalletBreadCrumb";
import { DepositStoreTab, DepositStoreTabSide } from "../../../../components/Tabs";
import ContributorsSide from "../../../../components/Tabs/Contributors/ContributorsSide";
import { Translation } from "../../../../i18n";

export enum DepositStoreTabs {
    DEPOSITSTORE = "depositStore",
    CONTRIBUTOR = "contributor",
    ACTIVITY = "activity"
}

export default function DepositStorePage() {
    const { translation } = useI18n<Translation>();
    const { depositStore } = useContext(InterdepositContext);
    const [memberInfos, setMemberInfos] = useState<Member>();
    const params = useParams();
    const { fetchApi, isLoading } = useApi();
    const { isUserManager } = useContext(UserContext);

    const loadMembersInfos = async () => {
        const memberApi = await getMemberApi();
        const memberFn = getMemberDetails(memberApi);

        if (memberFn) {
            const infos = await fetchApi(memberFn);
            setMemberInfos(infos);
        }
    };

    useEffect(() => {
        void loadMembersInfos();
    }, []);

    const subtitleList: Record<string, string> = {
        "": depositStore?.iddn ?? "-"
    };

    const getTabs = [
        {
            key: DepositStoreTabs.DEPOSITSTORE,
            title: translation.depositStore.name,
            component: <DepositStoreTab />
        },
        {
            key: DepositStoreTabs.CONTRIBUTOR,
            title: translation.deposit.depositors,
            component: memberInfos && depositStore && <ContributorsList member={memberInfos} depositStore={depositStore} disabled={isUserManager} />
        },
        {
            key: DepositStoreTabs.ACTIVITY,
            title: translation.activity,
            component: translation.activity
        }
    ];

    const handleSwitchSide = () => {
        switch (params.tab) {
            case DepositStoreTabs.CONTRIBUTOR:
                return !isUserManager && <ContributorsSide />;
            default:
                return <DepositStoreTabSide />;
        }
    };

    if (!depositStore) {
        return <Loader />;
    }

    return (
        <>
            <WalletBreadcrumb depositStore={depositStore} />
            <Layout
                title={`${translation.depositStore.name} : ${depositStore.name}`}
                subTitle={subtitleList}
                status={
                    <DepositStatus
                        label={translation.deposit.depositStoreStatus[depositStore.sealStatus]}
                        status={depositStore.sealStatus}
                        size="md"
                        showToolTip={false}
                    />
                }
                icon={<ShareIcon className="w-6 h-8 fill-primary" />}
                isLoading={isLoading || !memberInfos}
                rightContent={handleSwitchSide()}
                children={<Tabs tabs={getTabs} />}
            />
        </>
    );
}
