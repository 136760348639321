import { Link } from "../Hateoas";

export enum PaymentMethod {
    CREDIT_CARD = "CREDIT_CARD",
    BANK_TRANSFER = "BANK_TRANSFER"
}

export type Order = {
    _links: {
        checkout?: Link;
        payByBankTransfer?: Link;
    };
};

export type OrderCreation = {
    items: {
        reference: string;
        quantity: number;
    }[];
    externalReference?: string;
};

export type OrderCredit = {
    items: {
        reference: string;
        quantity: number;
    }[];
    externalReference?: string;
};
