export default function SadBox({ className }: { className?: string }): JSX.Element {
    return (
        <svg viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g id="Group 831">
                <g id="Vector">
                    <path
                        d="M25.7227 47.5C25.7227 49.1396 24.3935 50.4688 22.7539 50.4688C21.1143 50.4688 19.7852 49.1396 19.7852 47.5C19.7852 45.8604 21.1143 44.5312 22.7539 44.5312C24.3935 44.5312 25.7227 45.8604 25.7227 47.5Z"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M25.7227 47.5C25.7227 49.1396 24.3935 50.4688 22.7539 50.4688C21.1143 50.4688 19.7852 49.1396 19.7852 47.5C19.7852 45.8604 21.1143 44.5312 22.7539 44.5312C24.3935 44.5312 25.7227 45.8604 25.7227 47.5Z"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M50.2148 47.5C50.2148 49.1396 48.8857 50.4688 47.2461 50.4688C45.6065 50.4688 44.2773 49.1396 44.2773 47.5C44.2773 45.8604 45.6065 44.5312 47.2461 44.5312C48.8857 44.5312 50.2148 45.8604 50.2148 47.5Z"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M50.2148 47.5C50.2148 49.1396 48.8857 50.4688 47.2461 50.4688C45.6065 50.4688 44.2773 49.1396 44.2773 47.5C44.2773 45.8604 45.6065 44.5312 47.2461 44.5312C48.8857 44.5312 50.2148 45.8604 50.2148 47.5Z"
                        fill="#E6E6E6"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M35.1855 55.8496C32.7262 55.8496 30.7324 57.8433 30.7324 60.3027C30.7324 61.0201 30.1509 61.6016 29.4336 61.6016C28.7163 61.6016 28.1348 61.0201 28.1348 60.3027C28.1348 56.4087 31.2915 53.252 35.1855 53.252C39.0796 53.252 42.2363 56.4087 42.2363 60.3027C42.2363 61.0201 41.6548 61.6016 40.9375 61.6016C40.2202 61.6016 39.6387 61.0201 39.6387 60.3027C39.6387 57.8433 37.6449 55.8496 35.1855 55.8496Z"
                        fill="#E6E6E6"
                    />
                </g>
                <g id="Vector_2">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.28975 1.23081C6.66691 0.47649 7.43789 0 8.28125 0H61.7188C62.5621 0 63.3331 0.47649 63.7102 1.23081L69.6478 13.1058C69.8023 13.415 69.8828 13.7559 69.8828 14.1016V64.5703C69.8828 65.9482 69.3355 67.2696 68.3611 68.244C67.3868 69.2183 66.0654 69.7656 64.6875 69.7656H5.3125C3.93462 69.7656 2.61317 69.2183 1.63886 68.244C0.664549 67.2696 0.117188 65.9482 0.117188 64.5703V14.1016C0.117188 13.7559 0.197667 13.415 0.352252 13.1058L6.28975 1.23081ZM9.65734 4.45312L4.57031 14.6272V64.5703C4.57031 64.7672 4.64851 64.9559 4.78769 65.0951C4.92688 65.2343 5.11566 65.3125 5.3125 65.3125H64.6875C64.8843 65.3125 65.0731 65.2343 65.2123 65.0951C65.3515 64.9559 65.4297 64.7672 65.4297 64.5703V14.6272L60.3427 4.45312H9.65734Z"
                        fill="#E6E6E6"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M49.8437 11.875C51.0734 11.875 52.0703 12.8719 52.0703 14.1016V29.6874L67.6561 29.6874C68.8858 29.6874 69.8827 30.6843 69.8827 31.914C69.8827 33.1437 68.8858 34.1406 67.6561 34.1406L49.8437 34.1406C48.614 34.1406 47.6171 33.1437 47.6171 31.914L47.6171 14.1016C47.6171 12.8719 48.614 11.875 49.8437 11.875Z"
                        fill="#E6E6E6"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.117188 14.1016C0.117188 12.8719 1.11405 11.875 2.34375 11.875H67.6562C68.8859 11.875 69.8828 12.8719 69.8828 14.1016C69.8828 15.3313 68.8859 16.3281 67.6562 16.3281H2.34375C1.11405 16.3281 0.117188 15.3313 0.117188 14.1016Z"
                        fill="#E6E6E6"
                    />
                </g>
            </g>
        </svg>
    );
}
