import { RepresentativeStatus, RequestForm } from "..";
import { Card, Info, PlusIcon, Popup, SadBox, SIZE_MAP } from "../../../design-system";
import { standardFormatDate } from "../../helpers";
import { Translation, useI18n } from "../../i18n";
import { Member, Representative } from "../../model";
import DepositSection from "../DepositSection/DepositSection";

export default function Representatives({
    member,
    isFormOpen,
    closePopupForm,
    representatives
}: {
    member: Member;
    isFormOpen: boolean;
    closePopupForm: () => void;
    representatives?: Representative[];
}): JSX.Element {
    const { translation } = useI18n<Translation>();

    return (
        <div>
            <h2 className="text-base font-semibold uppercase pb-5">{translation.representativeName(member)}</h2>
            {!representatives?.length ? (
                <div className="pt-8 gap-y-2.5 flex flex-col justify-center items-center">
                    <SadBox className={SIZE_MAP.xxl} />
                    <span className="text-light-grey">{translation.noRepresentative}</span>
                </div>
            ) : (
                <div className="flex flex-col gap-2.5">
                    <Info type="neutral">{translation.representativeInfo}</Info>
                    <ul className="flex flex-col gap-y-2.5">
                        {representatives.map(representative => (
                            <li key={representative.id}>
                                <Card
                                    title={<span className="font-bold" children={representative.name} />}
                                    rightContent={
                                        <div className="flex gap-x-2.5">
                                            <span className="text-light-grey">|</span>
                                            <span className="px-1.5">
                                                <RepresentativeStatus status={representative.request.status} size="sm" />
                                            </span>
                                        </div>
                                    }
                                >
                                    <div className="flex flex-col gap-4 p-4 font-light text-color-disabled">
                                        <DepositSection
                                            title={translation.representatives}
                                            data={[
                                                { title: translation.label(translation.member.memberNumber), value: representative.fullNumber },
                                                {
                                                    title: translation.label(translation.socialRaison),
                                                    value: representative.siren ?? "-"
                                                },
                                                {
                                                    title: translation.label(translation.personInCharge),
                                                    value: `${representative.owner.firstName} ${representative.owner.lastName}`
                                                },
                                                { title: translation.label(translation.email), value: representative.owner.email }
                                            ]}
                                        />
                                        <DepositSection
                                            title={translation.mandate}
                                            data={[
                                                {
                                                    title: translation.label(translation.state),
                                                    value: translation.representativeStatus[representative.request.status]
                                                },
                                                {
                                                    title: translation.label(translation.mandatDate),
                                                    value: standardFormatDate(representative.request.requestedAt)
                                                },
                                                {
                                                    title: translation.label(translation.revokedDate),
                                                    value: standardFormatDate(representative.request.repliedAt) ?? "-"
                                                }
                                            ]}
                                        />
                                    </div>
                                </Card>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <Popup
                title={
                    <div className="flex items-center">
                        <PlusIcon className="text-green-light w-5 h-5" />
                        <p className="ml-1.5">{translation.inviteMember}</p>
                    </div>
                }
                isOpen={isFormOpen}
                size="xl"
            >
                <RequestForm member={member} cancelSubmit={closePopupForm} />
            </Popup>
        </div>
    );
}
