import { Link, Member } from ".";

export namespace User {
    export enum Role {
        DEVELOPER = "DEVELOPER",
        ADMINISTRATOR = "ADMINISTRATOR",
        SUPER_ADMINISTRATOR = "SUPER_ADMINISTRATOR",
        ADMIN = "ADMIN",
        PUBLISH_USER = "PUBLISH_USER"
    }

    export enum UserWalletRole {
        ADMINISTRATOR = "ADMINISTRATOR",
        CONTRIBUTOR = "CONTRIBUTOR",
        GIT = "GIT"
    }
    export enum Status {
        SENT = "SENT",
        CANCELED = "CANCELED",
        ACCEPTED = "ACCEPTED",
        REFUSED = "REFUSED",
        REVOKED = "REVOKED"
    }
}

export interface User {
    readonly firstName: string;
    readonly lastName: string;
    readonly avatar?: string;
    readonly roles: User.Role[];
    readonly uid: string;
    readonly sub: string;
    readonly memberId?: string;
}

export interface UserInfo {
    id: string;
    firstName: string;
    lastName: string;
    role: User.UserWalletRole;
    email: string;
    active: boolean;
    _links: {
        allocateCredits: Link;
        removeUserManager: Link;
        disable: Link;
        enable: Link;
    };
}

export interface UserWallet {
    user: UserInfo;
    credits: UserCredit;
}

export interface UserCredit {
    consumed: number;
    allocated?: number;
    available: number;
    lost: number;
    expiresAt?: string;
    status?: Member.CreditStatus;
}
