import { useState } from "react";
import { BoxContainer, Button, Card, Info, Input, Loader, SadBox, SIZE_MAP, TickIcon, useToast } from "../../../design-system";
import { useApi } from "../../hooks";
import { Translation, useI18n } from "../../i18n";
import { Member, UserInfo } from "../../model";
import { disableContributor, enableContributor } from "../../services";
import { ContributorStatus } from "../Contributors";

export default function DepositCreators({
    member,
    contributors,
    refresh
}: {
    member: Member;
    contributors: UserInfo[];
    refresh: () => Promise<void>;
}): JSX.Element {
    const { translation } = useI18n<Translation>();
    const [addContributors, setAddContributors] = useState<boolean>(false);
    const [contributorsRefreshing, setContributorsRefreshing] = useState<Record<string, boolean>>({});
    const { fetchApi } = useApi();
    const toast = useToast();

    const sendInvitation = () => {
        alert("Send invitation");
    };

    const handleContributorStatus = async (contributor: UserInfo, statusFn: () => Promise<void>, successMessage: string): Promise<void> => {
        setContributorsRefreshing(prev => ({ ...prev, [contributor.id]: true }));

        await fetchApi(statusFn);

        await refresh();

        toast.success(successMessage);

        setContributorsRefreshing(prev => ({ ...prev, [contributor.id]: false }));
    };

    return (
        <div>
            <h2 className="text-base font-semibold uppercase pb-5">{translation.depositCreatorName(member)}</h2>
            <Info type="neutral">{translation.depositCreatorInfo}</Info>
            <ul className="pt-2.5 space-y-2.5">
                {contributors?.map(contributor => {
                    const disableContributorFn = disableContributor(contributor);
                    const enableContributorFn = enableContributor(contributor);
                    return (
                        <li key={contributor.id}>
                            <Card
                                title={
                                    <span className="font-bold text-label">{`${contributor.firstName} ${contributor.lastName} - ${contributor.email}`}</span>
                                }
                                rightContent={
                                    <div className="flex space-x-2.5">
                                        {contributorsRefreshing[contributor.id] && <Loader position="relative" className="h-1 w-1" />}
                                        {disableContributorFn && !contributorsRefreshing[contributor.id] && (
                                            <Button
                                                variant="link"
                                                onClick={() =>
                                                    handleContributorStatus(contributor, disableContributorFn, translation.toastConfirmationDisableContributor)
                                                }
                                                underline
                                                label={translation.suspend}
                                                size="xs"
                                            />
                                        )}
                                        {enableContributorFn && !contributorsRefreshing[contributor.id] && (
                                            <Button
                                                variant="link"
                                                onClick={() =>
                                                    handleContributorStatus(contributor, enableContributorFn, translation.toastConfirmationEnableContributor)
                                                }
                                                underline
                                                label={translation.activate}
                                                size="xs"
                                            />
                                        )}
                                        <span className="text-light-grey">|</span>
                                        <span className="px-1.5">
                                            <ContributorStatus contributor={contributor} size="sm" />
                                        </span>
                                    </div>
                                }
                            />
                        </li>
                    );
                })}
            </ul>
            <div className="flex flex-col justify-center py-5 items-center">
                <Button onClick={() => setAddContributors(true)} label={translation.inviteContributor} disabled />
            </div>
            {addContributors && (
                <>
                    <div>
                        <BoxContainer title={translation.inviteContributor} icon={<TickIcon className="fill-green-light" />}>
                            <div className="space-y-5">
                                <div className="grid grid-cols-3 gap-5">
                                    <Input label={translation.email} onChange={() => {}} disabled />
                                    <Input label={translation.lastName} onChange={() => {}} disabled />
                                    <Input label={translation.firstName} onChange={() => {}} disabled />
                                </div>
                                <Info type="primary">{translation.addContributorInfo}</Info>
                                <div className="flex flex-col justify-center items-center gap-7">
                                    <Button label={translation.sendInvitation} onClick={sendInvitation} disabled />
                                    <Button variant="link" label={translation.cancel} onClick={() => setAddContributors(false)} underline disabled />
                                </div>
                            </div>
                        </BoxContainer>
                    </div>

                    <div className="pt-8 space-y-2.5 flex flex-col justify-center items-center">
                        <SadBox className={SIZE_MAP.xxl} />
                        <span className="text-light-grey">{translation.noContributor}</span>
                        <Button onClick={sendInvitation} label={translation.sendInvitation} disabled />
                    </div>
                </>
            )}
        </div>
    );
}
