import { Link } from "./Hateoas";

export interface Representative {
    id: string;
    fullNumber: string;
    siren?: string;
    owner: {
        firstName: string;
        lastName: string;
        email: string;
    };
    request: {
        status: Representative.Status;
        requestedAt: string;
        repliedAt?: string;
    };
    name: string;
    _links: {
        removeMemberManager: Link;
    };
}
export namespace Representative {
    export enum Status {
        SENT = "SENT",
        CANCELED = "CANCELED",
        ACCEPTED = "ACCEPTED",
        REFUSED = "REFUSED",
        REVOKED = "REVOKED"
    }
    export enum InvitationStatus {
        ACCEPTED = "ACCEPTED",
        REFUSED = "REFUSED",
        PENDING = "PENDING"
    }
    export enum InvitationType {
        REPRESENTATIVE = "REPRESENTATIVE",
        COTITULARITY = "COTITULARITY",
        MANAGER = "MANAGER"
    }
}
