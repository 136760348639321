export const SIZES = ["xxs", "xs", "sm", "md", "lg", "xl", "xxl"] as const;
export type Size = (typeof SIZES)[number];

export const SIZE_MAP: Record<Size, string> = {
    xxs: "size-2",
    xs: "size-3",
    sm: "size-4",
    md: "size-5",
    lg: "size-6",
    xl: "size-7",
    xxl: "size-16"
};

export const MAX_SIZE_MAP: Record<Size, string> = {
    xxs: "max-w-64",
    xs: "max-w-72",
    sm: "max-w-96",
    md: "max-w-lg",
    lg: "max-w-xl",
    xl: "max-w-2xl",
    xxl: "max-w-4xl"
};

export const PERCENTAGE_SIZE_MAP: Record<Size, string> = {
    xxs: "w-1/12",
    xs: "w-2/12",
    sm: "w-1/5",
    md: "w-2/4",
    lg: "w-9/12",
    xl: "w-4/5",
    xxl: "w-11/12"
};
