import { LayoutSide, LayoutSideElement, useI18n } from "@vaultinum/app-sdk";

import { Translation } from "../../../i18n";

export default function ContributorsSide() {
    const { translation } = useI18n<Translation>();

    const SIDE_ACTIONS: LayoutSideElement[] = [
        {
            label: translation.managedContributors,
            elementType: "button",
            action: () => window.open(process.env.REACT_APP_MANAGE_CONTRIBUTORS_URL)
        }
    ];

    return <LayoutSide title={translation.actions} sideElements={SIDE_ACTIONS} />;
}
