import { User } from "@vaultinum/app-sdk";
import mixpanel, { Dict, Mixpanel } from "mixpanel-browser";

export enum TrackingEvent {
    NAVIGATION = "Navigation"
}

class TrackingService {
    mixpanel: Mixpanel | undefined;

    constructor() {
        const mixpanelToken = process.env.REACT_APP_TRACKING_TOKEN;

        if (mixpanelToken) {
            mixpanel.init(mixpanelToken, {
                debug: process.env.REACT_APP_TRACKING_DEBUG === "true",
                loaded: instance => {
                    this.mixpanel = instance;
                    this.mixpanel.register({
                        application: process.env.REACT_APP_TRACKING_APPLICATION || "unknown",
                        environment: process.env.REACT_APP_TRACKING_ENVIRONMENT || "unknown"
                    });
                }
            });
        }
    }

    startUserTracking(userId: string | undefined, user: User) {
        this.mixpanel?.identify(userId);

        this.mixpanel?.people.set({
            $first_name: user.firstName,
            $last_name: user.lastName
        });
    }

    stopUserTracking() {
        this.mixpanel?.reset();
    }

    track(event: TrackingEvent, properties?: Dict) {
        this.mixpanel?.track(event, properties);
    }
}

export default new TrackingService();
