import classNames from "classnames";
import { ReactNode, useState } from "react";

export default function Tooltip({
    children,
    message,
    position = "bottom",
    className,
    tooltipClassName
}: {
    children: ReactNode;
    message?: ReactNode;
    position?: "top" | "left" | "bottom" | "right" | "top-left" | "top-right" | "bottom-left" | "bottom-right";
    className?: string;
    tooltipClassName?: string;
}): JSX.Element {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const handleMouseEnter = () => setIsOpen(true);
    const handleMouseLeave = () => setIsOpen(false);

    return (
        <div className={classNames("relative inline-block group", className)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            {message && isOpen && (
                <div
                    className={classNames(
                        "absolute z-10 px-3 text-xs rounded-sm shadow-sm",
                        {
                            "top-7": position === "bottom",
                            "left-5": position === "right",
                            "right-5": position === "left",
                            "bottom-5": position === "top",
                            "top-5 left-16": position === "bottom-right",
                            "top-5 right-5": position === "bottom-left",
                            "bottom-5 left-5": position === "top-right",
                            "bottom-5 right-5": position === "top-left"
                        },
                        tooltipClassName
                    )}
                >
                    {message}
                </div>
            )}
        </div>
    );
}
