import {
    ApisContext,
    BoxIcon,
    Credits,
    DepositStats,
    DepositStore,
    getDepositStatsByUserId,
    getDepositStoresByUserId,
    getMemberApi,
    getMemberCredit,
    getMemberDetails,
    getOwnCredits,
    GridCell,
    Info,
    InfoTooltipProps,
    InfoTracker,
    Loader,
    Member,
    MemberApi,
    MemberCredit,
    MoneyIcon,
    ShareIcon,
    standardFormatDate,
    useApi,
    useI18n,
    User,
    UserContext
} from "@vaultinum/app-sdk";
import classNames from "classnames";
import { useContext, useEffect, useState } from "react";

import { Translation } from "../../../i18n";
import { APP_NEWS, CONTACT_SUPPORT, CREDIT_PAGE, DEPOSIT_PAGE, HELP_PAGE, INFO_CENTER, MEMBERS_ADVANTAGES, WALLET_PAGE } from "../../../services";

function AdminCallout({ title }: { title: string }) {
    return <span className="absolute -top-0 left-0 bg-primary-light text-xxs px-2 py-0 text-primary font-semibold rounded-tl-md rounded-br-md">{title}</span>;
}

function Administrator({ administrator }: { administrator: Member.Administrator }): JSX.Element {
    return (
        <>
            {administrator.firstName} <span className="uppercase">{administrator.lastName}</span>{" "}
            <span>
                (<a href={`mailto:${administrator.email}`}>{administrator.email}</a>)
            </span>
        </>
    );
}

function Administrators({ member }: { member: Member }): JSX.Element {
    return (
        <ul className="list-disc pl-5">
            {member.administrators.map(administrator => (
                <li key={administrator.id}>
                    <Administrator administrator={administrator} />
                </li>
            ))}
        </ul>
    );
}

export default function HomePage() {
    const apis = useContext(ApisContext);
    const { translation, translate } = useI18n<Translation>();
    const { user } = useContext(UserContext);
    const [userCredits, setUserCredits] = useState<Credits>();
    const [memberInfos, setMemberInfos] = useState<Member | undefined>();
    const [depositStores, setDepositStores] = useState<DepositStore[]>();
    const [depositStoreInfo, setDepositStoreInfo] = useState<DepositStats>();
    const [memberCredits, setMemberCredits] = useState<MemberCredit>();
    const { fetchApi } = useApi();

    const userName = `${user?.firstName} ${user?.lastName}`;
    const isAdmin = (): boolean => user?.roles.includes(User.Role.ADMIN) ?? false;

    const loadInfos = async () => {
        const memberApi = await fetchApi<MemberApi>(getMemberApi);

        if (memberApi) {
            const memberFn = getMemberDetails(memberApi);
            if (memberFn) {
                const infos = await fetchApi(memberFn);
                setMemberInfos(infos);
            }
        }
        const getOwnCreditsFn = getOwnCredits(apis.deposit);

        if (getOwnCreditsFn) {
            setUserCredits(await getOwnCreditsFn());
        }

        if (user?.sub) {
            const depositStoresResponse = await fetchApi(() => getDepositStoresByUserId(user?.sub));
            setDepositStores(depositStoresResponse);
            const depositStats = await fetchApi(() => getDepositStatsByUserId(user?.sub));
            setDepositStoreInfo(depositStats);
        }

        if (memberApi && isAdmin()) {
            const memberFn = getMemberDetails(memberApi);
            if (memberFn) {
                await fetchApi(memberFn, async (response: Member) => {
                    const creditResponse = await fetchApi(() => getMemberCredit(response.id));
                    setMemberCredits(creditResponse);
                });
            }
        }
    };

    useEffect(() => {
        void loadInfos();
    }, [user]);

    if (!memberInfos || !userCredits || !depositStores || !depositStoreInfo) {
        return <Loader />;
    }

    const myAccountSectionData = [
        {
            label: translation.label(translation.memberName),
            content: memberInfos.name
        },
        {
            label: translation.label(translation.member.memberNumber),
            content: memberInfos.fullNumber
        },
        {
            label: translation.label(translation.subscriptionExpirationDate),
            content: translation.subscriptionExpirationDateContent(standardFormatDate(memberInfos.expiresAt))
        },
        {
            label: translation.label(memberInfos.administrators.length > 1 ? translation.administrators : translation.administrator),
            content:
                memberInfos.administrators.length > 1 ? (
                    <Administrators member={memberInfos} />
                ) : (
                    <Administrator administrator={memberInfos.administrators[0]} />
                )
        }
    ];

    const creditRelatedInfo: {
        value: number | string;
        label?: string;
        tooltip?: InfoTooltipProps;
    }[] = [
        {
            label: translation.label(translation.soldAvailableUntil),
            value: translation.balanceValidUntilInclusive(userCredits?.expiresAt || "-"),
            tooltip: { size: "small", children: translation.creditValidityRules }
        },
        {
            label: translation.label(translation.consumedCredits),
            value: userCredits.consumed
        }
    ];

    if (userCredits.allocated !== undefined) {
        creditRelatedInfo.push({
            label: translation.label(translation.allocadedCreditByYourAdmin),
            value: userCredits.allocated,
            tooltip: { size: "small", children: translation.allocatedCreditInfo }
        });
    }

    return (
        <>
            <div className="flex flex-col">
                <div className="flex justify-between">
                    <h1 className="m-0 text-2xl font-light">{translation.dahsboardOwner(memberInfos.name)}</h1>
                    <p className="text-label">
                        {translation.informationRenewSubscription(memberInfos?.subscriptionRenewalDate ?? translation.informationNotAvailableYet)}
                    </p>
                </div>
                <p className="text-label">{translation.welcomeOwner(userName)}</p>
            </div>
            <div className="grid grid-cols-[1fr] lg:grid-cols-[repeat(6,_1fr)_22rem] gap-5">
                {/* GAUCHE */}
                <div className="grid grid-cols-12 col-span-6 gap-5 auto-rows-min">
                    {/* DEPOTS DU MEMBRES */}
                    {isAdmin() && (
                        <GridCell
                            customClass={classNames("col-span-12 md:col-span-6 xl:col-span-4", {
                                "2xl:col-span-3": isAdmin()
                            })}
                            title={translation.allDeposits}
                            primaryButtonProps={{
                                label: translation.checkPortfolio,
                                to: WALLET_PAGE
                            }}
                            icon={<BoxIcon className="w-5 h-5 fill-primary" />}
                        >
                            <AdminCallout title={translation.exclusiveAdministrator} />
                            <div className="space-y-2">
                                <div className="flex gap-5">
                                    <InfoTracker
                                        figure={{
                                            value: depositStoreInfo.depositCount || "-",
                                            label: translation.deposits
                                        }}
                                        relatedInfo={[
                                            {
                                                label: translation.label(translation.depositsDistributedAmongDepositStores),
                                                value: translate(translation.depositStoresPlural, depositStoreInfo.depositStoreCount)
                                            },
                                            {
                                                label: translation.label(translation.lastDepositDate),
                                                value: "-"
                                            },
                                            { label: translation.label(translation.by), value: "-" }
                                        ]}
                                    />
                                </div>
                            </div>
                        </GridCell>
                    )}

                    {/* VOS DEPOTS */}
                    <GridCell
                        title={translation.yourDeposits}
                        customClass={classNames("col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-3", {
                            "xl:col-span-6": !isAdmin()
                        })}
                        primaryButtonProps={{
                            label: translation.depositAction,
                            to: DEPOSIT_PAGE
                        }}
                        icon={<BoxIcon className="w-5 h-5 fill-primary" />}
                        tooltipMessage={translation.allDepositsToolTip}
                    >
                        <InfoTracker
                            figure={{ value: "-", label: translation.deposits }}
                            relatedInfo={[
                                {
                                    label: translation.label(translation.depositsDistributedAmongDepositStores),
                                    value: "-"
                                },
                                {
                                    label: translation.label(translation.lastDepositDate),
                                    value: "-"
                                }
                            ]}
                        />
                    </GridCell>

                    {/* LES CREDITS DU MEMBRE */}
                    {isAdmin() && (
                        <GridCell
                            customClass="col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-3"
                            title={translation.memberCreditBalance}
                            primaryButtonProps={{
                                label: translation.administrateCredits,
                                to: CREDIT_PAGE
                            }}
                            icon={<MoneyIcon className="w-5 h-5 fill-primary" />}
                        >
                            <AdminCallout title={translation.exclusiveAdministrator} />
                            <InfoTracker
                                figure={{
                                    value: memberCredits?.available || "-",
                                    label: translation.available
                                }}
                                relatedInfo={[
                                    {
                                        label: translation.label(translation.soldAvailableUntil),
                                        value: translation.balanceValidUntilInclusive(userCredits?.expiresAt || "-"),
                                        tooltip: {
                                            size: "small",
                                            children: translation.creditValidityRules
                                        }
                                    },
                                    {
                                        label: translation.label(translation.bought),
                                        value: memberCredits?.purchased || "-"
                                    },
                                    {
                                        label: translation.label(translation.consummedByUsers),
                                        value: memberCredits?.consumed || "-",
                                        tooltip: {
                                            size: "small",
                                            children: translation.usersDefinition
                                        }
                                    }
                                ]}
                            />
                        </GridCell>
                    )}

                    {/* LES CREDITS PERSONNELS */}
                    <GridCell
                        customClass={classNames("col-span-12 md:col-span-6 2xl:col-span-3", {
                            "md:col-span-4 xl:col-span-6 ": !isAdmin(),
                            "md:col-span-6 xl:col-span-4": isAdmin()
                        })}
                        title={translation.personnalCreditBalance}
                        tooltipMessage={translation.soldCreditsTooltip}
                        icon={<MoneyIcon className="w-5 h-5 fill-primary" />}
                    >
                        <>
                            {(isAdmin() || !!userCredits.allocated) && (
                                <InfoTracker
                                    figure={{
                                        value: userCredits?.available || 0,
                                        label: translation.available
                                    }}
                                    relatedInfo={creditRelatedInfo}
                                />
                            )}
                            {!isAdmin() && userCredits.allocated === 0 && (
                                <>
                                    <InfoTracker
                                        figure={{
                                            value: userCredits?.available || 0,
                                            label: translation.available
                                        }}
                                    />
                                    <Info className="mt-3" children={translation.adminHasNotAllocatedCredit} />
                                </>
                            )}
                        </>
                    </GridCell>

                    {/* LES FILIERES */}

                    {/* LES ENTIERCEMENTS */}
                    {isAdmin() && (
                        <GridCell
                            customClass="col-span-12 xl:col-span-4 2xl:col-span-3"
                            title={translation.entiercementContract}
                            subtitle={translation.informationNotAvailableYet}
                            tooltipMessage={translation.escrowContractTooltip}
                            icon={<ShareIcon className="w-5 h-5 fill-primary" />}
                            children={<AdminCallout title={translation.exclusiveAdministrator} />}
                        />
                    )}
                </div>

                {/* DROITE */}
                <div className="grid grid-cols-1 col-span-6 lg:col-span-1 gap-5 auto-rows-min border-l-4 border-disabled pl-5">
                    <GridCell
                        customClass="border border-primary text-label"
                        title={translation.youAreConnctedTo}
                        children={
                            <ul>
                                {myAccountSectionData.map(el => (
                                    <li key={el.label}>
                                        <span className="font-semibold">{el.label}</span> {el.content}
                                    </li>
                                ))}
                            </ul>
                        }
                    />
                    <GridCell
                        customClass="row-span-2 text-label relative"
                        title={translation.appAnnouncement}
                        primaryButtonProps={{
                            label: translation.knownYourAvantage,
                            to: MEMBERS_ADVANTAGES,
                            shouldOpenInNewTab: true
                        }}
                        children={<p>{translation.avantageAppMember}</p>}
                    />
                    <GridCell
                        customClass="row-span-2 text-label"
                        title={translation.news}
                        primaryButtonProps={{
                            label: translation.discoverOurNews,
                            to: APP_NEWS,
                            shouldOpenInNewTab: true
                        }}
                    />
                    <GridCell
                        customClass="row-span-1 text-label"
                        title={translation.needHelp}
                        primaryButtonProps={{
                            label: translation.contactHelpDesk,
                            to: CONTACT_SUPPORT,
                            shouldOpenInNewTab: true
                        }}
                        children={translation.widgetContact}
                    />
                    <GridCell
                        customClass="row-span-2 text-label"
                        title={translation.helpPage}
                        primaryButtonProps={{
                            label: translation.goTohelpPage,
                            to: HELP_PAGE,
                            shouldOpenInNewTab: true
                        }}
                        secondaryButtonProps={{
                            label: translation.goToInfoCenter,
                            to: INFO_CENTER,
                            shouldOpenInNewTab: true
                        }}
                        children={<p>{translation.widgetHelp}</p>}
                    />
                </div>
            </div>
        </>
    );
}
