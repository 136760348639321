import { Info, MemberHolder, useI18n } from "@vaultinum/app-sdk";
import { ListDeposit } from ".";
import { Translation } from "../../i18n";

export default function RepresentativeList({ representatives }: { representatives?: MemberHolder[] }): JSX.Element {
    const { translation } = useI18n<Translation>();

    return (
        <ListDeposit
            field="holders"
            title={translation.depositRepresentativeTitle}
            information={<Info>{translation.depositRepresentativeInfo}</Info>}
            inputTitle={translation.depositRpresentativePlaceholder}
            users={representatives}
            isRepresentative
        />
    );
}
