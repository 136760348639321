import { Button, FileSizeUnit, TrashIcon, bytesConverter, getTotalSize, useI18n, useToast } from "@vaultinum/app-sdk";
import classNames from "classnames";
import { ChangeEvent, useCallback, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Translation } from "../../i18n";

export const MAX_FILE_SIZE = 9.8 * 8 ** 10; // 4 GB
export const MAX_FILES_LIMIT = 1000; // 1000 files
export const MAX_TOTAL_SIZE_LIMIT = 10 * 8 ** 10; // 10 GB
export const INPUT_FILES_NAME = "listOfFiles";

export default function DropZone() {
    const dropZoneRef = useRef<HTMLDivElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isDragOver, setIsDragOver] = useState(false);
    const toast = useToast();
    const { locale, translation } = useI18n<Translation>();
    const format = bytesConverter(locale, translation.file);
    const { control, setValue, watch } = useFormContext();
    const listOfFiles: File[] = watch(INPUT_FILES_NAME) as File[];

    const checkAndAddFiles = useCallback(
        (fileList: File[]) => {
            const totalFilesLimitExceeded =
                Array.from(listOfFiles.values()).reduce((acc, file) => acc + file.size, 0) + fileList.reduce((acc, file) => acc + file.size, 0);
            if (fileList.length > MAX_FILES_LIMIT) {
                toast.error(translation.sizeLimitTotaFilelExceeded(format(MAX_FILES_LIMIT)));
                return;
            } else if (fileList.some(file => file.size > MAX_FILE_SIZE)) {
                toast.error(translation.sizeLimitExceeded(format(MAX_FILE_SIZE)));
                return;
            } else if (totalFilesLimitExceeded > MAX_TOTAL_SIZE_LIMIT) {
                toast.error(translation.sizeLimitTotalExceeded(format(MAX_TOTAL_SIZE_LIMIT)));
                return;
            }

            setValue(INPUT_FILES_NAME, [...listOfFiles, ...fileList]);
        },
        [listOfFiles, toast, translation, format]
    );

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files;
        if (selectedFiles) {
            const fileList = Array.from(selectedFiles);
            checkAndAddFiles(fileList);
        }
    };
    const handleDrop = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
            setIsDragOver(false);
            event.preventDefault();

            const items = Array.from(event.dataTransfer.items);

            const filesFiltered: File[] = [];

            for (const item of items) {
                const entry = item.webkitGetAsEntry();
                if (entry && entry.isFile) {
                    const file = item.getAsFile();
                    if (file) {
                        filesFiltered.push(file);
                    }
                } else {
                    toast.error(translation.folderNotAuthorized);
                }
            }
            checkAndAddFiles(filesFiltered);
        },
        [checkAndAddFiles]
    );

    const handleDragEvent = useCallback((event: React.DragEvent<HTMLDivElement>, isOver: boolean) => {
        event.preventDefault();
        setIsDragOver(isOver);
    }, []);

    const handleAddFilesClick = useCallback(() => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }, []);

    const handleRemoveFile = (fileIndex: number) => {
        const updatedListOfFiles = listOfFiles.filter((file, index) => index !== fileIndex);
        setValue(INPUT_FILES_NAME, updatedListOfFiles);
    };

    return (
        <>
            <div
                ref={dropZoneRef}
                className={classNames("flex flex-col items-center bg-white border-2 border-dashed rounded-none p-10", {
                    "bg-gray-100 border-primary": isDragOver,
                    "border-color-disabled": !isDragOver
                })}
                onDrop={handleDrop}
                onDragOver={event => handleDragEvent(event, true)}
                onDragLeave={event => handleDragEvent(event, false)}
            >
                <div className="flex flex-col pb-5 text-center gap-y-5 text-label">
                    <label>{translation.labelDropHere}</label>
                    <label>{translation.where}</label>
                </div>
                <Button onClick={handleAddFilesClick} label={translation.selectFiles} variant="link" />
                <Controller
                    name={INPUT_FILES_NAME}
                    render={() => <input ref={fileInputRef} onChange={handleFileChange} type="file" multiple className="hidden" />}
                    control={control}
                />
            </div>
            <p className="text-sm text-center text-color-disabled pt-2.5">{translation.helperTextSizeLimit}</p>
            {listOfFiles.length > 0 && (
                <>
                    <div className="flex justify-between pt-10 text-sm text-color-disabled">
                        <span>{`${format(getTotalSize(listOfFiles), { decimal: 0, forceUnit: FileSizeUnit.KILO_BYTE })} (${format(
                            getTotalSize(listOfFiles)
                        )}) / ${format(
                            MAX_TOTAL_SIZE_LIMIT,

                            { decimal: 0, forceUnit: FileSizeUnit.KILO_BYTE }
                        )} (${format(MAX_TOTAL_SIZE_LIMIT)})`}</span>
                        <span>
                            {listOfFiles.length} / {MAX_FILES_LIMIT} {translation.files}
                        </span>
                    </div>
                    <ul className="pt-4">
                        {Array.from(listOfFiles.values()).map((file, index) => (
                            <li
                                key={file.name}
                                className="flex items-center border-b border-b-primary-xlight py-1.5 px-2.5 hover:bg-primary-xlight text-label text-sm"
                            >
                                <span className="w-9/12 text-start">{file.name}</span>
                                <span className="w-2/12 text-end">{format(file.size)}</span>
                                <div className="flex items-center justify-end w-1/12">
                                    <Button
                                        variant="iconButton"
                                        icon={<TrashIcon className="w-4 h-4 fill-primary" />}
                                        onClick={() => handleRemoveFile(index)}
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </>
    );
}
