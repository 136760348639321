import { Managers } from "../../hooks";
import { Translation, useI18n } from "../../i18n";
import { DepositStore } from "../../model";
import { ListSelect } from "../ListSelect";

export default function Contributors({
    managers,
    depositStore,
    readonly
}: {
    managers: Managers;
    depositStore: DepositStore;
    readonly?: boolean;
}): JSX.Element {
    const { translation } = useI18n<Translation>();

    return <ListSelect titleList={translation.contributorsOfDepositStore} managers={managers} readonly={readonly} depositStore={depositStore} />;
}
