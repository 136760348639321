import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import { Button, Info } from "../../../design-system";
import { canSendMandateRequest, canSendRepresentativeRequest, canSendRequest } from "../../helpers/roles-helper";
import yup from "../../helpers/yup-extended";
import { Translation, useI18n } from "../../i18n";
import { Member } from "../../model";
import { FormFieldsInput } from "../FormInputs";

enum RequestType {
    REPRESENTATIVE = "representative",
    MANDATES = "mandates"
}

export default function RequestForm({ member, cancelSubmit }: { member: Member; cancelSubmit: () => void }): JSX.Element {
    const { translation } = useI18n<Translation>();

    const RepresentativeSchema: ObjectSchema<FieldValues> = yup.object({
        userEmail: yup.string().isEmailFormatValid(translation.emailFormatError).required(translation.requiredField),
        role: yup.string().oneOf([RequestType.REPRESENTATIVE, RequestType.MANDATES]).required(translation.requiredField)
    });

    const {
        control,
        formState: { errors, isValid },
        watch
    } = useForm<FieldValues>({
        mode: "onChange",
        resolver: yupResolver(RepresentativeSchema)
    });

    return (
        <form onSubmit={() => {}} className="space-y-5">
            <div>
                <div className="flex flex-col">
                    <FormFieldsInput
                        control={control}
                        errors={errors}
                        fields={[
                            {
                                name: "role",
                                label: translation.invitedRole,
                                type: "select",
                                options: [
                                    {
                                        value: RequestType.REPRESENTATIVE,
                                        label: translation.representative,
                                        disabled: !canSendRepresentativeRequest(member)
                                    },
                                    {
                                        value: RequestType.MANDATES,
                                        label: translation.asMandate,
                                        disabled: !canSendMandateRequest(member)
                                    }
                                ],
                                disabled: !canSendRequest(member)
                            }
                        ]}
                    />
                </div>
            </div>
            <FormFieldsInput
                fields={[
                    {
                        name: "userEmail",
                        label: translation.memberEmail,
                        type: "email",
                        disabled: !watch("role")
                    }
                ]}
                control={control}
                errors={errors}
            />
            <Info type="primary" children={translation.representativeAddInfo} />
            <div className="flex flex-col justify-center items-center gap-7">
                <Button label={translation.sendInvitation} type="submit" disabled={!isValid} />
                <Button variant="link" label={translation.cancel} onClick={cancelSubmit} underline />
            </div>
        </form>
    );
}
