import { FieldValues } from "react-hook-form";
import * as yup from "yup";
import { ObjectSchema } from "yup";

const requiredField = "Ce champ est obligatoire";

const LoginSchema: ObjectSchema<FieldValues> = yup.object({
    login: yup.string().required(requiredField),
    password: yup.string().required(requiredField)
});

export default LoginSchema;
