import { BoxContainer, Button, CustomLink, Input, TickIcon, useI18n } from "@vaultinum/app-sdk";
import { ReactNode, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { OrderContext } from "../../../contexts";
import { Translation } from "../../../i18n";
import { CREDIT_BUY_PAGE, CREDIT_BUY_SUMMARY_PAGE } from "../../../services";
import CreditsPageTemplate from "./CreditsPageTemplate";

export default function CreditsBuyPaymentPage(): ReactNode {
    const { translation } = useI18n<Translation>();
    const { createOrder, paymentMethods, setPaymentMethod, shoppingCart } = useContext(OrderContext);

    const navigate = useNavigate();

    return (
        <CreditsPageTemplate currentStep={2} description={translation.choosePaymentMethodAndValidate}>
            <div className="flex flex-col items-center gap-2">
                <div className="w-full md:w-2/5">
                    <BoxContainer icon={<TickIcon className="fill-green-600" />} title={translation.paymentMethod}>
                        <div className="space-y-2">
                            {paymentMethods.map(({ paymentMethod, icon, label, description }) => (
                                <>
                                    <Input
                                        fieldName={`paymentMethod${paymentMethod}`}
                                        key={paymentMethod}
                                        type="radio"
                                        label={
                                            <span className="flex items-center gap-2">
                                                <span className="flex justify-center w-8">{icon}</span>
                                                {label}
                                            </span>
                                        }
                                        checked={shoppingCart.paymentMethod === paymentMethod}
                                        onChange={() => setPaymentMethod(paymentMethod)}
                                        disabled={!createOrder}
                                    />
                                    {shoppingCart.paymentMethod === paymentMethod && !!description && <div className="text-sm">{description}</div>}
                                </>
                            ))}
                        </div>
                    </BoxContainer>
                </div>
                <div className="flex flex-col items-center space-y-3">
                    <Button label={translation.submit} disabled={!shoppingCart.paymentMethod} onClick={() => navigate(CREDIT_BUY_SUMMARY_PAGE)} />
                    <CustomLink label={translation.goBack} path={CREDIT_BUY_PAGE} className="text-sm" />
                </div>
            </div>
        </CreditsPageTemplate>
    );
}
