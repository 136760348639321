import { useI18n } from "@vaultinum/app-sdk";
import { Translation } from "../../i18n";
import { useContext } from "react";
import { OrderContext } from "../../contexts";

export function OrderTotal(): JSX.Element {
    const { shoppingCartInformation } = useContext(OrderContext);

    const { translation } = useI18n<Translation>();

    return (
        <ul className="text-label mt-5 font-light">
            <OrderTotal.Item label={translation.totalPriceVatExcluded} price={shoppingCartInformation.totalPriceTaxExcluded} />
            {!!shoppingCartInformation.globalRebatePercent && (
                <OrderTotal.Item
                    label={translation.globalRebatePercent(shoppingCartInformation.globalRebatePercent)}
                    price={shoppingCartInformation.globalRebatePrice}
                />
            )}
            <OrderTotal.Item
                label={translation.vatPercents(shoppingCartInformation.vatPercents.sort((a, b) => a - b))}
                price={shoppingCartInformation.vatPrice}
            />
            <OrderTotal.Item label={translation.totalPriceVatIncluded} price={shoppingCartInformation.totalPriceTaxIncluded} />
        </ul>
    );
}

export namespace OrderTotal {
    export function Item({ label, price }: { label: string; price: number }): JSX.Element {
        const { translation } = useI18n<Translation>();
        return (
            <li className="flex justify-end">
                <span>{label}</span>
                <span className="w-24 text-right">{translation.formatPrice(price)}</span>
            </li>
        );
    }
}
