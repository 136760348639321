import { getConnectedUser, getMemberApi, getMemberDetails, Member, MemberApi, useApi, UserInfo } from "@vaultinum/app-sdk";
import { createContext, ReactNode, useEffect, useMemo, useState } from "react";

export interface MemberContextInterface {
    member?: Member;
    connectedUser?: UserInfo;
}

export const MemberContext = createContext<MemberContextInterface>({} as MemberContextInterface);

export function MemberContextProvider({ children }: { children: ReactNode }) {
    const [member, setMember] = useState<Member>();
    const [connectedUser, setConnectedUser] = useState<UserInfo>();

    const { fetchApi } = useApi();

    const exposedValue = useMemo(() => ({ member, connectedUser }), [member, connectedUser]);

    useEffect(() => {
        void (async () => {
            const memberApi = await fetchApi<MemberApi>(getMemberApi);

            if (memberApi) {
                const memberFn = getMemberDetails(memberApi);

                if (memberFn) {
                    await fetchApi(memberFn, setMember);
                }

                const connectedUserFn = getConnectedUser(memberApi);

                if (connectedUserFn) {
                    await fetchApi(connectedUserFn, setConnectedUser);
                }
            }
        })();
    }, []);

    return <MemberContext.Provider value={exposedValue}>{children}</MemberContext.Provider>;
}
