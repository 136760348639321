import { Button, Info, Loader } from "../../../design-system";
import { useContributorsList } from "../../hooks";
import { Translation, useI18n } from "../../i18n";
import { DepositStore, Member } from "../../model";
import Contributors from "./Contributors";

export default function ContributorsList({ depositStore, member, disabled }: { depositStore: DepositStore; member: Member; disabled?: boolean }): JSX.Element {
    const { translation } = useI18n<Translation>();
    const { submit, readonly, warningMessage, updating, reset, infoMessage, noModification, managers } = useContributorsList(depositStore, member);

    if (!managers.contributors || !managers.representatives) {
        return <Loader />;
    }

    const handleDisabled = () => {
        if (disabled) {
            return true;
        } else {
            return readonly;
        }
    };

    return (
        <>
            {infoMessage && (
                <div className="mb-5">
                    <Info type="primary">{infoMessage}</Info>
                </div>
            )}
            <Contributors managers={managers} readonly={handleDisabled()} depositStore={depositStore}/>
            {!readonly && (
                <div className="mt-8 flex flex-col justify-center">
                    {warningMessage && (
                        <div className="mb-5">
                            <Info type="warning">{warningMessage}</Info>
                        </div>
                    )}
                    {!disabled && (
                        <div className="flex flex-col items-center space-y-8">
                            <Button label={translation.saveContributors} disabled={noModification} onClick={() => submit()} loading={updating} />
                            <Button label={translation.cancel} variant="link" underline disabled={noModification} onClick={reset} />
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
