import * as yup from "yup";

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

declare module "yup" {
    interface StringSchema {
        isEmailFormatValid(message: string): this;
    }
}

yup.addMethod<yup.StringSchema>(yup.string, "isEmailFormatValid", function (message) {
    return this.test("isEmailFormatValid", message, function (value) {
        const { path, createError } = this;
        return value && EMAIL_REGEX.test(value) ? true : createError({ path, message });
    });
});

export default yup;
