import classNames from "classnames";
import { ComponentType, Fragment, ReactNode } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Chip, Contract, CoOwnership, ExternalLinkIcon, Lawyer, SkeletonTable, Tooltip } from "../../../design-system";
import { standardFormatDate } from "../../helpers";
import { Translation, useI18n } from "../../i18n";
import { DepositStore } from "../../model";
import { DepositStatus, SEAL_STATUS_COLORS } from "../DepositStatus";

export enum OptionsFilter {
    ICON = "ICON",
    IDDN = "IDDN",
    CONTRACT = "CONTRACT",
    HOLDERS = "HOLDERS",
    OWNER = "OWNER"
}

const TooltipCustom = ({ depositInfos }: { depositInfos: DepositStore }) => {
    const { translation } = useI18n<Translation>();
    return (
        <>
            <div className="relative border-b border-gray-600 py-2 text-center gap-2">
                <span className={`absolute w-3 h-3 me-2 bg-${SEAL_STATUS_COLORS[depositInfos.sealStatus]}-light rounded-full left-1 top-3`} />
                <h3 className="font-semibold text-sm text-gray-900">{translation.depositStoreDescription}</h3>
            </div>
            <ul className="py-2 text-gray-500 rounded-b-lg shadow-sm space-y-2">
                <li>
                    <strong>{translation.depositNameLabel}</strong> {depositInfos.name}
                </li>
                <li>
                    <strong>{translation.subtitleLabel}</strong> {depositInfos.subtitle ?? "-"}
                </li>
                <li>
                    <strong>{translation.depositStore.version}</strong> {depositInfos.workVersion ?? "-"}
                </li>
                <li>
                    <strong>{translation.depositStore.dateVersion}</strong> {depositInfos.workVersionDate ?? "-"}
                </li>
            </ul>
        </>
    );
};

const getContractTooltipText = (translation: Translation, depositStore?: DepositStore) => {
    if (depositStore?.agreementTypes?.length) {
        return depositStore.agreementTypes.map(agreementType => translation.agreementStatus[agreementType]).join(", ");
    }

    return translation.noContractForThisDepositStore;
};

const DepositStoreAdditionalIcon = ({
    Icon,
    active,
    tooltip
}: {
    Icon: ComponentType<{ className?: string }>;
    active: boolean;
    tooltip: string;
}): ReactNode => {
    return (
        <Chip
            label={<Icon className={classNames({ "opacity-50": !active })} />}
            tooltipContent={tooltip}
            type={active ? "primary" : "neutral"}
            invertColors={active}
            size="sm"
        />
    );
};

export default function Wallet({
    depositStores,
    depositStoreLink,
    depositLink,
    showToolTip = true,
    loading,
    openDepositStoreInNewTab
}: {
    depositStores?: DepositStore[];
    depositStoreLink: (depositStore: DepositStore) => string;
    depositLink: (depositStore: DepositStore, depositIddn: string) => string;
    showToolTip?: boolean;
    loading?: boolean;
    openDepositStoreInNewTab?: boolean;
}) {
    const { translation, translate } = useI18n<Translation>();
    const navigate = useNavigate();

    const handleRedirect = (url: string) => {
        if (!openDepositStoreInNewTab) {
            navigate(url);
        } else {
            window.open(url, "_blank");
        }
    };

    let content;

    if (!depositStores || loading) {
        content = (
            <>
                <div className="animate-pulse flex mb-2.5">
                    <div className="w-20 h-3 bg-gray-200" />
                </div>
                <SkeletonTable rowNumber={10} />
            </>
        );
    } else if (depositStores.length === 0) {
        content = <p className="text-center text-deep-blue">{translation.wallet.noDepositInPortfolio}</p>;
    } else if (depositStores.length > 0) {
        content = (
            <div className="relative shadow-md pt-2">
                <table className="w-full overflow-x-auto text-sm text-left rtl:text-right text-brown-dark">
                    <thead className="text-xs uppercase bg-primary-xlight">
                        <tr>
                            <th scope="col" className="py-3 pr-1 max-w-72 pl-12">
                                {translation.workName}
                            </th>
                            <th scope="col" className="px-1 py-3 w-48">
                                {translation.statusDeposit}
                            </th>
                            <th scope="col" className="px-1 py-3 w-24 truncate">
                                {translation.walletDepositDate}
                            </th>
                            <th scope="col" className="px-1 py-3 w-72">
                                {translation.iddnNumber}
                            </th>
                            <th scope="col" className="px-1 py-3 w-20">
                                {translation.informations}
                            </th>
                            <th scope="col" className="px-1 py-3 w-6" />
                        </tr>
                    </thead>
                    <tbody>
                        {depositStores?.map((depositStore, rowIndex) => {
                            const hasContract = !!depositStore.agreementTypes && depositStore.agreementTypes?.length > 0;
                            const isShared = depositStore.holdersCount > 1;
                            const isManagedByRepresentative = depositStore.managedBy?.isSharedToRepresentatives;
                            return (
                                <Fragment key={`row-${rowIndex}`}>
                                    <tr
                                        className={classNames("border-b cursor-pointer hover:bg-slate-300", {
                                            "bg-slate-100": rowIndex % 2 !== 0,
                                            "bg-white": rowIndex % 2 === 0
                                        })}
                                        onClick={() => handleRedirect(depositStoreLink(depositStore))}
                                        key={rowIndex}
                                    >
                                        <td className="px-1 py-2 font-medium max-w-72">
                                            <div className="flex items-center">
                                                <Tooltip
                                                    message={translate(translation.deposit.availableCount, depositStore.depositsCount)}
                                                    tooltipClassName="bg-slate-100 truncate p-3 border"
                                                    className="flex items-center justify-center pr-2"
                                                >
                                                    <span className="inline-flex items-center justify-center w-4 h-4 text-xs font-semibold text-primary bg-slate-200 rounded-full">
                                                        {depositStore.depositsCount}
                                                    </span>
                                                </Tooltip>
                                                <Tooltip
                                                    message={<TooltipCustom depositInfos={depositStore} />}
                                                    tooltipClassName={classNames(
                                                        `min-w-96 border-4 border-${SEAL_STATUS_COLORS[depositStore.sealStatus]}-light bg-white`
                                                    )}
                                                    position="bottom-right"
                                                    className="flex justify-center items-center pr-2"
                                                >
                                                    <div className="cursor-help flex items-center justify-center font-bold text-white rounded-full bg-primary font-serif w-3.5 h-3.5 text-xs">
                                                        i
                                                    </div>
                                                </Tooltip>
                                                <p className="truncate w-5/6">{depositStore.name}</p>
                                            </div>
                                        </td>
                                        <td className="px-1 py-2 w-48">
                                            <DepositStatus
                                                label={translation.deposit.depositStoreStatus[depositStore.sealStatus]}
                                                status={depositStore.sealStatus}
                                                size="xs"
                                                showToolTip={showToolTip}
                                            />
                                        </td>
                                        <td className="px-1 py-2 w-24 text-xs">{standardFormatDate(depositStore.sealedAt)}</td>
                                        <td className="px-1 py-2 w-72 text-xs">{depositStore.iddn}</td>
                                        <td className="px-1 py-2 w-20">
                                            <div className="flex gap-1">
                                                <DepositStoreAdditionalIcon
                                                    Icon={CoOwnership}
                                                    active={isShared}
                                                    tooltip={isShared ? translation.sharedDepositStore : translation.nonSharedDepositStore}
                                                />
                                                <DepositStoreAdditionalIcon
                                                    Icon={Contract}
                                                    active={hasContract}
                                                    tooltip={getContractTooltipText(translation, depositStore)}
                                                />
                                                <DepositStoreAdditionalIcon
                                                    Icon={Lawyer}
                                                    active={isManagedByRepresentative}
                                                    tooltip={
                                                        isManagedByRepresentative
                                                            ? translation.depositStoreManagedByRepresentative
                                                            : translation.depositStoreManagedByHolder
                                                    }
                                                />
                                            </div>
                                        </td>
                                        <td className="px-1 py-2 w-6">
                                            <Link to={depositStoreLink(depositStore)} onClick={e => e.stopPropagation()} target="_blank">
                                                <Tooltip
                                                    message={translation.depositAccess}
                                                    position="left"
                                                    tooltipClassName="bg-white p-3 border shadow-lg w-52"
                                                >
                                                    <ExternalLinkIcon className="fill-primary w-3 h-3" />
                                                </Tooltip>
                                            </Link>
                                        </td>
                                    </tr>
                                    {depositStore.deposits?.map((deposit, depositIndex) => (
                                        <tr
                                            className={classNames("bg-slate-200 hover:bg-slate-300", {
                                                "invisible overflow-hidden max-h-0 hidden transition-all duration-300 ease-in-out": false,
                                                "visible transition-all duration-300 ease-in-out": true
                                            })}
                                            key={`${deposit.id}-${depositIndex}`}
                                            onClick={() => handleRedirect(depositLink(depositStore, deposit.id))}
                                        >
                                            <td scope="row" className="px-1 py-2 max-w-72 font-medium text-gray-900 whitespace-nowrap">
                                                <p className="truncate pl-12">{deposit.name}</p>
                                            </td>
                                            <td className="px-1 py-2 w-48">
                                                <DepositStatus
                                                    label={translation.deposit.sealStatus[deposit.depositType][deposit.sealStatus]}
                                                    status={deposit.sealStatus}
                                                    size="xs"
                                                    showToolTip={showToolTip}
                                                />
                                            </td>
                                            <td className="px-1 py-2 w-24 text-xs">{standardFormatDate(depositStore.createdAt)}</td>
                                            <td className="px-1 py-2 w-72 text-xs">{deposit.iddn}</td>
                                            <td className="px-1 py-2 w-20" />
                                            <td className="px-1 py-2 w-6">
                                                <Link to={depositLink(depositStore, deposit.id)} onClick={e => e.stopPropagation()} target="_blank">
                                                    <Tooltip
                                                        message={translation.depositAccess}
                                                        position="left"
                                                        tooltipClassName="bg-white p-3 border shadow-lg w-52"
                                                    >
                                                        <ExternalLinkIcon className="fill-primary w-3 h-3" />
                                                    </Tooltip>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    } else {
        content = <p className="text-sm font-light text-center pt-9 text-deep-blue">{translation.noResult}</p>;
    }

    return content;
}
