import { CopyableElement, Deposit, MemberNatureIcon, Tooltip, useI18n } from "@vaultinum/app-sdk";
import classNames from "classnames";
import { useRef } from "react";
import { Translation } from "../../i18n";

export default function HolderCard({ holder, withBorderTop }: { holder: Deposit.Holder; withBorderTop: boolean }): JSX.Element {
    const { translation } = useI18n<Translation>();
    const iconRef = useRef<HTMLSpanElement>(null);

    return (
        <div
            className={classNames("flex flex-col space-y-2 py-2", {
                "border-t": withBorderTop
            })}
        >
            <p className="flex items-center">
                <span className="relative inline-block mr-1" ref={iconRef}>
                    <Tooltip message={translation.member.nature[holder.nature]} tooltipClassName="text-white top-6 bg-label">
                        <MemberNatureIcon nature={holder.nature} className="w-4 h-4" />
                    </Tooltip>
                </span>
                <CopyableElement value={holder.name}>{holder.name}</CopyableElement>
            </p>
            <div className="flex gap-1">
                <CopyableElement value={holder.longNumber}>{holder.longNumber}</CopyableElement>
                {holder.siret && (
                    <>
                        <span>/</span>
                        <CopyableElement value={holder.siret}>{holder.siret}</CopyableElement>
                    </>
                )}
            </div>
            {holder.lastDepositCreator && <span className="italic font-bold">{translation.deposit.depositor}</span>}
        </div>
    );
}
