import { Chip, Size } from "../../../design-system";
import { Translation, useI18n } from "../../i18n";
import { User, UserInfo } from "../../model";

const COLORS: { [key in User.Status]: "success" | "neutral" | "warning" | "primary" } = {
    [User.Status.SENT]: "neutral",
    [User.Status.ACCEPTED]: "success",
    [User.Status.REVOKED]: "warning",
    [User.Status.REFUSED]: "warning",
    [User.Status.CANCELED]: "warning"
};

export default function ContributorStatus({ contributor, size = "md" }: { contributor: UserInfo; size?: Size }): JSX.Element {
    const { translation } = useI18n<Translation>();

    const status = contributor.active ? User.Status.ACCEPTED : User.Status.REVOKED;

    return <Chip label={translation.contributeurStatus[status]} type={COLORS[status]} size={size} tooltipContent={status} />;
}
