export default function CompanyWhiteLogo(): JSX.Element {
    return (
        <svg width="100%" height="100%" viewBox="0 0 402 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6633_2289)">
                <path
                    d="M205.279 13.2923L206.672 17.9396H210.602L205.129 0.299829H200.055L194.632 17.9396H198.413L199.706 13.2923H205.279ZM201.348 6.54621C201.547 5.94656 201.697 5.19699 201.846 4.44742C201.945 3.94771 202.045 3.49798 202.194 3.04824L202.244 2.89833H202.592L202.692 3.04824L202.841 3.59792C203.09 4.54737 203.338 5.64673 203.637 6.54621L204.831 10.6938H200.204L201.348 6.54621Z"
                    fill="white"
                />
                <path
                    d="M212.344 9.3446C212.294 11.9431 213.189 14.2418 214.831 15.7409C216.473 17.34 218.861 18.1395 222.045 18.1395C224.185 18.1395 226.424 17.6398 227.866 17.19V8.04535H221.548V10.7938H224.334V14.9413L224.185 14.9913C223.836 15.1912 223.04 15.2912 222.045 15.2912C218.413 15.2912 216.125 12.8925 216.125 9.14472C216.125 5.39688 218.513 3.04824 222.344 3.04824C224.334 3.04824 225.578 3.39803 226.424 3.74783L227.17 0.899489C226.274 0.49972 224.583 0.0999482 222.394 0.0999482C216.324 0.0999482 212.344 3.74784 212.344 9.3446Z"
                    fill="white"
                />
                <path d="M242.195 10.294V7.44569H235.478V3.19815H242.593V0.299829H231.797V17.9396H242.941V15.0413H235.478V10.294H242.195Z" fill="white" />
                <path
                    d="M250.155 4.69728C251.25 7.2458 252.593 9.79433 253.29 11.0436L257.121 17.8897H261.051V0.299829H257.718V5.34691C257.718 8.39515 257.817 10.6938 258.066 12.8426L258.116 13.0924H257.618L257.569 12.9425C256.773 10.8937 255.678 8.645 254.633 6.79607L250.902 0.299829H246.474V17.9396H249.807V12.7426C249.807 9.8443 249.757 7.24581 249.658 4.8472V4.69728L250.106 4.54737L250.155 4.69728Z"
                    fill="white"
                />
                <path
                    d="M274.186 0C268.365 0 264.484 3.74784 264.484 9.39457C264.484 14.8414 268.066 18.1895 273.788 18.1895C275.778 18.1895 277.37 17.8397 278.216 17.4899L277.669 14.7415C276.773 15.0413 275.479 15.2412 274.435 15.2412C270.703 15.2412 268.365 12.8925 268.365 9.09474C268.365 5.24697 270.653 2.89833 274.385 2.89833C275.529 2.89833 276.673 3.09821 277.669 3.49798L278.415 0.699592C277.669 0.399766 276.176 0 274.186 0Z"
                    fill="white"
                />
                <path d="M291.997 10.294V7.44569H285.33V3.19815H292.395V0.299829H281.649V17.9396H292.793V15.0413H285.33V10.294H291.997Z" fill="white" />
                <path
                    d="M306.326 10.8437C308.615 10.8437 310.555 10.1441 311.699 8.94482C312.595 8.04534 313.043 6.79607 313.043 5.24696C313.043 3.74783 312.495 2.49856 311.55 1.64905C310.455 0.699604 308.814 0.199881 306.624 0.199881C305.132 0.199881 303.689 0.299826 302.346 0.499711V17.9396H304.236V10.6438L304.535 10.6938C305.032 10.8437 305.729 10.8437 306.326 10.8437ZM304.187 9.19467V1.79896L304.336 1.74899C304.784 1.64905 305.629 1.54911 306.624 1.54911C309.51 1.54911 311.152 2.9483 311.152 5.29694C311.152 7.84546 309.361 9.39457 306.376 9.39457C305.53 9.39457 304.883 9.34459 304.386 9.19467H304.187Z"
                    fill="white"
                />
                <path
                    d="M324.038 0C319.212 0 315.829 3.7978 315.829 9.24465C315.829 14.4916 319.112 18.1895 323.789 18.1895C328.764 18.1895 331.998 14.5416 331.998 8.89486C331.998 3.59793 328.764 0 324.038 0ZM323.889 16.7903C319.958 16.7903 317.869 12.9925 317.869 9.24465C317.869 5.39688 319.759 1.49913 323.938 1.49913C328.068 1.49913 329.958 5.44685 329.958 9.09474C329.958 13.5921 327.471 16.7903 323.889 16.7903Z"
                    fill="white"
                />
                <path
                    d="M341.998 18.2394C346.277 18.2394 348.665 15.541 348.665 10.6438V0.299829H346.725V10.7938C346.725 14.6415 345.083 16.7903 342.098 16.7903C339.262 16.7903 337.62 14.5916 337.62 10.7938V0.299829H335.68V10.7438C335.73 16.9402 339.163 18.2394 341.998 18.2394Z"
                    fill="white"
                />
                <path
                    d="M361.651 13.792C361.999 15.3911 362.447 17.24 362.795 17.9396H364.735C364.387 17.0401 363.989 15.3911 363.492 13.2423C363.044 11.3434 362.248 10.1941 360.954 9.74436L360.805 9.69439V9.29462L360.954 9.24465C362.994 8.54505 364.238 6.89601 364.238 4.8472C364.238 3.49798 363.74 2.34865 362.895 1.59908C361.8 0.599659 360.208 0.199881 357.77 0.199881C356.228 0.199881 354.636 0.299829 353.491 0.549685V17.9896H355.382V10.1441H357.82C360.059 10.1941 361.103 11.1935 361.651 13.792ZM355.332 1.79896L355.481 1.74899C355.879 1.64905 356.675 1.54911 357.87 1.54911C360.705 1.59908 362.297 2.84836 362.297 5.14703C362.297 7.39573 360.606 8.79491 357.969 8.79491H355.332V1.79896Z"
                    fill="white"
                />
                <path d="M374.188 0.299829V17.9396H383.84V16.4405H376.079V0.299829H374.188Z" fill="white" />
                <path
                    d="M396.527 12.2429L398.468 17.9396H400.507L394.487 0.299829H392L385.98 17.9396H387.92L389.811 12.2429H396.527ZM391.95 5.34691C392.248 4.39746 392.547 3.34807 392.845 2.09879L392.895 1.94887H393.244L393.343 2.09879L393.393 2.29867C393.642 3.29809 393.89 4.29752 394.288 5.39688L396.179 10.8937H390.059L391.95 5.34691Z"
                    fill="white"
                />
                <path
                    d="M195.676 30.9821C196.92 30.7822 198.711 30.6323 201.249 30.6323C203.786 30.6323 205.577 31.132 206.821 32.0814C207.965 32.9809 208.761 34.48 208.761 36.279C208.761 38.078 208.164 39.5771 207.07 40.5765C205.677 41.8758 203.587 42.4754 201.149 42.4754C200.602 42.4754 200.104 42.4254 199.756 42.3755V48.8217H195.676V30.9821ZM199.756 39.1773C200.104 39.2773 200.552 39.2773 201.149 39.2773C203.338 39.2773 204.682 38.1779 204.682 36.329C204.682 34.6799 203.537 33.6805 201.498 33.6805C200.652 33.6805 200.104 33.7805 199.806 33.8304V39.1773H199.756Z"
                    fill="white"
                />
                <path
                    d="M211.597 30.9821C212.941 30.7822 214.881 30.6323 217.07 30.6323C219.757 30.6323 221.647 31.032 222.941 32.0315C224.035 32.881 224.632 34.1302 224.632 35.8293C224.632 38.1279 222.991 39.727 221.448 40.2767V40.3766C222.692 40.8763 223.438 42.0756 223.886 43.7747C224.433 45.8235 225.03 48.1721 225.379 48.8717H221.199C220.901 48.372 220.453 46.8729 219.956 44.6741C219.458 42.4254 218.712 41.8258 217.02 41.7758H215.777V48.8717H211.697V30.9821H211.597ZM215.677 38.7776H217.319C219.359 38.7776 220.602 37.7781 220.602 36.1791C220.602 34.53 219.458 33.6805 217.568 33.6805C216.573 33.6805 216.025 33.7805 215.727 33.8304V38.7776H215.677Z"
                    fill="white"
                />
                <path
                    d="M244.384 39.6271C244.384 45.5736 240.752 49.1715 235.478 49.1715C230.105 49.1715 226.921 45.1239 226.921 39.9769C226.921 34.58 230.404 30.4824 235.777 30.4824C241.3 30.4324 244.384 34.63 244.384 39.6271ZM231.249 39.827C231.249 43.3749 232.941 45.8735 235.677 45.8735C238.464 45.8735 240.056 43.225 240.056 39.727C240.056 36.4789 238.513 33.6805 235.677 33.6805C232.842 33.6805 231.249 36.329 231.249 39.827Z"
                    fill="white"
                />
                <path d="M250.106 34.1802H245.23V30.7322H259.21V34.1802H254.235V48.8217H250.106V34.1802Z" fill="white" />
                <path d="M272.445 41.1762H265.778V45.4737H273.241V48.8217H261.648V30.7322H272.843V34.0803H265.778V37.8281H272.445V41.1762Z" fill="white" />
                <path
                    d="M289.609 48.322C288.863 48.7218 287.171 49.1216 284.932 49.1216C278.664 49.1216 275.43 45.2238 275.43 40.0768C275.43 33.9304 279.858 30.4824 285.33 30.4824C287.47 30.4824 289.062 30.9321 289.808 31.2819L288.962 34.53C288.117 34.1802 286.972 33.8804 285.529 33.8804C282.246 33.8804 279.758 35.8293 279.758 39.8769C279.758 43.4748 281.897 45.7735 285.579 45.7735C286.823 45.7735 288.216 45.5236 289.012 45.1738L289.609 48.322Z"
                    fill="white"
                />
                <path d="M296.475 34.1802H291.599V30.7322H305.58V34.1802H300.604V48.8217H296.475V34.1802Z" fill="white" />
                <path d="M312.147 30.7322V48.8217H308.018V30.7322H312.147Z" fill="white" />
                <path
                    d="M332.595 39.6271C332.595 45.5736 328.963 49.1715 323.69 49.1715C318.316 49.1715 315.132 45.1239 315.132 39.9769C315.132 34.58 318.615 30.4824 323.988 30.4824C329.511 30.4324 332.595 34.63 332.595 39.6271ZM319.461 39.827C319.461 43.3749 321.152 45.8735 323.889 45.8735C326.675 45.8735 328.267 43.225 328.267 39.727C328.267 36.4789 326.724 33.6805 323.889 33.6805C321.053 33.6805 319.461 36.329 319.461 39.827Z"
                    fill="white"
                />
                <path
                    d="M335.531 48.8217V30.7322H340.307L344.088 37.3784C345.183 39.2773 346.227 41.526 347.073 43.5748H347.173C346.924 41.1762 346.824 38.7276 346.824 36.0291V30.7322H350.606V48.8217H346.277L342.396 41.8258C341.302 39.8769 340.158 37.5782 339.262 35.4295L339.163 35.4795C339.262 37.8781 339.312 40.4266 339.312 43.3749V48.8217H335.531Z"
                    fill="white"
                />
                <path
                    d="M372.547 46.2232C374.188 44.5742 375.084 42.2256 375.084 39.3772C375.084 36.6288 374.288 34.58 372.596 33.0309C371.004 31.5317 368.666 30.8322 365.382 30.8322C363.79 30.8322 362.198 30.9321 360.606 31.1819V48.5719C361.8 48.7218 363.143 48.7718 364.636 48.7718C368.119 48.7718 370.855 47.8723 372.547 46.2232ZM362.496 47.1727V32.5312L362.695 32.4812C363.193 32.3813 364.138 32.2313 365.482 32.2313C367.969 32.2313 369.91 32.881 371.253 34.2302C372.497 35.4795 373.094 37.2284 373.094 39.4272C373.094 44.4243 370.208 47.3226 365.133 47.3226C363.989 47.3226 363.243 47.2726 362.745 47.1727H362.496Z"
                    fill="white"
                />
                <path d="M387.82 40.1268V38.6276H380.756V32.4812H388.219V30.9821H378.865V48.6219H388.617V47.1227H380.756V40.1268H387.82Z" fill="white" />
                <path
                    d="M402 43.8746C402 41.476 400.706 39.9769 397.522 38.7776C395.084 37.8281 393.741 36.9786 393.741 35.0797C393.741 33.6305 394.885 32.1314 397.373 32.1314C398.816 32.1314 399.96 32.5312 400.706 32.881L401.204 31.4318C400.557 31.132 399.363 30.6822 397.472 30.6822C394.139 30.6822 391.801 32.6311 391.801 35.3795C391.801 37.6282 393.293 39.1773 396.428 40.2767C399.015 41.2761 400.01 42.3255 400.01 44.0745C400.01 46.0733 398.418 47.3726 395.98 47.3726C394.587 47.3726 393.144 46.9728 391.9 46.3232L391.452 47.8223C392.447 48.422 394.239 48.8717 395.831 48.8717C399.612 48.8717 402 46.9228 402 43.8746Z"
                    fill="white"
                />
                <path
                    d="M201.149 72.8578C203.637 72.8578 205.627 72.2082 206.921 71.0089C207.965 70.0095 208.513 68.5603 208.513 66.8613C208.513 65.2622 207.816 63.7631 206.672 62.8636C205.478 61.9142 203.736 61.4644 201.249 61.4644C199.159 61.4644 197.368 61.5644 195.925 61.8142V79.2541H199.557V72.7579L199.806 72.8079C200.154 72.8578 200.602 72.8578 201.149 72.8578ZM199.507 70.0594V64.3628L199.657 64.3128C199.856 64.2628 200.403 64.1629 201.398 64.1629C203.537 64.1629 204.831 65.2622 204.831 67.0612C204.831 69.0101 203.388 70.2593 201.05 70.2593C200.403 70.2593 199.955 70.2094 199.607 70.1594L199.507 70.0594Z"
                    fill="white"
                />
                <path
                    d="M220.055 75.2564C220.553 77.3552 220.951 78.7544 221.249 79.3041H224.981C224.732 78.6545 224.433 77.5051 224.085 76.2059C223.936 75.6562 223.787 75.0566 223.637 74.5069C223.14 72.7079 222.443 71.7085 221.349 71.2588L221.199 71.2088V70.759L221.349 70.7091C222.891 70.1594 224.384 68.6603 224.384 66.4615C224.384 64.9124 223.836 63.7131 222.792 62.8636C221.598 61.9142 219.806 61.5144 217.07 61.5144C215.179 61.5144 213.239 61.6143 211.846 61.8642V79.2541H215.478V72.1582H216.921C218.762 72.2082 219.558 72.9578 220.055 75.2564ZM215.428 64.2628L215.627 64.2128C216.125 64.1129 216.772 64.0629 217.518 64.0629C219.607 64.1129 220.752 65.0623 220.752 66.8113C220.752 68.5603 219.408 69.6597 217.269 69.6597H215.428V64.2628Z"
                    fill="white"
                />
                <path
                    d="M235.727 61.3645C230.652 61.3645 227.12 65.1623 227.12 70.6091C227.12 75.956 230.453 79.6039 235.429 79.6039C240.702 79.6039 244.135 75.956 244.135 70.3093C244.135 65.8119 241.548 61.3645 235.727 61.3645ZM235.677 76.7556C232.842 76.7556 231.051 74.307 231.051 70.5092C231.051 66.7114 232.891 64.1129 235.677 64.1129C238.414 64.1129 240.304 66.7114 240.304 70.3593C240.255 74.257 238.464 76.7556 235.677 76.7556Z"
                    fill="white"
                />
                <path
                    d="M246.723 70.6591C246.673 73.2576 247.568 75.5563 249.21 77.0554C250.852 78.6545 253.24 79.454 256.424 79.454C258.564 79.454 260.802 78.9543 262.245 78.5046V69.3599H255.927V72.1083H258.713V76.2559L258.564 76.3058C258.215 76.5057 257.419 76.6057 256.424 76.6057C252.792 76.6057 250.504 74.207 250.504 70.4592C250.504 66.7114 252.892 64.3628 256.723 64.3628C258.713 64.3628 259.957 64.7125 260.802 65.0623L261.549 62.214C260.653 61.8142 258.962 61.4144 256.772 61.4144C250.752 61.4644 246.772 65.0623 246.723 70.6591Z"
                    fill="white"
                />
                <path
                    d="M274.435 75.2564C274.932 77.3552 275.33 78.7544 275.629 79.3041H279.36C279.111 78.6545 278.813 77.5051 278.465 76.2059C278.315 75.6562 278.166 75.0566 278.017 74.5069C277.519 72.7079 276.823 71.7085 275.728 71.2588L275.579 71.2088V70.759L275.728 70.7091C277.271 70.1594 278.763 68.6603 278.763 66.4615C278.763 64.9124 278.216 63.7131 277.171 62.8636C275.977 61.9142 274.186 61.5144 271.449 61.5144C269.559 61.5144 267.619 61.6143 266.225 61.8642V79.2541H269.857V72.1582H271.3C273.191 72.2082 273.987 72.9578 274.435 75.2564ZM269.808 64.2628L270.007 64.2128C270.504 64.1129 271.151 64.0629 271.897 64.0629C273.987 64.1129 275.131 65.0623 275.131 66.8113C275.131 68.5603 273.788 69.6597 271.648 69.6597H269.808V64.2628Z"
                    fill="white"
                />
                <path
                    d="M292.097 74.6068L293.49 79.2541H297.42L291.948 61.6143H286.873L281.45 79.2541H285.231L286.525 74.6068H292.097ZM288.166 67.9107C288.365 67.311 288.515 66.5615 288.664 65.8119C288.763 65.3122 288.863 64.8624 289.012 64.4127L289.062 64.2628H289.41L289.51 64.4127L289.659 64.9624C289.908 65.9118 290.156 67.0112 290.455 67.9107L291.649 72.0583H287.022L288.166 67.9107Z"
                    fill="white"
                />
                <path
                    d="M316.028 72.558L316.276 79.2541H319.809L318.814 61.6143H313.839L311.948 67.461C311.301 69.7096 310.654 72.2082 310.207 73.9572L310.157 74.1071H309.709L309.659 73.9072C309.311 72.1083 308.814 70.0594 308.117 67.461L306.376 61.6143H301.301L300.157 79.2541H303.49L303.789 72.608C303.888 71.1588 303.938 69.4598 303.988 67.8108C304.037 66.9113 304.037 66.0118 304.087 65.1123V64.9124H304.485L304.585 65.1123C304.983 67.2611 305.58 69.8096 306.226 72.1582L308.117 79.0043H311.003L313.142 72.1083C313.888 69.8596 314.635 67.311 315.182 65.1123L315.232 64.9624H315.729V65.2122C315.878 67.9107 315.928 70.7091 316.028 72.558Z"
                    fill="white"
                />
                <path
                    d="M339.113 72.558L339.362 79.2541H342.894L341.899 61.6143H336.924L335.033 67.461C334.386 69.7096 333.74 72.2082 333.292 73.9572L333.242 74.1071H332.844L332.794 73.9072C332.446 72.1083 331.948 70.0594 331.252 67.461L329.511 61.6143H324.436L323.292 79.2541H326.625L326.923 72.608C327.023 71.1588 327.073 69.4598 327.122 67.8108C327.172 66.9113 327.172 66.0118 327.222 65.1123V64.9124H327.62L327.72 65.1123C328.118 67.2611 328.715 69.8096 329.361 72.1582L331.252 79.0043H334.138L336.277 72.1083C337.023 69.8596 337.77 67.311 338.317 65.1123L338.367 64.9624H338.864V65.2122C338.914 67.9107 339.013 70.7091 339.113 72.558Z"
                    fill="white"
                />
                <path d="M357.173 71.6086V68.7602H350.456V64.5626H357.571V61.6643H346.775V79.2541H357.919V76.3558H350.456V71.6086H357.173Z" fill="white" />
                <path
                    d="M372.795 74.1571C372.795 71.7585 371.402 70.1594 368.218 68.9601C365.979 68.1106 364.686 67.5609 364.686 66.2117C364.686 65.3122 365.432 64.2628 367.571 64.2628C368.865 64.2628 370.059 64.5127 371.203 65.0124L371.999 62.164C370.706 61.6143 369.263 61.3645 367.621 61.3645C363.641 61.3645 360.954 63.4633 360.954 66.5615C360.954 68.8601 362.546 70.5592 365.78 71.7085C367.82 72.4081 368.964 73.1077 368.964 74.4569C368.964 75.8061 367.82 76.6556 365.83 76.6556C364.437 76.6556 362.795 76.2559 361.452 75.6062L360.705 78.5545C361.7 79.0542 363.591 79.554 365.581 79.554C370.905 79.554 372.795 76.7556 372.795 74.1571Z"
                    fill="white"
                />
                <path
                    d="M26.9658 0C12.0899 0 0 11.4933 0 25.6851V73.2076C0 76.5057 2.63687 79.2042 5.87078 79.2042C9.10469 79.2042 11.7416 76.5057 11.7416 73.2076V25.6851C11.7416 17.9896 18.5577 11.7432 26.9658 11.7432C35.374 11.7432 42.1901 17.9896 42.1901 25.6851V73.2076C42.1901 76.5057 44.827 79.2042 48.0609 79.2042C51.2948 79.2042 53.9317 76.5057 53.9317 73.2076V25.6851C53.9317 11.4933 41.8418 0 26.9658 0Z"
                    fill="white"
                />
                <path
                    d="M90.4002 0H69.1559C66.0215 0 63.4344 2.64847 63.4344 5.84662C63.4344 9.04477 66.0215 11.6932 69.1559 11.6932H90.4002C98.8084 11.6932 105.624 17.9396 105.624 25.6352C105.624 33.3307 98.8084 39.5771 90.4002 39.5771H75.176V31.5817C75.176 28.3336 72.5391 25.6851 69.3052 25.6851C66.0713 25.6851 63.4344 28.3336 63.4344 31.5817V73.3076C63.4344 76.5557 66.0215 79.1542 69.1559 79.1542H69.4544C72.5888 79.1542 75.176 76.5057 75.176 73.3076V52.5696C75.176 51.87 75.8725 51.3203 76.768 51.3203H90.4002C105.276 51.3203 117.366 39.827 117.366 25.6352C117.366 11.4434 105.276 0 90.4002 0Z"
                    fill="white"
                />
                <path
                    d="M152.84 0H131.595C128.461 0 125.874 2.64847 125.874 5.84662C125.874 9.04477 128.461 11.6932 131.595 11.6932H152.84C161.248 11.6932 168.064 17.9396 168.064 25.6352C168.064 33.3307 161.248 39.5771 152.84 39.5771H137.615V31.5817C137.615 28.3336 134.978 25.6851 131.744 25.6851C128.511 25.6851 125.874 28.3336 125.874 31.5817V73.3076C125.874 76.5557 128.461 79.1542 131.595 79.1542H131.894C135.028 79.1542 137.615 76.5057 137.615 73.3076V52.5196C137.665 51.87 138.362 51.3203 139.158 51.3203H152.79C167.666 51.3203 179.756 39.827 179.756 25.6352C179.756 11.4434 167.716 0 152.84 0Z"
                    fill="white"
                />
                <path
                    d="M32.7371 40.1268H31.6426L31.5431 37.8281C31.3938 35.9792 29.354 34.53 26.9161 34.53C24.4782 34.53 22.4384 35.9792 22.2891 37.8281L22.1896 40.1268H21.095C20.6473 40.1268 20.299 40.4766 20.299 40.9263V51.0205C20.299 51.4702 20.6473 51.82 21.095 51.82H32.7869C33.2346 51.82 33.5829 51.4702 33.5829 51.0205V40.9263C33.5332 40.4766 33.1849 40.1268 32.7371 40.1268ZM27.4634 46.3232V47.7224C27.4634 48.0722 27.2146 48.322 26.8663 48.322C26.5181 48.322 26.2693 48.0722 26.2693 47.7224V46.3232C25.921 46.1233 25.6225 45.8235 25.6225 45.3238C25.6225 44.6242 26.1698 44.0745 26.8663 44.0745C27.5629 44.0745 28.1101 44.6242 28.1101 45.3238C28.1599 45.8235 27.8116 46.1233 27.4634 46.3232ZM23.5827 40.1268L23.6324 38.4278C23.7319 37.1785 25.125 36.1791 26.8166 36.1791C28.5082 36.1791 29.9012 37.1785 30.0007 38.4278L30.0505 40.1268H23.5827Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_6633_2289">
                    <rect width="402" height="79.6039" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
