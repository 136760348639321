import { useState } from "react";
import { Cell, Chip, Popup, SadBox, SimpleTable, Size, SIZE_MAP } from "../../../design-system";
import Button from "../../../design-system/components/Button/Button";
import { Translation, useI18n } from "../../i18n";
import { Representative } from "../../model";

enum InvitationAction {
    SUBMIT = "submit",
    CANCEL = "cancel"
}

export type Invitations = {
    memberName: string;
    userName: string;
    userFirstName: string;
    receivedAt: string;
    acceptedAt?: string;
    invitationStatus: Representative.InvitationStatus;
    invitationType: Representative.InvitationType;
    actions?: boolean;
};

const COLORS: { [key in Representative.InvitationStatus]: "success" | "warning" | "primary" } = {
    [Representative.InvitationStatus.REFUSED]: "warning",
    [Representative.InvitationStatus.ACCEPTED]: "success",
    [Representative.InvitationStatus.PENDING]: "primary"
};

const InvitationStatus = ({ status, size }: { status: Representative.InvitationStatus; size: Size }): JSX.Element => {
    const { translation } = useI18n<Translation>();
    return <Chip label={translation.invitationStatus[status]} type={COLORS[status]} size={size} tooltipContent={status} />;
};

const ActionRow = ({ translation, onAccept, onReject }: { translation: Translation; onAccept: () => void; onReject: () => void }): JSX.Element => {
    return (
        <div className="flex justify-center gap-x-4 w-28">
            <Button variant="link" label={translation.accepted} size="xs" underline onClick={onAccept} />
            <Button variant="link" label={translation.reject} size="xs" color="danger" onClick={onReject} />
        </div>
    );
};

export default function RepresentativesInvitations({ invitations }: { invitations: Invitations[] }): JSX.Element {
    const { translation } = useI18n<Translation>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [action, setAction] = useState<InvitationAction>();

    const defaultStyle = "text-left";
    const memberTitles: Cell[] = [
        { value: translation.invitationUserTitle, style: defaultStyle },
        { value: translation.invitationTitleAPP, style: defaultStyle },
        { value: translation.receivedAt, style: defaultStyle },
        { value: translation.invitationFrom, style: defaultStyle },
        { value: translation.statut, style: defaultStyle },
        { value: translation.invitationAcceptedOrRefused, style: defaultStyle },
        { value: translation.actions }
    ];

    const handleOpen = (stateInvitation: InvitationAction) => {
        setIsOpen(true);

        if (stateInvitation === InvitationAction.SUBMIT) {
            setAction(InvitationAction.SUBMIT);
        } else {
            setAction(InvitationAction.CANCEL);
        }
    };

    const submit = () => {
        setIsOpen(false);
    };

    const cancel = () => {
        setIsOpen(false);
    };

    const invoiceToRow = (invitation: Invitations): Cell[] => [
        { value: translation.invitationType[invitation.invitationType] },
        { value: invitation.memberName },
        { value: invitation.receivedAt },
        { value: `${invitation.userFirstName} ${invitation.userName.toUpperCase()}` },
        { value: <InvitationStatus status={invitation.invitationStatus} size="sm" /> },
        { value: invitation.acceptedAt ?? "-" },
        {
            value: invitation.actions ? (
                <ActionRow
                    translation={translation}
                    onAccept={() => handleOpen(InvitationAction.SUBMIT)}
                    onReject={() => handleOpen(InvitationAction.CANCEL)}
                />
            ) : (
                "-"
            ),
            style: "flex justify-center"
        }
    ];

    const memberValues: Cell[][] = invitations.map(invoiceToRow);

    return (
        <>
            <div className="space-y-2.5">
                <h2 className="font-semibold text-sm text-label">{translation.receivedInvitation}</h2>
                {invitations.length ? (
                    <SimpleTable titles={memberTitles} values={memberValues} layout="auto" />
                ) : (
                    <div className="pt-8 space-y-2.5 flex flex-col justify-center items-center">
                        <SadBox className={SIZE_MAP.xxl} />
                        <span className="text-light-grey">{translation.noInvitations}</span>
                    </div>
                )}
            </div>
            <Popup
                title={action === InvitationAction.CANCEL ? translation.reject : translation.accepted}
                isOpen={isOpen}
                leftContent={<Button label={translation.cancel} onClick={cancel} variant="danger" />}
                rightContent={<Button label={translation.confirm} onClick={submit} withLoader={true} />}
            >
                <div className="flex flex-col gap-4">
                    <span className="text-label">
                        {action === InvitationAction.CANCEL ? translation.titleCancelInvitation : translation.titleConfirmInvitation}
                    </span>
                </div>
            </Popup>
        </>
    );
}
