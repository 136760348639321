import {
    BranChIcon,
    Button,
    DepositStats,
    DepositStore,
    DownloadIcon,
    getDepositStatsByUserId,
    getDepositStoresByUserId,
    getMemberExports,
    Input,
    Layout,
    Loader,
    Member,
    MemberExports,
    searchDepositStoresByUserId,
    useApi,
    useI18n,
    usePrevious,
    UserContext,
    Wallet
} from "@vaultinum/app-sdk";
import { useDebounce } from "@vaultinum/shared-web-app-sdk";
import { saveAs } from "file-saver";
import { useContext, useEffect, useState } from "react";
import { Translation } from "../../../i18n";
import { toDepositStorePage } from "../../../services";

export default function WalletPage(): JSX.Element {
    const [depositStores, setDepositStores] = useState<DepositStore[]>();
    const [depositStoreInfo, setDepositStoreInfo] = useState<DepositStats>();
    const [inputSearch, setInputSearch] = useState<string>("");
    const { translation, translate } = useI18n<Translation>();
    const debounce = useDebounce(inputSearch, 300);
    const { fetchApi } = useApi();
    const prevStateInput = usePrevious(debounce);
    const { user } = useContext(UserContext);
    const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);
    const [memberExports, setMemberExports] = useState<MemberExports>();
    const [isPortfolioLoading, setIsPortfolioLoading] = useState(false);

    const fetchData = async () => {
        if (user?.sub) {
            const depositStoresResponse = await fetchApi(
                () => getDepositStoresByUserId(user?.sub),
                () => setIsFirstLoading(false)
            );
            setDepositStores(depositStoresResponse);
        }
    };

    const loadDepositStoresWithFilter = async () => {
        if (user?.sub) {
            const depositStoresFiltered = await fetchApi(() => searchDepositStoresByUserId(user?.sub, inputSearch));
            setDepositStores(depositStoresFiltered);
        }
    };

    useEffect(() => {
        void fetchData();
    }, []);

    useEffect(() => {
        if (!isFirstLoading) {
            setDepositStores(undefined);
            void loadDepositStoresWithFilter();
        }
    }, [debounce]);

    useEffect(() => {
        if (user?.sub) {
            void (async () => {
                const depositStats = await fetchApi(() => getDepositStatsByUserId(user?.sub));
                setDepositStoreInfo(depositStats);
            })();
        }
    }, [user]);

    useEffect(() => {
        void (async () => {
            if (user?.memberId) {
                const memberExportsResponse = await fetchApi(() => getMemberExports({ id: user?.memberId } as Member));

                setMemberExports(memberExportsResponse);
            }
        })();
    }, []);

    const downloadPortfolio = async () => {
        if (!memberExports?.deposits) {
            return;
        }

        setIsPortfolioLoading(true);

        const file = await memberExports.deposits();

        saveAs(file.blob, file.name);

        setIsPortfolioLoading(false);
    };

    if (!depositStoreInfo || !memberExports) {
        return <Loader />;
    }

    const subtitleList: Record<string, string> = {
        [translation.label(translation.depositStore.names)]: translate(translation.deposit.availableStoresCount, depositStoreInfo?.depositStoreCount || 0),
        [translation.label(translation.deposits)]: translate(translation.deposit.achievedCount, depositStoreInfo?.depositCount || 0)
    };

    return (
        <Layout
            title={translation.portfolio}
            subTitle={subtitleList}
            icon={<BranChIcon className="w-6 h-8 fill-primary" />}
            children={
                <div className="space-y-5">
                    {isFirstLoading ? (
                        <div className="animate-pulse flex">
                            <div className="flex-1 space-y-1">
                                <div className="w-52 h-3 bg-gray-200"></div>
                                <div className="w-96 h-8  bg-gray-200"></div>
                                <div className="w-52 h-1 bg-gray-200"></div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex justify-between items-center">
                            <div className="w-96">
                                <Input
                                    label={translation.searchDeposit}
                                    onChange={e => setInputSearch(e.target.value)}
                                    value={inputSearch}
                                    helperText={translation.searchDepositHelper}
                                    type="search"
                                />
                            </div>
                            <span className="group inline-block">
                                <Button
                                    label={translation.deposit.export}
                                    loading={isPortfolioLoading}
                                    icon={<DownloadIcon className="fill-primary group-hover:fill-white" />}
                                    onClick={async () => {
                                        await fetchApi(downloadPortfolio);
                                    }}
                                />
                            </span>
                        </div>
                    )}
                    <Wallet
                        depositStores={depositStores}
                        depositLink={depositStore => toDepositStorePage(depositStore)}
                        depositStoreLink={depositStore => toDepositStorePage(depositStore)}
                        showToolTip={false}
                        loading={!depositStores || prevStateInput !== inputSearch}
                    />
                </div>
            }
        />
    );
}
