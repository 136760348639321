import { Chip, CopyableElement, LayoutSide } from "../../../design-system/components";
import { useI18n } from "../../i18n";
import { Agreement } from "../../model";

type SideElement = {
    component: React.ReactNode;
    elementType: "component";
};

function SideComponent({ agreement }: { agreement: Agreement }): JSX.Element {
    const { translation } = useI18n();

    return (
        <CopyableElement value={agreement.reference}>
            <span>{`${translation.agreementStatus[agreement.type]} n° ${agreement.reference} : `}</span>
            <Chip label={agreement.canceled ? translation.inactive : translation.active} type={agreement.canceled ? "danger" : "success"} size="sm" />
        </CopyableElement>
    );
}

export default function AgreementSide({ agreements }: { agreements: Agreement[] }): JSX.Element {
    const { translation } = useI18n();

    const sideElements: SideElement[] = agreements.map(agreement => ({
        component: <SideComponent agreement={agreement} />,
        elementType: "component"
    }));

    return <LayoutSide title={translation.contracts} sideElements={sideElements} />;
}
