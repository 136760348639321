import { Badge, isDevelopment, isProduction, isStaging, useI18n } from "@vaultinum/app-sdk";
import classNames from "classnames";
import { useContext } from "react";

import { Translation } from "../../i18n";
import { MemberContext } from "../../contexts";

export default function Cartridge() {
    const { member } = useContext(MemberContext);

    const { translation } = useI18n<Translation>();

    const label = [isProduction() ? undefined : translation.applicationBadge, member && translation.memberNumber(member)].filter(text => !!text).join(" | ");

    return (
        member && (
            <Badge
                label={label}
                className={classNames({
                    "bg-success": isDevelopment(),
                    "bg-warning": isStaging(),
                    "bg-primary": isProduction()
                })}
            />
        )
    );
}
