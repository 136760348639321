import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Checkout, Loader, OrderCredit, useI18n, useToast } from "@vaultinum/app-sdk";
import { useEffect, useMemo, useState } from "react";

import { ShoppingCart } from "../../contexts";
import { Translation } from "../../i18n";

const APPEARANCE = {
    variables: {
        borderRadius: "0",
        colorPrimary: "#004C93",
        fontFamily: "inter, sans-serif",
        fontSizeBase: "0.95rem"
    }
};

const PaymentForm = ({ returnUrl }: { returnUrl: string }): JSX.Element => {
    const [isFormLoading, setIsFormLoading] = useState<boolean>(true);
    const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);

    const stripe = useStripe();
    const elements = useElements();

    const { translation } = useI18n<Translation>();
    const toast = useToast();

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }

        setIsPaymentLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: returnUrl
            }
        });

        if (error) {
            toast.error(error.message);
        }

        setIsPaymentLoading(false);
    };

    return (
        <div className="flex flex-col items-center gap-4">
            <div className="w-full">
                <PaymentElement onReady={() => setIsFormLoading(false)} />
            </div>
            <Button label={translation.validateOrderAndPay} onClick={handleSubmit} disabled={isFormLoading} loading={isPaymentLoading} />
        </div>
    );
};

export function CheckoutForm({
    orderCreditsWithCreditCard,
    shoppingCart,
    returnUrl
}: {
    orderCreditsWithCreditCard: (orderCredit: OrderCredit) => Promise<Checkout>;
    shoppingCart: ShoppingCart;
    returnUrl: string;
}): JSX.Element {
    const [clientSecret, setClientSecret] = useState<string>();
    const [customerSessionClientSecret, setCustomerSessionClientSecret] = useState<string>();

    const stripePromise = useMemo(() => loadStripe(process.env.REACT_APP_STRIPE_API_KEY ?? ""), [process.env.REACT_APP_STRIPE_API_KEY]);

    const fetchClientSecret = async (): Promise<void> => {
        const checkout = await orderCreditsWithCreditCard({
            items: Object.entries(shoppingCart.items).map(([reference, quantity]) => ({ reference, quantity })),
            externalReference: shoppingCart.externalReference
        });

        setClientSecret(checkout.metadata.stripeClientSecret);
        setCustomerSessionClientSecret(checkout.metadata.stripeCustomerSessionClientSecret);
    };

    useEffect(() => {
        void fetchClientSecret();
    }, []);

    return (
        <>
            {(!clientSecret || !customerSessionClientSecret) && <Loader position="relative" />}
            {!!clientSecret && !!customerSessionClientSecret && (
                <Elements stripe={stripePromise} options={{ clientSecret, customerSessionClientSecret, appearance: APPEARANCE }}>
                    <PaymentForm returnUrl={returnUrl} />
                </Elements>
            )}
        </>
    );
}
