import { Deposit } from "@vaultinum/app-sdk";

import { Translation } from "../i18n";

export function subscriptionTranslation(label: string, translation: Translation): string {
    const SUBSCRIPTION: Record<string, string> = {
        [Deposit.SubscriptionTypeCode.S]: translation.depositTypeStep.standard.subtitle || "",
        [Deposit.SubscriptionTypeCode.D]: translation.depositTypeStep.verified.subtitle || "",
        [Deposit.SubscriptionTypeCode.C]: translation.depositTypeStep.controlled.subtitle || ""
    };

    return SUBSCRIPTION[label];
}

export function contractTypeTranslation(label: string, translation: Translation): string {
    const CONTRACT_TYPE: Record<string, string> = {
        standard: translation.pack.free.title,
        bipartite: translation.pack.mid.title,
        tripartite: translation.pack.high.title
    };

    return CONTRACT_TYPE[label];
}
