import classNames from "classnames";
import { ReactNode, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

type TabItem = {
    title: string;
    component: ReactNode;
    key?: string;
};

export default function Tabs({ tabs, currentIndex = 0 }: { tabs: TabItem[]; currentIndex?: number }) {
    const [activeTab, setActiveTab] = useState<number>(currentIndex);
    const navigate = useNavigate();
    const param = useParams();

    const currentComponent = tabs.find(tab => tab.key === param.tab);

    return (
        <>
            <div className="flex-initial text-center border-b border-light-grey bg-primary-xlight px-3">
                <ul className="flex flex-wrap -mb-px space-x-2">
                    {tabs.map(({ title, key }, index) => (
                        <li key={title} onClick={() => (key ? navigate(`../${key}`, { relative: "path" }) : setActiveTab(index))}>
                            <span
                                className={classNames("inline-block py-4 px-2 cursor-pointer tracking-wide text-xs", {
                                    "text-primary font-bold": param.tab === key,
                                    "text-color-disabled": param.tab !== key
                                })}
                            >
                                {title.toUpperCase()}
                            </span>
                            <hr
                                className={classNames({
                                    " border-primary border-b-2 w-12 mx-auto": param.tab === key,
                                    "border-transparent hover:text-primary": param.tab !== key
                                })}
                            />
                        </li>
                    ))}
                </ul>
            </div>
            <div className="flex-auto h-full grow py-5 px-3 border border-light-grey border-t-0 rounded-none">
                {currentComponent ? currentComponent.component : tabs[activeTab].component}
            </div>
        </>
    );
}
