import classNames from "classnames";
import { saveAs } from "file-saver";
import { ReactNode, useState } from "react";

import { Button, Cell, Chip, CustomLink, Loader, SimpleTable } from "../../../design-system";
import { standardFormatDate } from "../../helpers";
import { useI18n } from "../../i18n";
import { Invoice } from "../../model";
import { Download, downloadInvoicePdf } from "../../services";
import { Number } from "../Number";

const invoiceStatusColors: { [key in Invoice.Status]: "success" | "warning" | "danger" } = {
    PAID: "success",
    UNPAID: "warning",
    CANCELLED: "danger"
};

const InvoiceStatus = ({ invoice }: { invoice: Invoice }): JSX.Element => {
    const { translation } = useI18n();

    return <Chip label={translation.invoiceStatus[invoice.status]} type={invoiceStatusColors[invoice.status]} size="sm" />;
};

const downloadInvoicePdfCallback = async (downloadFn: () => Promise<Download>, setIsLoading: (value: boolean) => void) => {
    setIsLoading(true);

    try {
        const file = await downloadFn();

        saveAs(file.blob, file.name);
    } finally {
        setIsLoading(false);
    }
};

const InvoiceActions = ({ invoice }: { invoice: Invoice }): ReactNode => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { translation } = useI18n();

    const downloadInvoicePdfFn = downloadInvoicePdf(invoice);

    return (
        <>
            {isLoading && <Loader position="relative" />}
            {!isLoading && (
                <>
                    {downloadInvoicePdfFn && (
                        <Button
                            variant="link"
                            label={translation.download}
                            size="sm"
                            onClick={() => downloadInvoicePdfCallback(downloadInvoicePdfFn, setIsLoading)}
                        />
                    )}
                    {invoice._links.payment && (
                        <CustomLink label={translation.pay} target="_blank" path={invoice._links.payment.href} className="no-underline" />
                    )}
                </>
            )}
        </>
    );
};

const InvoiceDataLoading = (): JSX.Element => <div className="animate-pulse rounded bg-gray-200 w-auto h-6" />;

const invoiceToRow = (invoice: Invoice): Cell[] => [
    { value: standardFormatDate(invoice.createdAt) },
    { value: <span className={classNames({ "line-through": invoice.status === Invoice.Status.CANCELLED })}>{invoice.reference}</span> },
    {
        value: (
            <ul>
                {invoice.items.map(item => (
                    <li key={item.name}>
                        {item.quantity} x {item.name}
                    </li>
                ))}
            </ul>
        )
    },
    { value: <Number.Currency value={invoice.totalVatExcluded} />, style: "text-right" },
    { value: <Number.Currency value={invoice.totalVatIncluded} />, style: "text-right" },
    { value: <InvoiceStatus invoice={invoice} /> },
    { value: <InvoiceActions invoice={invoice} /> }
];

const loadingInvoiceRow: Cell[] = Array.from({ length: 7 }, () => ({ value: <InvoiceDataLoading /> }));

const loadingInvoiceRows = (count: number): Cell[][] => Array.from({ length: count }, () => loadingInvoiceRow);

const createHeaderCell = (value: string): Cell => ({ value, style: "text-left" });

export default function InvoicesList({ invoices, loadingCount = 2 }: { invoices?: Invoice[]; loadingCount?: number }): JSX.Element {
    const { translation } = useI18n();

    const titles: Cell[] = [
        createHeaderCell(translation.date),
        createHeaderCell(translation.reference),
        createHeaderCell(translation.articles),
        createHeaderCell(translation.priceExcludingTax),
        createHeaderCell(translation.priceIncludingTax),
        createHeaderCell(translation.status),
        createHeaderCell(translation.actions)
    ];

    const values: Cell[][] = invoices?.map(invoiceToRow) ?? loadingInvoiceRows(loadingCount);

    return (
        <>
            <SimpleTable titles={titles} values={values} />
            {values.length === 0 && <p className="text-center p-3">{translation.noInvoice}</p>}
        </>
    );
}
