export default function SkeletonTable({ rowNumber = 5 }: { rowNumber?: number }): JSX.Element {
    return (
        <table className="w-full animate-pulse">
            <thead className=" bg-primary-xlight">
                <tr>
                    <th scope="col" className="p-4" />
                    <th scope="col" className="px-6 py-3">
                        <div className="h-2 bg-gray-200 rounded"></div>
                    </th>
                    <th scope="col" className="px-6 py-3">
                        <div className="h-2 bg-gray-200 rounded"></div>
                    </th>
                    <th scope="col" className="px-6 py-3">
                        <div className="h-2 bg-gray-200 rounded"></div>
                    </th>
                    <th scope="col" className="px-6 py-3">
                        <div className="h-2 bg-gray-200 rounded"></div>
                    </th>
                    <th scope="col" className="px-6 py-3">
                        <div className="h-2 bg-gray-200 rounded"></div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {Array.from({ length: rowNumber }).map((_, index) => (
                    <tr className="border-b" key={index}>
                        <td className="p-4 w-5">
                            <div className="w-5 h-2 bg-gray-200 rounded"></div>
                        </td>
                        <th scope="row" className="px-6 py-4">
                            <div className="h-2 bg-gray-200 rounded"></div>
                        </th>
                        <td className="px-6 py-4">
                            <div className="h-2 bg-gray-200 rounded"></div>
                        </td>
                        <td className="px-6 py-4">
                            <div className="h-2 bg-gray-200 rounded"></div>
                        </td>
                        <td className="px-6 py-4">
                            <div className="h-2 bg-gray-200 rounded"></div>
                        </td>
                        <td className="px-6 py-4">
                            <div className="h-2 bg-gray-200 rounded"></div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
