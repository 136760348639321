import { Link } from "../Hateoas";

export enum RequestType {
    MANDATE = "MANDATE",
    REPRESENTATIVE = "REPRESENTATIVE"
}

type Requester = {
    member: {
        name: string;
    };
    user: {
        firstName: string;
        lastName: string;
    };
};

export type Request = {
    type: RequestType;
    requester: Requester;
    _links: {
        accept: Link;
        reject: Link;
    };
};
