import { Deposit, InterdepositContext, Layout, LetterOpen, Loader, getDepositsByDepositStore, useApi, useI18n } from "@vaultinum/app-sdk";
import { useContext, useEffect, useState } from "react";
import { CreateDeposit } from "../../../../components";
import { WalletBreadCrumb } from "../../../../components/BreadCrumb";
import { Translation } from "../../../../i18n";

export default function DepositStoreCreateDepositPage(): JSX.Element {
    const { fetchApi } = useApi();
    const { depositStore } = useContext(InterdepositContext);
    const { translation } = useI18n<Translation>();
    const [prefill, setPrefill] = useState<Deposit>();

    useEffect(() => {
        if (depositStore) {
            void (async () => {
                await fetchApi(
                    () => getDepositsByDepositStore(depositStore),
                    deposits => setPrefill(deposits[0])
                );
            })();
        }
    }, [depositStore]);

    if (!depositStore) {
        return <Loader />;
    }

    return (
        <>
            <WalletBreadCrumb depositStore={depositStore} deposit={prefill} />
            <Layout
                title={`${translation.label(translation.depositOfUpdate)} ${depositStore.name || "-"}`}
                icon={<LetterOpen className="w-6 h-8 fill-primary" />}
            >
                <div className="min-w-max max-w-3xl mx-auto">
                    <CreateDeposit prefillDeposit={prefill} />
                </div>
            </Layout>
        </>
    );
}
