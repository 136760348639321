import classNames from "classnames";
import { ReactNode } from "react";

export interface Cell {
    value: ReactNode;
    style?: string;
    merge?: boolean;
}

export function SimpleTable({
    titles,
    values,
    layout = "fixed",
    mergeLength
}: {
    titles: Cell[];
    values?: Cell[][];
    layout?: "auto" | "fixed";
    mergeLength?: number;
}) {
    return (
        <table
            className={classNames("min-w-full w-full border-separate border-spacing-x-0 text-sm", {
                "table-auto": layout === "auto",
                "table-fixed": layout === "fixed"
            })}
        >
            <thead>
                <tr>
                    {titles.map((cell, colIndex) => (
                        <th
                            key={`${colIndex}-${cell.value}`}
                            className={classNames("text-brown-dark border border-blue-teal bg-primary-xlight px-3 py-2.5", cell.style)}
                        >
                            {cell.value}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {values?.map((cells, rowIndex) => (
                    <tr key={`row-${rowIndex}`} className="odd:bg-white even:bg-gray-100">
                        {cells.map((cell, index) => (
                            <td
                                key={`col-${rowIndex}-${index}`}
                                className={classNames("border text-deep-blue border-dark-blue-teal px-3 py-2.5", cell.style)}
                                rowSpan={cell.merge ? mergeLength : 1}
                            >
                                {cell.value}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
