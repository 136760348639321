import { ReactNode, useContext } from "react";
import { CopyableElement, CustomLink } from "../../../design-system/components";
import { bytesConverter, productClassCode, standardFormatDate } from "../../helpers";
import { I18nContextInterface, Translation, useI18n } from "../../i18n";
import { Deposit } from "../../model";
import { Apis, ApisContext } from "../../services";
import { DepositModeIcon, DepositTypeIcon } from "../DepositIcon";
import DepositSection, { DepositSectionData } from "../DepositSection/DepositSection";

const DepositCreatorMember = ({ member }: { member: { name: string; longNumber: string } }): ReactNode => {
    return (
        <div className="flex">
            {member.name} (<CopyableElement value={member.longNumber}>{member.longNumber}</CopyableElement>)
        </div>
    );
};

const DepositCreatorUser = ({
    user,
    translation
}: {
    user: { firstName: string; lastName: string; role: Deposit.CreatorUserRole };
    translation: Translation;
}): ReactNode => {
    return (
        <div className="flex">
            {user.firstName} {user.lastName} ({translation.creatorUserRole[user.role]})
        </div>
    );
};

const DepositCreator = ({ deposit, translation }: { deposit: Deposit; translation: Translation }): ReactNode => {
    return (
        <>
            {deposit.creator.user && (
                <div className="flex flex-wrap gap-1">
                    <DepositCreatorMember member={deposit.creator.member} />
                    -
                    <DepositCreatorUser user={deposit.creator.user} translation={translation} />
                </div>
            )}
            {!deposit.creator.user && <DepositCreatorMember member={deposit.creator.member} />}
        </>
    );
};

const getLogiboxData = (deposit: Deposit, translation: Translation): DepositSectionData[] => {
    if (!deposit.logibox) {
        return [];
    }

    if ("logiboxAdh" in deposit.logibox) {
        return [
            { title: translation.depositStore.logiboxAdhWithLabel, value: deposit.logibox.logiboxAdh },
            { title: translation.depositStore.logiboxAppWithLabel, value: deposit.logibox.logiboxApp },
            { title: translation.depositStore.quantitySupport, value: deposit.logibox.mediaType }
        ];
    } else {
        return [
            { title: translation.depositStore.logibox1WithLabel, value: deposit.logibox.logibox1 },
            { title: translation.depositStore.logibox2WithLabel, value: deposit.logibox.logibox2 },
            { title: translation.depositStore.quantitySupport, value: deposit.logibox.mediaType },
            { title: translation.depositStore.additionnalInformation, value: deposit.logibox.note }
        ];
    }
};

const getFileData = (deposit: Deposit, translation: Translation, locale: string): DepositSectionData[] => {
    const fileData = [];

    if (deposit.filesCount) {
        fileData.push({ title: translation.depositStore.numberDeposit, value: deposit.filesCount.toString() });
    }

    if (deposit.filesSizeInBytes) {
        const convert = bytesConverter(locale, translation.file);

        fileData.push({
            title: translation.depositStore.depositStoreSize,
            value: convert(deposit.filesSizeInBytes)
        });
    }

    return fileData;
};

const generalDepositInformation = (deposit: Deposit, translationContext: I18nContextInterface<Translation>, urlMemberPage?: string): DepositSectionData[] => {
    const { translation, locale } = translationContext;

    return [
        {
            title: translation.label(translation.depositNumber),
            value: deposit.iddn
        },
        { title: translation.label(translation.depositStore.sealedDate), value: standardFormatDate(deposit.sealedAt) },
        {
            title: translation.depositTypeLabel,
            value: (
                <span className="flex">
                    {!!deposit.subscriptionTypeCode && (
                        <>
                            {translation.deposit.subscriptionTypeCode[deposit.subscriptionTypeCode]}
                            <span className="p-1 h-6 w-6">
                                <DepositTypeIcon type={deposit.subscriptionTypeCode} />
                            </span>
                        </>
                    )}
                    {!deposit.subscriptionTypeCode && "-"}
                </span>
            )
        },
        {
            title: translation.depositModeLabel,
            value: (
                <span className="flex">
                    {translation.deposit.depositType[deposit.depositType]}
                    <span className="p-1 h-6 w-6">
                        <DepositModeIcon mode={deposit.depositType} />
                    </span>
                </span>
            )
        },
        ...getLogiboxData(deposit, translation),
        ...getFileData(deposit, translation, locale),
        {
            title: translation.creatorIdentityLabel,
            value: urlMemberPage ? <CustomLink label={deposit.creator.member.name} path={urlMemberPage} target="_blank" /> : deposit.creator.member.name
        },
        {
            title: translation.label(translation.depositMember),
            value: <DepositCreator deposit={deposit} translation={translation} />
        },
        {
            title: translation.label(translation.depositRoleMember),
            value: translation.creatorMemberRole[deposit.creator.member.role]
        },
        { title: translation.label(translation.deposit.dateLabel), value: standardFormatDate(deposit.createdAt) }
    ];
};

const technicalDepositInformation = (deposit: Deposit, apis: Apis, translationContext: I18nContextInterface<Translation>): DepositSectionData[] => {
    const { translation } = translationContext;
    return [
        { title: translation.depositNameLabel, value: deposit.name },
        { title: translation.subtitleLabel, value: deposit.subtitle },
        { title: translation.depositStore.version, value: deposit.workVersion },
        { title: translation.label(translation.depositStore.dateVersion), value: standardFormatDate(deposit.workVersionDate) },
        { title: translation.depositStore.workNature, value: translation.deposit.workNature[deposit.workNature] },
        {
            title: translation.depositStore.workType,
            value: `${apis.deposit.workTypes.find(workType => workType.value === deposit.workType)?.code} - ${translation.deposit.workType[deposit.workType]}`
        },
        { title: translation.depositStore.workNationality, value: translation.country[deposit.countryCode] },
        {
            title: translation.depositStore.productClass,
            value: `${productClassCode(apis.deposit, deposit.productClass)} - ${translation.deposit.productClass[deposit.productClass]}`
        }
    ];
};

export default function DepositCardContent({ deposit, urlMemberPage }: { deposit: Deposit; urlMemberPage?: string }) {
    const apis = useContext(ApisContext);
    const translationContext = useI18n<Translation>();

    const { translation } = translationContext;

    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-4">
            <div className="col-span-1">
                <DepositSection title={translation.deposit.depositName} data={generalDepositInformation(deposit, translationContext, urlMemberPage)} />
            </div>
            <div className="col-span-1">
                <DepositSection title={translation.artwork} data={technicalDepositInformation(deposit, apis, translationContext)} />
            </div>
        </div>
    );
}
