import {
    addHolder,
    addUserManagerToDepositStore,
    BoxContainer,
    Button,
    Contributors,
    DepositStore,
    FormFieldsRadio,
    InfoTooltip,
    InterdepositContext,
    Loader,
    Member,
    SuccessThickIcon,
    TickIcon,
    useContributorsList,
    useI18n
} from "@vaultinum/app-sdk";
import { ReactNode, useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Translation } from "../../i18n";
import { DepositContributors } from "../../schemas/DepositFormNumericSchema";
import { WALLET_PAGE } from "../../services";

export default function StepEnd({
    title,
    subtitle,
    memberInfos,
    depositStore
}: {
    title: string;
    subtitle: ReactNode;
    memberInfos: Member;
    depositStore: DepositStore;
}): JSX.Element {
    const { control, watch, getValues } = useFormContext();
    const { deposit } = useContext(InterdepositContext);
    const { translation } = useI18n<Translation>();
    const navigate = useNavigate();

    const { managers, submit, reset } = useContributorsList(depositStore, memberInfos);

    const choices = [
        {
            value: DepositContributors.SELF,
            title: translation.self
        },
        {
            value: DepositContributors.SHARED,
            title: translation.joint,
            disabled: !addUserManagerToDepositStore(depositStore)
        }
    ];

    useEffect(() => {
        if (getValues("contributors") === DepositContributors.SELF) {
            reset();
        }
    }, [watch("contributors")]);

    const doSubmit = () => {
        if (watch("contributors") === DepositContributors.SELF) {
            navigate(WALLET_PAGE);
        } else {
            void submit(WALLET_PAGE);
        }
    };

    if (!memberInfos || !depositStore || !managers.contributors || !managers.representatives) {
        return <Loader />;
    }

    return (
        <div className="flex flex-col items-center justify-center gap-2">
            <SuccessThickIcon className="w-20 h-20 fill-green-600" />
            <h2 className="text-base text-center font-bold text-green-600">{title}</h2>
            <p className="text-sm text-center">{subtitle}</p>
            {deposit && addHolder(deposit) && (
                <div className="w-full space-y-5 mt-12">
                    <BoxContainer
                        title={translation.responsabilityDeposit}
                        topRightContent={<InfoTooltip children={translation.responsabilityToolTip(memberInfos.name)} className="top-1 right-1" />}
                        icon={<TickIcon className="fill-green-light" />}
                    >
                        <FormFieldsRadio radioGroupName="contributors" control={control} options={choices} />
                    </BoxContainer>
                    <BoxContainer topRightContent={<InfoTooltip children={translation.contributorTooltip} className="top-1 right-1" />}>
                        <div className="w-full space-y-2.5 text-label">
                            <Contributors managers={managers} readonly={watch("contributors") === DepositContributors.SELF} depositStore={depositStore} />
                        </div>
                    </BoxContainer>
                </div>
            )}
            <div className="flex flex-col items-center justify-center gap-8 mt-12">
                <Button label={translation.validateAndGoToWallet} onClick={doSubmit} withLoader />
            </div>
        </div>
    );
}
