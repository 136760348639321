import { Breadcrumb, Deposit, DepositStore, useBreadCrumb, useI18n } from "@vaultinum/app-sdk";
import { useNavigate, useParams } from "react-router-dom";

import { Translation } from "../../../i18n";
import { WALLET_PAGE, toDepositStorePage } from "../../../services";

export default function WalletBreadcrumb({ depositStore, deposit }: { depositStore?: DepositStore; deposit?: Deposit }): JSX.Element {
    const { translation } = useI18n<Translation>();
    const navigate = useNavigate();
    const params = useParams();

    const breadCrumbList = [
        {
            label: translation.portfolio,
            display: true,
            onClick: () => navigate(WALLET_PAGE)
        },
        {
            label: depositStore?.name ?? "-",
            display: depositStore,
            onClick: () => deposit && depositStore && navigate(toDepositStorePage(depositStore)),
            hiddenContent: translation.depositStore.name
        },
        {
            label: `${translation.label(params.option === "resume" ? translation.depositInprogress : translation.depositOfUpdate)}  ${deposit?.name || "-"}`,
            display: depositStore && deposit,
        }
    ].filter(item => item.display);

    useBreadCrumb(breadCrumbList);

    return <Breadcrumb breadCrumbList={breadCrumbList} />;
}
