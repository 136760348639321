import { BoxContainer, Button, FormFieldsInput, Info, Layout, PersonIcon, TickIcon, useI18n } from "@vaultinum/app-sdk";
import { useContext, useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { MemberContext } from "../../contexts";
import { Translation } from "../../i18n";
import { UserInfoSchema } from "../../schemas";

export default function UserInfoPage(): JSX.Element {
    const { member } = useContext(MemberContext);
    const { translation } = useI18n<Translation>();
    const methods = useForm<FieldValues>({
        mode: "onChange",
        resolver: yupResolver(UserInfoSchema(translation)),
        defaultValues: {
            immatriculation:"",
            address1:  "",
            zipCode:  "",
            city: "",
            country: "",
            state: "",
            socialName: "",
            legalStatus: ""
        }
    });

    const {
        control,
        reset,
        formState: { errors }
    } = methods;

    useEffect(() => {
        reset({
            immatriculation: member?.siren,
            address1: member?.address.street,
            zipCode: member?.address.zipCode,
            city: member?.address.city,
            country: member?.vatCode,
            state: member?.address.countryCode,
            socialName: member?.name,
            legalStatus: member?.legalStatus
        });
    }, [member]);

    const memberName = [
        {
            name: "firstName",
            label: translation.firstName,
            disabled: true
        },
        {
            name: "lastName",
            label: translation.lastName,
            disabled: true
        }
    ];

    const professionnalContact = [
        {
            name: "email",
            label: (
                <>
                    {translation.email} <span className="text-danger-high">*</span>
                </>
            ),
            helperText: translation.professionalEmail,
            disabled: true
        },
        {
            name: "phoneNumber",
            label: (
                <>
                    {translation.phoneNumber} <span className="text-danger-high">*</span>
                </>
            ),
            helperText: translation.phoneNumberHelper,
            disabled: true
        },
        {
            name: "job",
            label: (
                <>
                    {translation.job} <span className="text-danger-high">*</span>
                </>
            ),
            disabled: true
        }
    ];

    const professionnalAddress = [
        {
            name: "socialName",
            label: translation.socialName,
            helperText: translation.socialNameHelper,
            disabled: true
        }
    ];

    const legalInfo = [
        {
            name: "legalStatus",
            label: translation.legalStatus,
            disabled: true
        },
        {
            name: "immatriculation",
            label: translation.immatriculation,
            helperText: translation.immatriculationHelper,
            disabled: true
        }
    ];

    const address = [
        {
            name: "address1",
            label: translation.address1,
            disabled: true
        },
        {
            name: "address2",
            label: translation.address2,
            disabled: true
        }
    ];

    const city = [
        {
            name: "zipCode",
            label: translation.postalCode,
            disabled: true
        },
        {
            name: "city",
            label: translation.city,
            disabled: true
        },
        {
            name: "state",
            label: translation.state,
            disabled: true
        },
        {
            name: "country",
            label: translation.countryName,
            disabled: true
        }
    ];

    return (
        <Layout icon={<PersonIcon className="w-6 h-8 fill-primary" />} title={translation.personnalInfoAndPassword}>
            <div className="max-w-2xl mx-auto">
                <div className="flex flex-col items-center mb-5">
                    <div className="flex flex-col items-center justify-center">
                        <h2 className="font-medium text-primary text-center">{translation.yourPersonnalInfo}</h2>
                        <p className="text-sm text-label">{translation.yourInformationsKnownByAPP}</p>
                    </div>
                </div>
                <FormProvider {...methods}>
                    <form className="flex flex-col items-center gap-5 w-full">
                        <BoxContainer
                            title={translation.identity}
                            rightTitle={
                                <p className="text-xs">
                                    <span className="text-danger-high">*</span> {translation.mandatory}
                                </p>
                            }
                            icon={<TickIcon className="fill-green-light" />}
                        >
                            <FormFieldsInput className="grid grid-cols-2" fields={memberName} control={control} errors={errors} />
                        </BoxContainer>
                        <BoxContainer
                            title={translation.professionalContact}
                            rightTitle={
                                <p className="text-xs">
                                    <span className="text-danger-high">*</span> {translation.mandatory}
                                </p>
                            }
                            icon={<TickIcon className="fill-green-light" />}
                        >
                            <div className="pb-5">
                                <Info>{translation.professionnalContactInfo}</Info>
                            </div>
                            <FormFieldsInput className="grid grid-cols-2" fields={professionnalContact} control={control} errors={errors} />
                        </BoxContainer>
                        <BoxContainer
                            title={translation.professionnalAddress}
                            rightTitle={
                                <p className="text-xs">
                                    <span className="text-danger-high">*</span> {translation.mandatory}
                                </p>
                            }
                            icon={<TickIcon className="fill-green-light" />}
                        >
                            <div className="pb-5">
                                <Info>{translation.professionnalAddressInfo}</Info>
                            </div>
                            <div className="space-y-5">
                                <FormFieldsInput className="grid grid-cols-1" fields={professionnalAddress} control={control} errors={errors} />
                                <FormFieldsInput className="grid grid-cols-2" fields={legalInfo} control={control} errors={errors} />
                                <FormFieldsInput className="grid grid-cols-1" fields={address} control={control} errors={errors} />
                                <FormFieldsInput className="grid grid-cols-2" fields={city} control={control} errors={errors} />
                            </div>
                        </BoxContainer>
                        <div className="flex flex-col items-center justify-center gap-8 mt-12">
                            <Button label={translation.save} onClick={() => {}} withLoader disabled />
                            <Button variant="link" onClick={() => {}} label={translation.cancel} size="sm" underline disabled />
                        </div>
                        <div className="mt-10 space-y-5">
                            <h2 className="font-medium text-primary text-center">{translation.yourPassword}</h2>
                            <Button label={translation.changePassword} onClick={() => {}} withLoader disabled />
                        </div>
                    </form>
                </FormProvider>
            </div>
        </Layout>
    );
}
