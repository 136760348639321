import { ReactNode } from "react";
import { isNotBlank, Translation, User, UserInfo } from "..";

export function isAdministrator(user: UserInfo): boolean {
    return user.role === User.UserWalletRole.ADMINISTRATOR;
}

export function userDisplayName(user: { firstName?: string; lastName?: string } | undefined): string {
    return [user?.firstName, user?.lastName].filter(isNotBlank).join(" ");
}

export function userDisplayPhone(user: { phone?: string; mobile?: string } | undefined, translation: Translation): ReactNode {
    const phones = [user?.phone, user?.mobile].filter(isNotBlank).join(" / ");

    return isNotBlank(phones) ? phones : <span className="text-warning italic">{translation.noPhoneNumber}</span>;
}
