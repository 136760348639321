import { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState } from "react";

export interface BreadCrumbContextInterface {
    content?: ReactNode;
    setContent?: Dispatch<SetStateAction<ReactNode>>;
    showContent?: boolean;
    setShowContent?: Dispatch<SetStateAction<boolean>>;
}

export const BreadCrumbContext = createContext<BreadCrumbContextInterface>({} as BreadCrumbContextInterface);

export function BreadCrumbContextProvider({ children }: { children: ReactNode }) {
    const [content, setContent] = useState<ReactNode>();
    const [showContent, setShowContent] = useState<boolean>(false);

    const exposed = useMemo(() => ({ content, setContent, showContent, setShowContent }), [content, showContent]);

    return <BreadCrumbContext.Provider value={exposed}>{children}</BreadCrumbContext.Provider>;
}
