import { Chip, Size } from "../../../design-system";
import { Translation, useI18n } from "../../i18n";
import { Deposit } from "../../model";
import { DepositStatus } from "../DepositStatus";
import DepositStatusIcon from "../DepositStatusIcon/DepositStatusIcon";

export default function DepositBadge({ deposit, label, size, showToolTip = true }: { deposit: Deposit; label: string; size: Size; showToolTip?: boolean }) {
    const { translation } = useI18n<Translation>();

    return (
        <>
            <DepositStatus label={label} status={deposit.sealStatus} size={size} showToolTip={showToolTip} />
            <div className="flex items-end space-x-1">
                {!!deposit.subscriptionTypeCode && (
                    <Chip
                        label={<span>{deposit.subscriptionTypeCode}</span>}
                        tooltipContent={translation.deposit.subscriptionTypeCode[deposit.subscriptionTypeCode]}
                        type="primary"
                        size={size}
                        invertColors
                    />
                )}
                <DepositStatusIcon depositType={deposit.depositType} tooltipContent={translation.deposit.depositType[deposit.depositType]} size={size} />
            </div>
        </>
    );
}
