import { Deposit, DepositsList, getDepositsByDepositStore, InterdepositContext, Loader, useApi } from "@vaultinum/app-sdk";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { toDepositStoreResumeDepositPage, WALLET_PAGE } from "../../../services";

export default function DepositStoreTab() {
    const { depositStore } = useContext(InterdepositContext);
    const { fetchApi } = useApi();
    const navigate = useNavigate();
    const [deposits, setDeposits] = useState<Deposit[]>();

    const loadInfos = async () => {
        if (depositStore) {
            await fetchApi(
                () => getDepositsByDepositStore(depositStore),
                depositsResponse => setDeposits(depositsResponse)
            );
        }
    };

    useEffect(() => {
        void loadInfos();
    }, [depositStore]);

    const navigateToDepositStorePageUpdate = (deposit: Deposit) => {
        if (depositStore) {
            navigate(toDepositStoreResumeDepositPage(depositStore, deposit));
        }
    };

    if (!deposits) {
        return <Loader />;
    }

    return (
        <DepositsList
            deposits={deposits}
            resumeCallback={navigateToDepositStorePageUpdate}
            navigateToWallet={() => navigate(WALLET_PAGE)}
            refreshCallback={loadInfos}
            showToolTip={false}
            copyable={false}
            displayCopyUuid={false}
        />
    );
}
