import { Button, InputSelectCheckbox, SimpleListOption, OutlinedMinusIcon, SimpleTable, TrashIcon } from "../../../design-system";
import { isAdministrator } from "../../helpers";
import { Managers } from "../../hooks";
import { Translation, useI18n } from "../../i18n";
import { DepositStore, Representative, UserInfo } from "../../model";
import { addMemberToDepositStore, removeDepositStoreContributor, removeDepositStoreMember } from "../../services";

const canUserBeRemoved = (user: UserInfo, readonly: boolean | undefined): boolean =>
    !readonly && (!user._links?.removeUserManager || !!removeDepositStoreContributor(user));

const canRepresentativeBeRemoved = (representative: Representative, readonly: boolean | undefined): boolean =>
    !readonly && (!representative._links?.removeMemberManager || !!removeDepositStoreMember(representative));

const getUserLabel = (translation: Translation, user: UserInfo): string =>
    `${translation.userWalletRole(user.role)} | ${user.firstName} ${user.lastName} - ${user.email}`;

const formatRepresentativesList = (representatives: Representative[]): SimpleListOption[] => {
    return representatives.map(representative => {
        return {
            id: representative.id,
            label: representative.name,
            type: "radio"
        };
    });
};

const formatUsersList = (users: UserInfo[], readonly?: boolean): SimpleListOption[] => {
    return users.map(user => {
        return {
            id: user.id,
            label: `${user.firstName} ${user.lastName} - ${user.email}`,
            disabled: !canUserBeRemoved(user, readonly),
            type: "checkbox"
        };
    });
};

export default function ListSelect({
    titleList,
    managers,
    depositStore,
    readonly
}: {
    titleList: string;
    managers: Managers;
    depositStore: DepositStore;
    readonly?: boolean;
}): JSX.Element {
    const { translation } = useI18n<Translation>();
    const {
        userManagers,
        contributors,
        onUserManagerAdd,
        representativeManagers,
        representatives,
        onRepresentativeManagerAdd,
        onUserManagerRemove,
        onRepresentativeManagerRemove
    } = managers;

    const handleList = (options: SimpleListOption[]) => {
        options
            .filter(option => userManagers.every(manager => manager.id !== option.id))
            .map(option => contributors?.find(contributor => contributor.id === option.id))
            .forEach(manager => {
                if (manager) {
                    onUserManagerAdd(manager);
                }
            });

        userManagers
            .filter(manager => options.every(option => manager.id !== option.id))
            .map(manager => manager.id)
            .forEach(onUserManagerRemove);

        options
            .filter(option => representativeManagers.every(manager => manager.id !== option.id))
            .map(option => representatives?.find(contributor => contributor.id === option.id))
            .forEach(manager => {
                if (manager) {
                    onRepresentativeManagerAdd(manager);
                }
            });

        representativeManagers
            .filter(manager => options.every(option => manager.id !== option.id))
            .map(manager => manager.id)
            .forEach(onRepresentativeManagerRemove);
    };

    const userManagersTitles = [
        { value: translation.role, style: "text-left" },
        { value: translation.lastName, style: "text-left" },
        { value: translation.email, style: "text-left" },
        {
            value: (
                <div className="flex items-center justify-center">
                    <TrashIcon className="fill-text-label w-4 h-4" />
                </div>
            )
        }
    ];

    const userManagersValues = userManagers.map(manager => [
        {
            value: translation.userWalletRole(manager.role),
            style: isAdministrator(manager) ? "font-bold" : "font-normal"
        },
        {
            value: `${manager.lastName} ${manager.firstName}`,
            style: isAdministrator(manager) ? "font-bold" : "font-normal"
        },
        {
            value: manager.email
        },
        {
            value: (
                <div className="flex justify-center items-center">
                    {canUserBeRemoved(manager, readonly) ? (
                        <Button
                            variant="iconButton"
                            onClick={() => onUserManagerRemove(manager.id)}
                            icon={<OutlinedMinusIcon className="fill-primary w-4 h-4" />}
                        />
                    ) : (
                        <OutlinedMinusIcon className="fill-medium-grey w-4 h-4" />
                    )}
                </div>
            )
        }
    ]);

    const representativeManagersTitle = [
        { value: translation.lastName, style: "text-left" },
        { value: translation.email, style: "text-left" },
        {
            value: (
                <div className="flex items-center justify-center">
                    <TrashIcon className="fill-text-label w-4 h-4" />
                </div>
            )
        }
    ];

    const representativeManagersValues = representativeManagers.map(manager => [
        {
            value: manager.name,
            style: "font-bold"
        },
        {
            value: "-"
        },
        {
            value: (
                <div className="flex justify-center items-center">
                    {canRepresentativeBeRemoved(manager, readonly) ? (
                        <Button
                            variant="iconButton"
                            onClick={() => onRepresentativeManagerRemove(manager.id)}
                            icon={<OutlinedMinusIcon className="fill-primary w-4 h-4" />}
                        />
                    ) : (
                        <OutlinedMinusIcon className="fill-medium-grey w-4 h-4" />
                    )}
                </div>
            )
        }
    ]);

    const renderManagers = () => {
        return (
            <>
                {!userManagers.length && !representativeManagers.length && (
                    <table className="w-full">
                        <thead>
                            <tr className="border border-blue-teal bg-primary-xlight px-3 py-2.5">
                                <td className="h-8" />
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border text-deep-blue border-dark-blue-teal px-3 py-2.5 text-center">
                                <td>{translation.noResult}</td>
                            </tr>
                        </tbody>
                    </table>
                )}
                {!!userManagers.length && <SimpleTable titles={userManagersTitles} values={userManagersValues} layout="auto" />}
                {!!representativeManagers.length && <SimpleTable titles={representativeManagersTitle} values={representativeManagersValues} layout="auto" />}
            </>
        );
    };

    const formatListSelected = () => {
        const listFormatManagers = userManagers.map(manager => ({
            id: manager.id,
            label: getUserLabel(translation, manager),
            disabled: canUserBeRemoved(manager, readonly)
        }));

        const listFormatRepresentatives = representativeManagers.map(representative => ({
            id: representative.id,
            label: representative.name,
            disabled: canRepresentativeBeRemoved(representative, readonly)
        }));

        return [...listFormatManagers, ...listFormatRepresentatives];
    };

    return (
        <div className="w-full text-label">
            <div className="flex">
                <h3 className="text-sm font-bold">{titleList}</h3>
            </div>
            <div className="bg-white mb-5 mt-2.5">{renderManagers()}</div>
            {!readonly && (
                <InputSelectCheckbox
                    list={[
                        ...(contributors?.length
                            ? [
                                  {
                                      groupName: translation.contributors,
                                      list: formatUsersList(
                                          contributors.map(contributor => ({ ...contributor, groupName: translation.contributors })),
                                          readonly
                                      )
                                  }
                              ]
                            : []),
                        ...(addMemberToDepositStore(depositStore) && representatives?.length
                            ? [
                                  {
                                      groupName: translation.representatives,
                                      list: formatRepresentativesList(
                                          representatives.map(representative => ({ ...representative, groupName: translation.representatives }))
                                      )
                                  }
                              ]
                            : [])
                    ]}
                    placeholder={translation.findOrSelect}
                    inputLabel={translation.findByContributors}
                    noResultLabel={translation.noResult}
                    handleList={handleList}
                    listFiltered={formatListSelected()}
                />
            )}
        </div>
    );
}
