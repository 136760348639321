import { yupResolver } from "@hookform/resolvers/yup";
import { ApisContext, Layout, LetterOpen, saveDepositMetadata, useApi, useI18n, UserContext, useToast } from "@vaultinum/app-sdk";
import { useContext } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DepositStepLayout } from "../../../components/DepositSteps";
import { MemberContext } from "../../../contexts";
import { dataForm } from "../../../helpers";
import { useStepController } from "../../../hooks";
import { Translation } from "../../../i18n";
import { DepositStep } from "../../../model";
import { DepositFormNumericSchema } from "../../../schemas";
import { DepositDefaultValues } from "../../../schemas/DepositFormNumericSchema";
import { DEPOSIT_PAGE, toDepositPageUpdate, WALLET_PAGE } from "../../../services";

export default function CreateDepositPage(): JSX.Element {
    const { translation } = useI18n<Translation>();
    const { member } = useContext(MemberContext);
    const { user } = useContext(UserContext);
    const { fetchApi, isLoading } = useApi();
    const toast = useToast();
    const apis = useContext(ApisContext);
    const navigate = useNavigate();

    const methods = useForm<FieldValues>({
        mode: "onChange",
        resolver: yupResolver(DepositFormNumericSchema),
        defaultValues: DepositDefaultValues
    });

    const { handleSubmit, trigger } = methods;

    const StepController = useStepController(methods, DepositStep.INFORMATION, member);

    const saveDeposit = async (data: FieldValues, saveProgress?: boolean) => {
        const userId = user?.sub;
        const memberId = user?.memberId;

        const createDepositFn = saveDepositMetadata(apis.deposit);
        if (createDepositFn && userId && memberId) {
            const createDepositResponse = await fetchApi(() => createDepositFn(userId, memberId, dataForm(data)));

            if (saveProgress && createDepositResponse) {
                navigate(WALLET_PAGE);
            } else if (createDepositResponse) {
                toast.success(translation.depositCreateAlert(createDepositResponse.name));
                navigate(toDepositPageUpdate(createDepositResponse));
            }
        }
    };

    const saveAndQuit = async () => {
        await handleSubmit(data => saveDeposit(data, true))();
    };

    const handleForm = async () => {
        if (await trigger(StepController.validateFields)) {
            await handleSubmit(data => saveDeposit(data))();
        }
    };

    return (
        <Layout title={translation.depositAction} icon={<LetterOpen className="w-6 h-8 fill-primary" />}>
            <div className="min-w-max max-w-3xl mx-auto">
                <FormProvider {...methods}>
                    <DepositStepLayout
                        depositStep={DepositStep.INFORMATION}
                        forwardButtonAction={handleForm}
                        backButtonAction={() => navigate(DEPOSIT_PAGE)}
                        submit={saveAndQuit}
                        stepController={StepController}
                        isLoading={isLoading}
                    />
                </FormProvider>
            </div>
        </Layout>
    );
}
