import { yupResolver } from "@hookform/resolvers/yup";
import { Button, CompanyLogo, CustomLink, ExternalLinkIcon, FormFieldsInput, Info, InputFields, useI18n } from "@vaultinum/app-sdk";
import { createRef } from "react";
import { default as ReCAPTCHA } from "react-google-recaptcha";
import { Controller, FieldValues, useForm } from "react-hook-form";

import { Translation } from "../../i18n";
import { ResetPasswordFormSchema } from "../../schemas";
import { LOGIN_PAGE } from "../../services";

export default function PasswordPage() {
    const recaptchaRef = createRef<ReCAPTCHA>();
    const { translation } = useI18n<Translation>();

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm<FieldValues>({
        resolver: yupResolver(ResetPasswordFormSchema)
    });

    const resetPassword = (data: FieldValues) => {
        console.log(data);
    };

    const STEP_RESET_PASSWORD = [
        {
            title: translation.resetPassword.requestCode,
            content: <p className="text-sm">{translation.resetPassword.fillForm}</p>
        },
        {
            title: translation.resetPassword.getCodeByMail,
            content: <p className="text-sm">{translation.resetPassword.getCodeByMailDescription}</p>
        },
        {
            title: translation.resetPassword.securityQuestionsAnswers,
            content: (
                <p className="text-sm">
                    {translation.resetPassword.helperMessage}

                    <a
                        className="flex items-center underline cursor-pointer w-fit text-primary hover:font-bold"
                        target="_blank"
                        href="https://www.app.asso.fr/contact"
                    >
                        ce formulaire
                        <ExternalLinkIcon className="ml-1.5 mt-1 fill-primary hover:font-bold" />
                    </a>
                </p>
            )
        }
    ];

    const RESET_PASSWORD_FORM: InputFields[] = [
        {
            name: "login",
            label: translation.login,
            helperText: translation.loginHelper,
            labelPosition: "top"
        }
    ];

    return (
        <main className="flex flex-col items-center py-16 space-y-12">
            <CompanyLogo className="h-12 w-60" />
            <div className="max-w-md">
                <Info labelType="Rappel" type="primary" className="mb-8">
                    {translation.resetPassword.description}
                </Info>

                <div className="flex flex-col gap-6 mb-16">
                    {STEP_RESET_PASSWORD.map(({ title, content }, index) => (
                        <div className="flex gap-5" key={title}>
                            <span className="flex items-center justify-center w-10 h-10 text-xl border-2 border-primary rounded-3xl text-primary">
                                <strong>{index + 1}</strong>
                            </span>
                            <div className="flex flex-col w-3/4">
                                <h6>
                                    <strong>{title}</strong>
                                </h6>
                                {content}
                            </div>
                        </div>
                    ))}
                </div>

                <div className="relative flex flex-col items-center p-12 border border-form">
                    <h2 className="text-2xl font-bold text-primary mb-9">{translation.resetPassword.label}</h2>
                    <form onSubmit={handleSubmit(resetPassword)} className="w-full space-y-5">
                        <FormFieldsInput className="flex flex-col" fields={RESET_PASSWORD_FORM} control={control} errors={errors} />
                        <Controller
                            name="captcha"
                            control={control}
                            render={({ field: { ref = recaptchaRef, ...field }, fieldState: { error } }) => (
                                <>
                                    <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY || ""} ref={ref} {...field} />
                                    <span className="text-xs mt-0.5 text-danger-high">{error?.message}</span>
                                </>
                            )}
                        />
                        <div className="flex flex-col items-center gap-4 pt-9">
                            <Button type="submit" label="Valider" />
                            <CustomLink label={translation.resetPassword.cancelAndBackToLoginPage} path={LOGIN_PAGE} />
                        </div>
                    </form>
                </div>
            </div>
        </main>
    );
}
