import {
    calculatePercentage,
    CreateDepositResponse,
    deleteAllFiles,
    Deposit,
    finalizeUpload,
    FinalizeUploadResponse,
    getDepositById,
    initializeUpload,
    InitializeUploadResponse,
    uploadChunk,
    UploadChunkResponse,
    useApi,
    useI18n,
    useToast
} from "@vaultinum/app-sdk";
import { useState } from "react";

import { Translation } from "../i18n";

const CHUNK_SIZE_3M = 3 * 1024 * 1024;

export default function useUpload() {
    const { fetchApi } = useApi();
    const { translation } = useI18n<Translation>();
    const [totalUploadSizeInBytes, setTotalUploadSizeInBytes] = useState(0);
    const [uploadedSizeInBytes, setUploadedSizeInBytes] = useState(0);
    const [cancelUpload, setCancelUpload] = useState<() => void>();
    const toast = useToast();

    class Uploader {
        readonly deposit: CreateDepositResponse | Deposit;

        private isCanceled = false;

        constructor(deposit: CreateDepositResponse | Deposit) {
            this.deposit = deposit;
        }

        public cancel() {
            this.isCanceled = true;

            setCancelUpload(undefined);
        }

        private getInitializeUploadFn(depositData: CreateDepositResponse | Deposit): (file: File) => Promise<InitializeUploadResponse> {
            const initializeUploadFn = initializeUpload(depositData);

            if (!initializeUploadFn) {
                throw new Error(translation.upload.noInitializeUploadFunction);
            }

            return initializeUploadFn;
        }

        private getUploadChunkFn(
            response: UploadChunkResponse | InitializeUploadResponse
        ): (chunk: Blob, currentChunk: number, chunksCount: number) => Promise<UploadChunkResponse> {
            const uploadChunkFn = uploadChunk(response);

            if (!uploadChunkFn) {
                throw new Error(translation.upload.noUploadChunkFunction);
            }

            return uploadChunkFn;
        }

        private async uploadFileChunk(
            file: File,
            currentChunk: number,
            chunksCount: number,
            response: UploadChunkResponse | InitializeUploadResponse
        ): Promise<UploadChunkResponse | undefined> {
            if (this.isCanceled) {
                return undefined;
            }

            const uploadChunkFn = this.getUploadChunkFn(response);

            const chunk = file.slice((currentChunk - 1) * CHUNK_SIZE_3M, currentChunk * CHUNK_SIZE_3M);

            const uploadChunkResponse = await fetchApi(() => uploadChunkFn(chunk, currentChunk, chunksCount));

            if (!this.isCanceled) {
                setUploadedSizeInBytes(uploadedSize => uploadedSize + chunk.size);
            }

            return uploadChunkResponse;
        }

        private getFinalizeUploadFn(uploadChunkResponse: UploadChunkResponse) {
            const finalizeUploadFn = finalizeUpload(uploadChunkResponse);

            if (!finalizeUploadFn) {
                throw new Error(translation.upload.noFinalizeUploadFunction);
            }

            return finalizeUploadFn;
        }

        private getUploadFileFn(depositData: CreateDepositResponse | Deposit): (file: File) => Promise<FinalizeUploadResponse | undefined> {
            const initializeUploadFn = this.getInitializeUploadFn(depositData);

            return async (file: File): Promise<FinalizeUploadResponse | undefined> => {
                const initializeUploadResponse = await fetchApi(() => initializeUploadFn(file));

                if (!initializeUploadResponse) {
                    throw new Error(translation.upload.initializeUploadError(file));
                }

                setTotalUploadSizeInBytes(totalSize => totalSize + file.size);

                setCancelUpload(() => () => this.cancel());

                const chunksCount = file.size % CHUNK_SIZE_3M === 0 ? file.size / CHUNK_SIZE_3M : Math.floor(file.size / CHUNK_SIZE_3M) + 1;

                let uploadChunkResponse;

                for (let currentChunk = 1; currentChunk <= chunksCount; currentChunk++) {
                    uploadChunkResponse = await this.uploadFileChunk(file, currentChunk, chunksCount, uploadChunkResponse || initializeUploadResponse);

                    if (!uploadChunkResponse) {
                        return undefined;
                    }
                }

                if (!uploadChunkResponse) {
                    throw new Error(translation.upload.uploadChunkError(file));
                }

                const finalizeUploadFn = this.getFinalizeUploadFn(uploadChunkResponse);

                const finalizeUploadResponse = await fetchApi(() => finalizeUploadFn());

                if (!finalizeUploadResponse) {
                    throw new Error(translation.upload.finalizeUploadError(file));
                }

                return finalizeUploadResponse;
            };
        }

        public async upload(files: File[]) {
            try {
                this.isCanceled = false;

                const uploadFile = this.getUploadFileFn(this.deposit);

                const fileUploadPromises = files.map(uploadFile);

                const uploadFileResponses = await Promise.all(fileUploadPromises);

                if (this.isCanceled) {
                    const deposit = await getDepositById(this.deposit._links.self.href);

                    const deleteAllFilesFn = deleteAllFiles(deposit);

                    if (deleteAllFilesFn) {
                        await deleteAllFilesFn();
                    }

                    setUploadedSizeInBytes(0);
                    setTotalUploadSizeInBytes(0);
                }

                return uploadFileResponses;
            } catch (error) {
                toast.error(error instanceof Error ? error.message : translation.upload.unknownError);
            }

            return undefined;
        }
    }

    const uploadFiles = async (depositData: CreateDepositResponse | Deposit, files: File[]) => {
        try {
            const uploader = new Uploader(depositData);

            const uploadFileResponses = await uploader.upload(files);

            return uploadFileResponses;
        } catch (error) {
            toast.error(error instanceof Error ? error.message : translation.upload.unknownError);
        }

        return undefined;
    };

    return {
        uploadFiles,
        totalUploadSizeInBytes,
        uploadedSizeInBytes,
        uploadPercent: calculatePercentage(totalUploadSizeInBytes, uploadedSizeInBytes),
        isUploadDone: uploadedSizeInBytes > 0 && totalUploadSizeInBytes === uploadedSizeInBytes,
        cancelUpload
    };
}
