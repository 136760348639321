import { FieldValues } from "react-hook-form";
import * as yup from "yup";
import { ObjectSchema } from "yup";
import { Translation } from "../i18n";

const UserInfoSchema = (translation: Translation): ObjectSchema<FieldValues> => {
    const requiredField = translation.requiredField;
    return yup.object({
        firstName: yup.string().required(requiredField),
        lastName: yup.string().required(requiredField),
        email: yup.string().email(translation.invalidEmail).required(requiredField),
        phoneNumber: yup.string().required(requiredField),
        job: yup.string().required(requiredField),
        legalStatus: yup.string(),
        immatriculation: yup.string(),
        address1: yup.string(),
        address2: yup.string(),
        zipCode: yup.string(),
        city: yup.string(),
        country: yup.string(),
        socialName: yup.string()
    });
};

export default UserInfoSchema;
