import { useEffect, useState } from "react";

import { InvoicesList } from ".";
import { PaginatedInvoices } from "../../model";
import { getMemberInformation, getMemberInvoices, MemberInformation } from "../../services";
import { NavigationPagination } from "../Navigation";

export default function MemberInvoicesList({ memberId }: { memberId: string }): JSX.Element {
    const [memberInformation, setMemberInformation] = useState<MemberInformation>();
    const [paginatedInvoices, setPaginatedInvoices] = useState<PaginatedInvoices>();
    const [loadingCount, setLoadingCount] = useState<number>(2);

    const loadMemberInformation = async () => {
        setMemberInformation(await getMemberInformation(memberId));
    };

    const loadPaginatedInvoices = async () => {
        if (memberInformation) {
            const getMemberInvoicesFn = getMemberInvoices(memberInformation);

            if (getMemberInvoicesFn) {
                setPaginatedInvoices(await getMemberInvoicesFn());
            }
        }
    };

    useEffect(() => {
        void loadMemberInformation();
    }, []);

    useEffect(() => {
        void loadPaginatedInvoices();
    }, [memberInformation]);

    return (
        <div className="flex flex-col items-center space-y-3">
            <InvoicesList invoices={paginatedInvoices?.invoices} loadingCount={loadingCount} />
            {paginatedInvoices?.pagination && (
                <NavigationPagination
                    pagination={paginatedInvoices?.pagination}
                    onLoadingInitialized={() => {
                        setLoadingCount(prev => paginatedInvoices?.invoices.length ?? prev);
                        setPaginatedInvoices(undefined);
                    }}
                    onLoadingSuccess={setPaginatedInvoices}
                />
            )}
        </div>
    );
}
