import classNames from "classnames";
import { ReactNode, useContext, useEffect } from "react";
import { Breadcrumb } from "../../design-system";
import { BreadCrumbContext } from "../contexts";

type BreadCrumbItem = {
    label: string;
    path?: string;
    icon?: ReactNode;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

export default function useBreadCrumb(breadCrumbList: BreadCrumbItem[]) {
    const { setContent, setShowContent } = useContext(BreadCrumbContext);

    const handleScroll = () => {
        setShowContent?.(window.scrollY > 0);
    };

    const breadCrumbScrollContent = (
        <div className={classNames("bg-medium-light-grey px-2 py-0.5 rounded-b-md z-auto font-bold")}>
            <Breadcrumb breadCrumbList={breadCrumbList} />
        </div>
    );

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        setContent?.(breadCrumbScrollContent);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            setContent?.(null);
        };
    }, []);
}
