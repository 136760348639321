import { Deposit } from "@vaultinum/app-sdk";
import { FieldValues } from "react-hook-form";
import * as yup from "yup";
import { ObjectSchema } from "yup";

const requiredField = "Ce champ est requis";
const limitSize255 = "Nombre maximal de caractères dépassé, limite de 255 caractères";
const limitSize2048 = "Nombre maximal de caractères dépassé, limite de 2048 caractères";

export enum DepositRights {
    ONE_TITULARITY = "oneTitularity",
    SHARED_TITULARITY = "sharedTitularity",
    REPRESENTATIVE = "representative",
    UNKNOWN = "unknown"
}

export enum DepositContributors {
    SHARED = "shared",
    SELF = "self"
}

export const DepositDefaultValues: FieldValues = {
    type: Deposit.Type.DIGITAL,
    contractType: "standard",
    subscriptionTypeCode: "S",
    countryCode: "FR",
    agreements: false,
    listOfFiles: [],
    holders: [],
    representatives: [],
    contributors: DepositContributors.SELF,
    depositRights: DepositRights.UNKNOWN
};

const DepositFormNumericSchema: ObjectSchema<FieldValues> = yup.object({
    contractType: yup.string().oneOf(["standard", "bipartite", "tripartite", "accessclause"]),
    depositRights: yup.string().oneOf([DepositRights.ONE_TITULARITY, DepositRights.SHARED_TITULARITY, DepositRights.REPRESENTATIVE, DepositRights.UNKNOWN]),
    type: yup.string().oneOf([Deposit.Type.DIGITAL, Deposit.Type.PHYSICAL]),
    subscriptionTypeCode: yup.string().oneOf([Deposit.SubscriptionTypeCode.S, Deposit.SubscriptionTypeCode.C, Deposit.SubscriptionTypeCode.D]),
    name: yup.string().required(requiredField).max(255, limitSize255),
    productClass: yup.string().required(requiredField),
    subtitle: yup.string().max(2048, limitSize2048),
    workVersion: yup.string().max(255, limitSize255),
    workVersionDate: yup.string(),
    workNature: yup.string(),
    workType: yup.string().required(requiredField),
    countryCode: yup.string().required(requiredField),
    agreements: yup.boolean().required(requiredField),
    holders: yup.array(),
    representatives: yup.array(),
    contributors: yup.string().oneOf([DepositContributors.SHARED, DepositContributors.SELF])
});

export default DepositFormNumericSchema;
