export namespace Product {
    export enum Type {
        CREDIT = "CREDIT"
    }
}

export interface Product {
    reference: string;
    name: string;
    type: Product.Type;
    unitPriceVatExcluded: number;
    unitPriceWithRebateVatIncluded: number;
    vatRate: number;
    vatPriceWithRebate: number;
    rebatePercent: number;
    rebatePriceVatExcluded: number;
}
