import { Deposit, Info, Member, MemberHolder, SimpleListOption, useI18n, UserContext } from "@vaultinum/app-sdk";
import { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ListDeposit } from ".";
import { formatUserList } from "../../helpers";
import { Translation } from "../../i18n";

export default function StepSharedTitularity({ member, holders }: { member: Member | undefined; holders: MemberHolder[] | undefined }): JSX.Element {
    const { setValue, getValues } = useFormContext();
    const { translation } = useI18n<Translation>();
    const { isUserManager } = useContext(UserContext);

    const initiateHolders = () => {
        if (getValues("holders").length && holders) {
            const holdersIds: string[] = getValues("holders").map((item: Deposit.Holder | SimpleListOption) => {
                if ("memberId" in item) {
                    return item.memberId;
                } else {
                    return item.id;
                }
            });

            const newOptions = holders.filter((item: MemberHolder) => holdersIds.includes(item.id));
            setValue("holders", formatUserList(newOptions));
        }
    };

    useEffect(() => {
        initiateHolders();
    }, []);

    return (
        <ListDeposit
            field="holders"
            users={holders}
            member={member}
            title={translation.titularity}
            information={!isUserManager && <>{<Info>{translation.addHolderV1}</Info>}</>}
            inputTitle={translation.findOrAddHolder}
        />
    );
}
