import { BoxContainer, Deposit, FormFieldsRadio, InfoTooltip, TickIcon, useI18n } from "@vaultinum/app-sdk";
import { useFormContext } from "react-hook-form";
import { Translation } from "../../i18n";
import DropZone from "../DropZone/DropZone";

export default function StepTransmission(): JSX.Element {
    const { watch, control } = useFormContext();
    const { translation } = useI18n<Translation>();

    const choices = [
        {
            value: Deposit.Type.DIGITAL,
            title: translation.meanStep.option1.title
        },
        {
            value: Deposit.Type.PHYSICAL,
            title: translation.meanStep.option2.title,
            disabled: true
        }
    ];

    return (
        <div className="max-w-2xl space-y-5">
            <BoxContainer
                title={translation.depositMode}
                topRightContent={<InfoTooltip children={translation.transmissionTooltip} className="top-1 right-1" />}
                icon={watch("type") && <TickIcon className="fill-green-light" />}
            >
                <FormFieldsRadio radioGroupName="type" control={control} options={choices} />
            </BoxContainer>
            <BoxContainer>
                <DropZone />
            </BoxContainer>
        </div>
    );
}
