import { Checkout, Invoice, Link, Order, OrderCreation, OrderCredit, PaginatedInvoices, Product } from "../model";
import { doGet, doPost, download } from "./api.service";

export type Download = {
    name: string;
    blob: Blob;
};

export type MemberInformation = {
    globalRebatePercent: number;
    canFillExternalReference: boolean;
    _links: {
        invoices?: Link;
        credits?: Link;
        createOrder?: Link;
        orderCreditsWithCreditCard?: Link;
    };
};

export function getMemberInformation(memberId: string): Promise<MemberInformation> {
    return doGet(`${process.env.REACT_APP_ORDER_API_URL}/v1/members/${memberId}`);
}

export function getMemberInvoices(memberInformation: MemberInformation): (() => Promise<PaginatedInvoices>) | undefined {
    const link = memberInformation._links.invoices;

    if (!link) {
        return undefined;
    }

    return () => doGet<PaginatedInvoices>(link.href);
}

export function downloadInvoicePdf(invoice: Invoice): (() => Promise<Download>) | undefined {
    const link = invoice._links.downloadPdf;

    if (!link) {
        return undefined;
    }

    return () => download(link.href);
}

export function getCredits(memberInformation: MemberInformation): (() => Promise<Product[]>) | undefined {
    const link = memberInformation._links.credits;

    if (!link) {
        return undefined;
    }

    return () => doGet<Product[]>(link.href);
}

export function createOrder(memberInformation: MemberInformation): ((orderCreation: OrderCreation) => Promise<Order>) | undefined {
    const link = memberInformation._links.createOrder;

    if (!link) {
        return undefined;
    }

    return (orderCreation: OrderCreation) => doPost<Order>(link.href, orderCreation);
}

export function checkoutOrder(order: Order): (() => Promise<Checkout>) | undefined {
    const link = order._links.checkout;

    if (!link) {
        return undefined;
    }

    return () => doPost<Checkout>(link.href);
}

export function payOrderByBankTransfer(order: Order): (() => Promise<void>) | undefined {
    const link = order._links.payByBankTransfer;

    if (!link) {
        return undefined;
    }

    return () => doPost<void>(link.href);
}

export function orderCreditsWithCreditCard(memberInformation: MemberInformation): ((orderCredit: OrderCredit) => Promise<Checkout>) | undefined {
    const link = memberInformation._links.orderCreditsWithCreditCard;

    if (!link) {
        return undefined;
    }

    return (orderCredit: OrderCredit) => doPost<Checkout>(link.href, orderCredit);
}
